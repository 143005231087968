<template>
  <v-container class="d-flex justify-center pa-0">
    <v-card style="width: 100%;">
      <v-form
        onsubmit="return false;"
        @submit="onSubmit"
      >
        <v-card-text class="pb-0">
          <v-container class="d-flex justify-start pb-0">
            <v-card-title
              class="text-h3 font-weight-bold grey--text justify-center flex-wrap pa-0"
            >
              AC Setpoints
            </v-card-title>
          </v-container>
          <v-sheet
            width="80%"
            style="margin-right: 10%; margin-left: 10%"
          >
            <v-select
              v-model="leadSelect"
              :items="leadItems"
              label="Lead"
            />
            <v-select
              v-model="acNeededSelect"
              :items="acNeededItems"
              label="AC Needed"
            />
            <v-slider
              v-model="setpoint"
              class="mt-4"
              color="grey darken-1"
              track-color="grey darken-1"
              :tick-labels="tickLabels"
              max="75"
              min="65"
              step="1"
              ticks="always"
              tick-size="4"
              label="Setpoint"
            >
              <template v-slot:label="">
                <span>
                  Setpoint:
                </span>
                <span style="font-weight: bold; font-size: 16px;">
                  {{ `${setpoint} &#176;F` }}
                </span>
              </template>
            </v-slider>
            <v-slider
              v-model="offset"
              class="mt-4"
              color="grey darken-1"
              track-color="grey"
              :tick-labels="offsetTickLabels"
              max="5"
              min="0"
              step="1"
              ticks="always"
              tick-size="4"
              :label="`Offset ${offset}`"
            >
              <template v-slot:label="">
                <span>
                  Offset:
                </span>
                <span style="font-weight: bold; font-size: 16px;">
                  {{ `${offset} &#176;F` }}
                </span>
              </template>
            </v-slider>
          </v-sheet>
        </v-card-text>
        <v-card-actions class="justify-end ma-2">
          <v-btn
            color="green darken-1"
            text
            type="submit"
          >
            Update
          </v-btn>
          <v-btn
            color="grey darken-1"
            text
            @click="$emit('close')"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>

  export default {
    name: 'SetpointsCard',
    data: () => ({
      leadSelect: '',
      leadItems: [],
      acNeededSelect: '',
      acNeededItems: [],
      setpoint: 65,
      tickLabels: [65, '', '', '', '', 70, '', '', '', '', 75],
      offsetTickLabels: ['0', '', '', '', '', 5],
      offset: 0,

    }),
    methods: {
      onSubmit: function () {

      },
    },
  }
</script>
<style lang="sass">

div .v-slider__tick-label
    transform: translateX(-50%) !important

</style>
