<template>
  <v-container
    fluid
    style="width: 100%; height: 100%"
  >
    <v-skeleton-loader
      v-if="loadingFloorplan"
      width="100%"
      height="100%"
      type="image"
    />
    <div
      v-else
      id="svgContainer"
      ref="svgContainer"
      style="width: 100%; height: 100%; overflow: hidden;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0);
        border-radius: 6px;"
    >
      <svg
        id="svgImage"
        ref="svgImage"
        height="100%"
        width="100%"
        style="background: white;"
      >
        <image
          v-if="imagePath"
          :href="imagePath"
          :height="imageHeight"
          :width="imageWidth"
          :x="imageXOffset"
          :y="imageYOffset"
        />
        <svg
          v-if="showGridLines"
          id="grid"
          :key="selectedItems.length"
          height="300%"
          width="300%"
          style="background: transparent;"
        >
          <template
            v-for="(position) in gridArray"
          >
            <grid-square
              :key="`x:${position.x}y:${position.y}`"
              :position="position"
              :square-pixel-width="50"
              :square-pixel-height="50"
              :selectedItems="selectedItems"
              :local-bus="localBus"
              :selectable="setLocationDialog && setFloorLocation"
              :set-location-dialog="setLocationDialog"
              :configure-floor="configureFloor"
            />
          </template>
        </svg>
        <svg
          v-if="floorSelectionType === 'half-left-right' || floorSelectionType === 'half-up-down'"
          height="300%"
          width="300%"
          style="background: transparent;"
        >
          <template
            v-for="(position) in gridArray"
          >
            <g
              v-if="!includesPosition(position, selectedItems)"
              :key="`grid-half-square-x:${position.x}y:${position.y}`"
            >
              <grid-square
                :position="position"
                :half="floorSelectionType === 'half-left-right' ? 'horizontal' : 'vertical'"
                :square-pixel-width="floorSelectionType === 'half-left-right' ? 25 : 50"
                :square-pixel-height="floorSelectionType === 'half-left-right' ? 50 : 25"
                :selectedItems="selectedPartialItems"
                :local-bus="localBus"
                :selectable="setLocationDialog && setFloorLocation"
              />
              <grid-square
                :position="{
                  x: position.x + (floorSelectionType === 'half-left-right' ? .5 : 0),
                  y: position.y + (floorSelectionType === 'half-left-right' ? 0 : .5)
                }"
                :half="floorSelectionType === 'half-left-right' ? 'horizontal' : 'vertical'"
                :square-pixel-width="floorSelectionType === 'half-left-right' ? 25 : 50"
                :square-pixel-height="floorSelectionType === 'half-left-right' ? 50 : 25"
                :selectedItems="selectedPartialItems"
                :local-bus="localBus"
                :selectable="setLocationDialog && setFloorLocation"
              />
              <line
                :key="`bisectline-${position.x}-${position.y}`"
                :x1="floorSelectionType === 'half-left-right' ? position.x * 50 + 25 : position.x * 50"
                :y1="floorSelectionType === 'half-left-right' ? position.y * 50 : position.y * 50 + 25"
                :x2="floorSelectionType === 'half-left-right' ? position.x * 50 + 25 : position.x * 50 + 50"
                :y2="floorSelectionType === 'half-left-right' ? position.y * 50 + 50 : position.y * 50 + 25"
                stroke="grey"
                stroke-dasharray="2"
              />
            </g>
          </template>
        </svg>
        <g v-if="setLocationDialog && setFloorLocation">
          <template
            v-for="(position, i) in unselectableGridSpaces"
          >
            <grid-square
              :key="`unselectable-x:${position.x}y:${position.y}-${i}`"
              :position="{x: Math.floor(position.x), y: Math.floor(position.y)}"
              :square-pixel-width="50"
              :square-pixel-height="50"
              :local-bus="localBus"
              :selectable="false"
              half="true"
            />
          </template>
        </g>
        <svg
          height="300%"
          width="300%"
          style="background: #cfcfcf;"
        >
          <template
            v-for="(position) in selectedPartialItems"
          >
            <grid-square
              :key="`selectedGridPartial-${position.x}-${position.y}`"
              :position="position"
              :half="latestHalfSelection === 'half-left-right' ? 'horizontal' : 'vertical'"
              :square-pixel-width="latestHalfSelection === 'half-left-right' ? 25 : 50"
              :square-pixel-height="latestHalfSelection === 'half-left-right' ? 50 : 25"
              :selectedItems="selectedPartialItems"
              :local-bus="localBus"
              :selectable="setLocationDialog && setFloorLocation"
            />
          </template>
        </svg>
        <svg
          id="gridEquipmentItems"
          :key="selectedItemId"
          height="300%"
          width="300%"
        >
          <template v-for="floorItem in floorData">
            <grid-floor-item
              :id="`floorItem-${floorItem.equipmentData.id}`"
              :key="floorItem.equipmentData.id"
              :floorItemData="floorItem"
              :selectedItemId="selectedItemId"
              :local-bus="localBus"
              :selectable="selectable(floorItem)"
              :selected-power-equipment="selectedPowerEquipment"
              :configure-floor-base-view-equipment-type="configureFloorBaseView && !configureFloor ? 'ACTIVE_EQUIPMENT' : ''"
            />
          </template>
          <template v-for="pendingItem in pendingData">
            <grid-floor-item
              :id="`pendingItem-${pendingItem.equipmentData.id}`"
              :key="pendingItem.equipmentData.id"
              :floorItemData="pendingItem"
              :selectedItemId="selectedItemId"
              :local-bus="localBus"
              :selectable="selectable(pendingItem)"
              :configure-floor-base-view-equipment-type="configureFloorBaseView && !configureFloor ? 'PENDING_EQUIPMENT' : ''"
            />
          </template>
          <template v-for="auditItem in auditData">
            <grid-floor-item
              :id="`auditItem-${auditItem.equipmentData.id}`"
              :key="auditItem.equipmentData.id"
              :floorItemData="auditItem"
              :selectedItemId="selectedItemId"
              :local-bus="localBus"
              :selectable="selectable(auditItem)"
              :configure-floor-base-view-equipment-type="configureFloorBaseView && !configureFloor ? 'PENDING_EQUIPMENT' : ''"
            />
          </template>
        </svg>
        <svg
          v-if="!configureFloor"
          id="gridWalls"
          height="300%"
          width="300%"
        >
          <template v-for="wallPiece in ewcWallData">
            <rect
              :key="`${wallPiece.x}-${wallPiece.y}`"
              :x="wallPiece.x * 50"
              :y="wallPiece.y * 50"
              :style="wallFillStyle"
              width="50"
              height="50"
              @mouseover="(e) => localBus.$emit('display-tooltip', ({ e: e, label: 'Wall'}))"
            />
          </template>
        </svg>
        <template v-for="(xLabel, i) in xLabelsArray">
          <text
            v-if="showGridLines"
            :key="`xLabel${xLabel}`"
            :y="-7"
            :x="i*50 + 25 - xLabel.length * 3"
          >
            {{ xLabel }}
          </text>
        </template>
        <template v-for="(yLabel, i) in yLabelsArray">
          <text
            v-if="showGridLines"
            :key="`yLabel${yLabel}`"
            :y="i*50 + 30"
            :x="-2/3 * gridXSize - 7 * yLabel.length"
          >
            {{ yLabel }}
          </text>
        </template>
      </svg>
    </div>
  </v-container>
</template>

<script>
  import fillStyles from './fillStyles'

  export default {
    name: 'GridWrapper',
    props: {
      localBus: {
        type: Object,
      },
      setLocationDialog: Boolean,
      setFloorLocation: Boolean,
      setEnclosureItemLocation: Boolean,
      floorSelectionType: {
        type: String,
        default: 'whole',
      },
      latestHalfSelection: {
        type: String,
        default: '',
      },
      floorplan: Object,
      loadingFloorplan: Boolean,
      redrawGrid: String,
      initialStateObject: Object,
      pendingEquipmentEwc: Array,
      auditEquipmentEwc: Array,
      selectedPowerEquipment: Array,
      configureFloor: Boolean,
      configureFloorBaseView: Boolean,
    },
    data: () => ({
      gridXSize: 0,
      gridYSize: 0,
      floorData: [],

      viewBoxX: 0,
      viewBoxY: 0,
      viewBoxW: 0,
      viewBoxH: 0,
      svgSizeW: 0,
      svgSizeH: 0,
      isPanning: false,
      startPointX: 0,
      startPointY: 0,
      endPointX: 0,
      endpointY: 0,
      scale: 1,
      gridArray: [],
      xLabelsArray: [],
      yLabelsArray: [],

      selectedItemsInitialState: [],
      selectedItems: [],
      selectedItemId: -1,
      selectedItemInitialStateId: '',
      viewboxIsSetup: false,
      gridIsSetup: false,

      selectedPartialItems: [],
      selectedPartialItemsInitialState: [],
      unselectableGridSpacesLeftRight: [],
      unselectableGridSpacesUpDown: [],

      wallFillStyle: fillStyles.wallFillStyle,
      ewcWallData: [],

      showGridLines: true,
      imagePath: null,
      imageHeight: 0,
      imageWidth: 0,
      imageXOffset: 0,
      imageYOffset: 0,

    }),
    computed: {
      unselectableGridSpaces () {
        if (this.floorSelectionType === 'half-left-right') {
          return this.unselectableGridSpacesUpDown
        } else if (this.floorSelectionType === 'half-up-down') {
          return this.unselectableGridSpacesLeftRight
        } else {
          return this.unselectableGridSpacesLeftRight.concat(this.unselectableGridSpacesUpDown, this.selectedPartialItems)
        }
      },
      pendingData () {
        if (!this.pendingEquipmentEwc) return []

        return this.pendingEquipmentEwc.filter(
          pendingItem => pendingItem.equipmentData.id !== this.initialStateObject?.equipmentData.id &&
            !pendingItem.location.parentEquipmentEwcId,
        )
      },
      auditData () {
        if (!this.auditEquipmentEwc) return []

        return this.auditEquipmentEwc.filter(
          auditItem => auditItem.equipmentData.id !== this.initialStateObject?.equipmentData.id &&
            !auditItem.location.parentEquipmentEwcId && !auditItem.location.parentAuditEquipmentId,
        )
      },
    },
    watch: {
      floorSelectionType (newSelection, oldSelection) {
        if ((newSelection === 'half-up-down' && oldSelection === 'half-left-right') ||
          (newSelection === 'half-left-right' && oldSelection === 'half-up-down')) {
          this.selectedPartialItems = []
          this.localBus.$emit('selectedItemsResponse', this.selectedItems, this.selectedPartialItems)
        }
      },

      floorplan (newFloorplan, oldFloorplan) {
        if (Object.keys(newFloorplan).length > 0) {
          this.setGrid(newFloorplan)
        }
      },

      redrawGrid () {
        this.$nextTick(() => { this.setGrid(this.floorplan) })
      },
    },
    created () {
      this.localBus.$on('onSelectFloorItem', (floorItem) => {
        this.selectedItemId = floorItem.equipmentData.id
      })

      this.localBus.$on('onSelectSquare', (position) => {
        const includes = this.includesPosition(position, this.selectedItems)
        if (includes) {
          const newSelectedItems = this.selectedItems.filter(item => item.x !== position.x || item.y !== position.y)
          this.selectedItems = newSelectedItems
        } else {
          this.selectedItems.push(position)
        }
        this.localBus.$emit('selectedItemsResponse', this.selectedItems, this.selectedPartialItems)
      })
      this.localBus.$on('onSelectHalfSquare', (position) => {
        const includes = this.includesPosition(position, this.selectedPartialItems)
        if (includes) {
          const newSelectedItems = this.selectedPartialItems.filter(item => item.x !== position.x || item.y !== position.y)
          this.selectedPartialItems = newSelectedItems
        } else {
          this.selectedPartialItems.push(position)
        }
        this.localBus.$emit('selectedItemsResponse', this.selectedItems, this.selectedPartialItems)
      })

      this.localBus.$on('openSetLocationDialog', (selectedFloorRectangles, selectedPartialRectangles, initialRack) => {
        this.$nextTick(() => { this.setGrid(this.floorplan) })
        this.selectedItems = [...selectedFloorRectangles]
        this.selectedPartialItems = [...selectedPartialRectangles]
        this.selectedItemId = initialRack
        this.localBus.$emit('selectedItemsResponse', this.selectedItems, this.selectedPartialItems)
      })

      this.localBus.$on('clearSelectedItems', () => {
        this.selectedItems = []
        this.selectedPartialItems = []
        this.selectedItemId = -1
      })
      this.localBus.$on('clearSelectedSquaresFloor', () => {
        this.selectedPartialItems = []
        this.selectedItems = []
      })
      this.localBus.$on('clearSelectedFloorItem', () => {
        this.selectedItemId = -1
      })

      this.localBus.$on('initiate-edit-wall', () => {
        this.selectedItems = [...this.ewcWallData]
      })

      this.localBus.$on('hide-grid-lines', () => {
        this.showGridLines = false
      })
      this.localBus.$on('show-grid-lines', () => {
        this.showGridLines = true
      })
    },
    mounted () {
      if (Object.keys(this.floorplan).length > 0) {
        this.setGrid(this.floorplan)
      }
    },

    methods: {
      setupViewbox: function () {
        this.viewBoxH = this.gridYSize ? this.gridYSize * 50 + 100 : this.$refs.svgContainer.clientHeight
        this.viewBoxW = this.gridXSize ? this.gridXSize * 50 + 100 : this.$refs.svgContainer.clientWidth

        this.$refs.svgImage.setAttribute('viewBox', `${this.viewBoxX} ${this.viewBoxY} ${this.viewBoxW} ${this.viewBoxH}`)
        this.svgSizeW = this.$refs.svgImage.clientWidth
        this.svgSizeH = this.$refs.svgImage.clientHeight

        const vm = this

        this.$refs.svgContainer.onmousewheel = function (e) {
          e.preventDefault()
          const w = vm.viewBoxW
          const h = vm.viewBoxH
          const mx = e.offsetX
          const my = e.offsetY
          const dw = w * Math.sign(e.deltaY) * 0.05
          const dh = h * Math.sign(e.deltaY) * 0.05
          const dx = dw * mx / vm.svgSizeW
          const dy = dh * my / vm.svgSizeH

          // limit how far one can zoom in or out
          if (vm.viewBoxW - dw < vm.gridXSize * 25 || vm.viewBoxW - dw > vm.gridXSize * 75) return
          vm.viewBoxX = vm.viewBoxX + dx
          vm.viewBoxY = vm.viewBoxY + dy
          vm.viewBoxW = vm.viewBoxW - dw
          vm.viewBoxH = vm.viewBoxH - dh
          vm.scale = vm.svgSizeW / vm.viewBoxW
          vm.$refs.svgImage.setAttribute('viewBox', `${vm.viewBoxX} ${vm.viewBoxY} ${vm.viewBoxW} ${vm.viewBoxH}`)
        }

        this.$refs.svgContainer.onmousedown = function (e) {
          vm.isPanning = true
          vm.startPointX = e.x
          vm.startPointY = e.y
        }

        this.$refs.svgContainer.onmousemove = function (e) {
          if (vm.isPanning) {
            vm.endPointX = e.x
            vm.endPointY = e.y
            const dx = (vm.startPointX - vm.endPointX) / vm.scale
            const dy = (vm.startPointY - vm.endPointY) / vm.scale
            vm.$refs.svgImage.setAttribute('viewBox', `${(vm.viewBoxX + dx)} ${(vm.viewBoxY + dy)} ${vm.viewBoxW} ${vm.viewBoxH}`)
          }
        }

        this.$refs.svgContainer.onmouseup = function (e) {
          if (vm.isPanning) {
            vm.endPointX = e.x
            vm.endPointY = e.y
            const dx = (vm.startPointX - vm.endPointX) / vm.scale
            const dy = (vm.startPointY - vm.endPointY) / vm.scale
            vm.viewBoxX = vm.viewBoxX + dx
            vm.viewBoxY = vm.viewBoxY + dy
            vm.$refs.svgImage.setAttribute('viewBox', `${vm.viewBoxX} ${vm.viewBoxY} ${vm.viewBoxW} ${vm.viewBoxH}`)
            vm.isPanning = false
          }
        }

        this.$refs.svgContainer.onmouseleave = function (e) {
          vm.isPanning = false
        }

        this.viewboxIsSetup = true
      },
      setGrid: function (gridData) {
        if (!this.$refs.svgImage) return

        if (Object.keys(this.floorplan).length === 0) {
          this.imagePath = null
        } else if (!this.floorplan.dataCenterGrid.imagePath) {
          this.imagePath = null
        } else {
          this.imageHeight = this.floorplan.dataCenterGrid.imageHeight
          this.imageWidth = this.floorplan.dataCenterGrid.imageWidth
          this.imageXOffset = this.floorplan.dataCenterGrid.imageXOffset
          this.imageYOffset = this.floorplan.dataCenterGrid.imageYOffset
          this.imagePath = require('../../assets/ewc/' + this.floorplan.dataCenterGrid.imagePath)
        }

        this.gridXSize = gridData.dataCenterGrid.xsize
        this.gridYSize = gridData.dataCenterGrid.ysize
        this.floorData = gridData.activeEquipmentEwc.filter(equipment => equipment.equipmentData.id !== this.initialStateObject?.equipmentData.id && equipment.equipmentData.id !== this.initialStateObject?.equipmentToEditId &&
          !equipment.equipmentData.unitLocation && !equipment.equipmentData.bay)
        this.xLabelsArray = gridData.dataCenterGrid.xlabels
        this.yLabelsArray = gridData.dataCenterGrid.ylabels

        this.ewcWallData = JSON.parse(this.floorplan.ewcWall.wallArray).map(wallSquareString => {
          const splitWallSquareStringArray = wallSquareString.split('-')
          return {
            x: parseInt(splitWallSquareStringArray[0]),
            y: parseInt(splitWallSquareStringArray[1]),
          }
        })

        let x = 0
        let y = 0
        this.gridArray = []
        for (let i = 0; i < this.gridXSize * this.gridYSize; i++) {
          this.gridArray.push({ x, y })
          if (x < this.gridXSize - 1) {
            x++
          } else {
            x = 0
            y++
          }
        }

        // check for elements that take up half size of a grid square
        this.floorData.concat(this.pendingData).forEach(e => {
          if (e.location.gridY % 1 !== 0) {
            for (let i = 0; i < e.location.uwidth; i++) {
              this.unselectableGridSpacesUpDown.push({ x: e.location.gridX + i, y: e.location.gridY })
            }
          }
          if ((e.location.gridY + e.location.uheight) % 1 !== 0) {
            for (let i = 0; i < e.location.uwidth; i++) {
              this.unselectableGridSpacesUpDown.push({ x: e.location.gridX + i, y: e.location.gridY + e.location.uheight })
            }
          }
          if (e.location.gridX % 1 !== 0) {
            for (let i = 0; i < e.location.uheight; i++) {
              this.unselectableGridSpacesLeftRight.push({ x: e.location.gridX, y: e.location.gridY + i })
            }
          }
          if ((e.location.gridX + e.location.uwidth) % 1 !== 0) {
            for (let i = 0; i < e.location.uheight; i++) {
              this.unselectableGridSpacesLeftRight.push({ x: e.location.gridX + e.location.uwidth, y: e.location.gridY + i })
            }
          }
        })

        this.setupViewbox()
        this.viewBoxX = -(this.viewBoxW / 2 - this.gridXSize * 50 / 2)
        this.viewBoxY = -(this.viewBoxH / 2 - this.gridYSize * 50 / 2)

        this.$refs.svgImage.setAttribute('viewBox', `${this.viewBoxX} ${this.viewBoxY} ${this.viewBoxW} ${this.viewBoxH}`)
        this.gridIsSetup = true
      },

      containsEnclosure: function (floorItem) {
        let contains = false
        floorItem.children.forEach(child => {
          if (child.equipmentModel.capacity > 0) {
            contains = true
          }
        })
        this.pendingEquipmentEwc.forEach(child => {
          if (child.location.parentEquipmentEwcId === floorItem.equipmentData.id && child.equipmentModel.capacity > 0) {
            contains = true
          }
        })
        if (this.auditEquipmentEwc) {
          this.auditEquipmentEwc.forEach(child => {
            if ((child.location.parentEquipmentEwcId === floorItem.equipmentData.id || -child.location.parentAuditEquipmentId === floorItem.equipmentData.id) && child.equipmentModel.capacity > 0) {
              contains = true
            }
          })
        }
        return contains
      },

      selectable: function (floorItem) {
        if (!this.setLocationDialog ||
          (this.setLocationDialog && !this.setFloorLocation && !this.setEnclosureItemLocation && floorItem.equipmentModel.capacity > 0) ||
          (this.setEnclosureItemLocation && this.containsEnclosure(floorItem))
        ) {
          return true
        } else {
          return false
        }
      },

      includesPosition (position, array) {
        let includes = false
        array.forEach((item) => {
          if (item.x === position.x && item.y === position.y) {
            includes = true
          }
        })
        return includes
      },
    },
  }
</script>
<style lang="scss">
.v-skeleton-loader__image.v-skeleton-loader__bone { height: 100%; }
</style>
