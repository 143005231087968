<template>
  <g>
    <rect
      :x="floorItemData.location.pixelTopX ? floorItemData.location.pixelTopX : floorItemData.location.gridX*50"
      :y="floorItemData.location.pixelTopY ? floorItemData.location.pixelTopY : floorItemData.location.gridY *50"
      :width="floorItemData.location.uwidth*50"
      :height="floorItemData.location.uheight*50"
      :style="fillStyle"
      @click="selectable ? onSelect() : ''"
      @mousemove="onMouseMove"
      @mouseleave="localBus.$emit('hide-tooltip', floorItemData.equipmentData.label ? floorItemData.equipmentData.label : floorItemData.equipmentModel.name)"
    />
  </g>
</template>

<script>
  import fillStyles from './fillStyles'

  export default {
    name: 'GridFloorItem',
    props: {
      floorItemData: {
        type: Object,
        default: () => ({}),
      },
      localBus: {
        type: Object,
      },
      selectable: Boolean,
      selectedItemId: Number,
      selectedPowerEquipment: Array,
      configureFloorBaseViewEquipmentType: String,
    },
    data: (instance) => ({
      selectedFillStyle: fillStyles.selectedFillStyle,
      standardFillStyle: fillStyles.standardFillStyle,
      standardFillStyleNoSelect: fillStyles.standardFillStyleNoSelect,
      unselectableFillStyle: fillStyles.unselectableFillStyle,
      pendingFillStyle: fillStyles.pendingFillStyle,
      verifiedRackEquipment: [],

      lastMove: 0,
      eventThrottle: 25,
    }),
    computed: {
      fillStyle () {
        if (this.configureFloorBaseViewEquipmentType === 'PENDING_EQUIPMENT') {
          return this.pendingFillStyle
        }
        if (this.configureFloorBaseViewEquipmentType === 'ACTIVE_EQUIPMENT') {
          return this.standardFillStyleNoSelect
        }
        if (this.selectedPowerEquipment?.includes(this.floorItemData.equipmentData.id)) {
          return this.selectedFillStyle
        }
        if (this.verifiedRackEquipment.includes(this.floorItemData.equipmentData.id)) {
          return this.selectedFillStyle
        }
        if (this.selectedItemId === this.floorItemData.equipmentData.id) {
          return this.selectedFillStyle
        } else {
          if (this.selectable) {
            return this.standardFillStyle
          } else {
            return this.unselectableFillStyle
          }
        }
      },
    },
    created () {
      this.localBus.$on('show-audit-verified', verifiedRacks => {
        this.verifiedRackEquipment = verifiedRacks.map(rack => { return rack.equipmentEwcId })
      })
    },
    methods: {
      onSelect: function () {
        if (this.selectedItemId !== this.floorItemData.equipmentData.id && this.selectable) {
          this.localBus.$emit('onSelectFloorItem', this.floorItemData)
        } else if (this.selectedItemId === this.floorItemData.equipmentData.id) {
          this.localBus.$emit('clearSelectedFloorItem')
        }
      },
      onMouseMove (e) {
        e.preventDefault()
        // use eventThrottle to limit frequency of events emitted because of mouse move
        const now = Date.now()
        if (now > this.lastMove + this.eventThrottle) {
          this.lastMove = now
          this.localBus.$emit('display-tooltip', ({ e: e, label: this.floorItemData.equipmentData.label ? this.floorItemData.equipmentData.label : this.floorItemData.equipmentModel.name }))
        }
      },
    },
  }
</script>
