var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"admin-view","fluid":""}},[_c('v-card',{staticClass:"pa-2",staticStyle:{"width":"100%","height":"100%"}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-card-title',{staticClass:"text-h4 font-weight-bold grey--text"},[_vm._v(" "+_vm._s(_vm.siteName)+" ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","text":"","min-width":"50","width":"50"},on:{"click":function($event){return _vm.$emit('addUserToSite', {siteId: _vm.siteId, siteName: _vm.siteName})}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.mdiAccountPlus)+" ")])],1)]}}])},[_c('span',[_vm._v("Add user to "+_vm._s(_vm.siteName))])]),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","text":"","min-width":"50","width":"50"},on:{"click":function($event){return _vm.$emit('downloadUserData', _vm.users, _vm.siteName)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.mdiDownload)+" ")])],1)]}}])},[_c('span',[_vm._v("Download user data")])])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"item-key":"email","loading":_vm.loadingUsersForSite === _vm.siteId,"items":_vm.users,"fixed-header":true,"calculate-widths":"","height":"600"},scopedSlots:_vm._u([{key:"body.prepend",fn:function(){return [_c('tr',{staticClass:"no-pointer"},[_c('td',[_c('v-text-field',{staticClass:"pt-4",attrs:{"type":"text","outlined":"","dense":"","label":"filter..."},model:{value:(_vm.tableEmail),callback:function ($$v) {_vm.tableEmail=$$v},expression:"tableEmail"}})],1),_c('td',[_c('v-text-field',{staticClass:"pt-4",attrs:{"type":"text","outlined":"","dense":""},model:{value:(_vm.tableName),callback:function ($$v) {_vm.tableName=$$v},expression:"tableName"}})],1),_c('td',[_c('v-text-field',{staticClass:"pt-4",attrs:{"type":"text","outlined":"","dense":""},model:{value:(_vm.tableRole),callback:function ($$v) {_vm.tableRole=$$v},expression:"tableRole"}})],1)])]},proxy:true},{key:"item.userRole",fn:function(props){return [_c('td',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',[_vm._v(" "+_vm._s(props.item.userRole))])]),_c('v-spacer'),_c('div',{staticClass:"admin-icon"},[_c('v-tooltip',{attrs:{"bottom":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":_vm.removeIconActive === props.item.email ? 'primary' : 'grey darken-1',"text":""},on:{"mouseenter":function($event){_vm.removeIconActive = props.item.email},"mouseleave":function($event){_vm.removeIconActive = ''},"click":function($event){return _vm.$emit('removeUser', {user: props.item, site: {siteName: _vm.siteName, siteId: _vm.siteId } })}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.mdiAccountMinus)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Remove user from "+_vm._s(_vm.siteName))])])],1),_c('div',{staticClass:"admin-icon"},[_c('v-tooltip',{attrs:{"bottom":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":_vm.updateIconActive === props.item.email ? 'primary' : 'grey darken-1',"text":""},on:{"mouseenter":function($event){_vm.updateIconActive = props.item.email},"mouseleave":function($event){_vm.updateIconActive = ''},"click":function($event){return _vm.$emit('updateUser', {user: props.item, site: {siteName: _vm.siteName, siteId: _vm.siteId } })}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.mdiAccountArrowUp)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Update user role")])])],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }