import axios from 'axios'
import common from '@/api/common.js'

export default {
  signup: function (username, firstName, lastName, roleId, siteIds) {
    const api = axios.create()
    console.log('signup()', username, firstName, lastName, roleId, siteIds)

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/auth/signup`,
      data: {
        username: username,
        firstName: firstName,
        lastName: lastName,
        roleId: roleId,
        siteIds: siteIds,
      },
    })
  },
  validateId: function (tenantId, resetId) {
    const api = axios.create()
    console.log('validateId()', tenantId, resetId)

    return api({
      method: 'post',
      url: `${common.getUrlPrefix()}/auth/validateId`,
      data: {
        tenantId,
        resetId,
      },
    })
  },
  updateAuth: function (tenantId, resetId, password) {
    const api = axios.create()
    console.log('updateAuth')

    return api({
      method: 'post',
      url: `${common.getUrlPrefix()}/auth/updateAuth`,
      data: {
        tenantId,
        resetId,
        password,
      },
    })
  },
  startResetRequest: function (tenantId, username) {
    const api = axios.create()
    console.log('startResetRequest')

    return api({
      method: 'post',
      url: `${common.getUrlPrefix()}/auth/startResetRequest`,
      data: {
        tenantId,
        username,
      },
    })
  },
}
