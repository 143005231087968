<template>
  <rect
    :x="half === 'horizontal' ? position.x * squarePixelWidth * 2 : position.x * squarePixelWidth"
    :y="half === 'vertical' ? position.y * squarePixelHeight * 2 : position.y * squarePixelHeight"
    :width="squarePixelWidth"
    :height="squarePixelHeight"
    :style="fillStyle"
    @click="selectable ? onSelect() : ''"
    @mousemove="e => (setLocationDialog || configureFloor) ? onMouseMove(e) : ''"
    @mouseleave="(setLocationDialog || configureFloor) ? localBus.$emit('hide-tooltip') : ''"
  />
</template>

<script>
  import fillStyles from './fillStyles'

  export default {
    name: 'GridSquare',
    props: {
      position: {
        type: Object,
        default: () => ({}),
      },
      squarePixelHeight: Number,
      squarePixelWidth: Number,
      localBus: {
        type: Object,
      },
      selectedItems: {
        type: Array,
        default: () => [],
      },
      selectable: Boolean,
      half: {
        type: String,
        default: '',
      },
      setLocationDialog: Boolean,
      configureFloor: Boolean,
    },
    data: (instance) => ({
      standardFillStyle: `${instance.half ? 'fill: transparent; stroke-width:0;' : 'fill: transparent; stroke-width:3;' }  stroke: rgb(0, 0, 0); ${instance.selectable ? 'cursor: pointer;' : ''}`,
      selectedFillStyle: `${fillStyles.selectedFillStyle} ${instance.selectable ? 'cursor: pointer' : ''}`,

      lastMove: 0,
      eventThrottle: 25,
    }),
    computed: {
      fillStyle () {
        let selected = false
        this.selectedItems.forEach((item) => {
          if (item.x === this.position.x && item.y === this.position.y) {
            selected = true
          }
        })
        if (selected) {
          return this.selectedFillStyle
        } else {
          return this.standardFillStyle
        }
      },
    },
    created () {
    },
    methods: {
      onSelect: function () {
        if (this.half) {
          this.localBus.$emit('onSelectHalfSquare', this.position)
        } else {
          this.localBus.$emit('onSelectSquare', this.position)
        }
      },
      onMouseMove (e) {
        e.preventDefault()
        // use eventThrottle to limit frequency of events emitted because of mouse move
        const now = Date.now()
        if (now > this.lastMove + this.eventThrottle) {
          this.lastMove = now
          this.localBus.$emit('display-tooltip-grid-square', ({ e: e, label: { x: this.position.x, y: this.position.y } }))
        }
      },
    },
  }
</script>
