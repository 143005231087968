<template>
  <svg
    :key="updateSvg ? updateSvg : -1"
    ref="ewcRackSvg"
    height="100%"
    width="100%"
  >
    <g
      id="ewcRackRectangles"
    >
      <template
        v-for="(i) in SVGArray"
      >
        <ewc-rack-rectangle
          :key="'rack rectangle' + i"
          :uPosition="i + 1"
          :rectangleWidth="uWidthPixels"
          :rectangleHeight="uHeightPixels"
          :xOffsetPixels="xOffsetPixels"
          :yOffsetPixels="yOffsetPixels"
          :localBus="localBus"
          :selectedItems="selectedItems"
          :selectable="setLocationDialog && setRackLocation"
          :total-rectangles="rackData.equipmentModel.capacity - 1"
          :set-location-dialog="setLocationDialog"
        />
        <line
          :key="'tick' + i"
          :x1="xOffsetPixels - 15"
          :y1="(i+1)*uHeightPixels + yOffsetPixels"
          :x2="xOffsetPixels"
          :y2="(i+1)*uHeightPixels + yOffsetPixels"
          stroke="rgb(207, 207, 207)"
        />
        <text
          v-if="(i+1)%5 === 0 || i === 0"
          :key="'label' + i"
          :x="0"
          :y="(rackData.equipmentModel.capacity*uHeightPixels - i*uHeightPixels) + 5 + yOffsetPixels"
          style="text-align: right; user-select: none;"
        >
          {{ `${i+1 &lt; 10 ? '&nbsp;' : ''} ${i+1} ` }}
        </text>
      </template>
    </g>
    <template v-for="child in rackDataChildren">
      <ewc-rack-item
        :id="`rackItem-${child.equipmentData.id}`"
        :key="child.equipmentData.id"
        :rack-item="child"
        :uPosition="child.location.gridY"
        :rectangleWidth="uWidthPixels"
        :rectangleHeight="uHeightPixels"
        :xOffsetPixels="xOffsetPixels"
        :yOffsetPixels="yOffsetPixels"
        :localBus="localBus"
        :selectable="!setLocationDialog || (setEnclosureLocation && child.equipmentModel.capacity > 0) "
        :selectedItemId="selectedItemId"
        :selectedPowerEquipment="selectedPowerEquipment"
        :total-rectangles="rackData.equipmentModel.capacity - 1"
      />
    </template>
    <template v-for="child in pendingChildren">
      <ewc-rack-item
        :id="`rackItem-${child.equipmentData.id}`"
        :key="child.equipmentData.id"
        :rack-item="child"
        :uPosition="child.location.gridY"
        :rectangleWidth="uWidthPixels"
        :rectangleHeight="uHeightPixels"
        :xOffsetPixels="xOffsetPixels"
        :yOffsetPixels="yOffsetPixels"
        :localBus="localBus"
        :selectable="!setLocationDialog || (setEnclosureLocation && child.equipmentModel.capacity > 0) "
        :selectedItemId="selectedItemId"
        :total-rectangles="rackData.equipmentModel.capacity - 1"
      />
    </template>
    <template v-for="child in auditChildren">
      <ewc-rack-item
        :id="`rackItem-${child.equipmentData.id}`"
        :key="child.equipmentData.id"
        :rack-item="child"
        :uPosition="child.location.gridY"
        :rectangleWidth="uWidthPixels"
        :rectangleHeight="uHeightPixels"
        :xOffsetPixels="xOffsetPixels"
        :yOffsetPixels="yOffsetPixels"
        :localBus="localBus"
        :selectable="!setLocationDialog || (setEnclosureLocation && child.equipmentModel.capacity > 0) "
        :selectedItemId="selectedItemId"
        :total-rectangles="rackData.equipmentModel.capacity - 1"
      />
    </template>
    <rect
      id="selectRectangle"
      :x="dragRectangleX"
      :y="dragRectangleY"
      :width="dragRectangleWidth"
      :height="dragRectangleHeight"
      :style="dragFillStyle"
    />
  </svg>
</template>

<script>
  import fillStyles from './fillStyles'

  export default {
    name: 'EwcRack',
    props: {
      localBus: {
        type: Object,
      },
      rackData: {
        type: Object,
        default: () => ({ equipmentModel: { capacity: 42 } }),
      },
      setLocationDialog: Boolean,
      setRackLocation: Boolean,
      setEnclosureLocation: Boolean,
      updateSvg: Number,
      pendingEquipmentEwc: Array,
      auditEquipmentEwc: Array,
      initialStateObject: Object,
      selectedPowerEquipment: Array,
    },
    data: () => ({
      SVGArray: [],
      xOffsetPixels: 35,
      yOffsetPixels: 0,

      selectedItems: [],
      selectedItemId: -1,

      dragRectangleWidth: 0,
      dragRectangleHeight: 0,
      dragRectangleX: 0,
      dragRectangleY: 0,
      isDrawing: false,
      mouseDownTime: 0,
      quickClick: false,
      occupiedSpaces: [],
      dragFillStyle: fillStyles.selectedFillStyle,

      viewBoxH: 0,
      viewBoxW: 0,

    }),
    computed: {
      pendingChildren () {
        if (!this.pendingEquipmentEwc) return []

        return this.pendingEquipmentEwc.filter(
          pendingEquipmentItem => pendingEquipmentItem.location.parentEquipmentEwcId === this.rackData.equipmentData.id &&
          this.initialStateObject?.equipmentData.id !== pendingEquipmentItem.equipmentData.id,
        )
      },
      auditChildren () {
        if (!this.auditEquipmentEwc) return []
        // less than zero means it is a piece of audit equipment, assigning all of those negative values
        if (this.rackData.equipmentData.id < 0) {
          return this.auditEquipmentEwc.filter(
            auditEquipmentItem => auditEquipmentItem.location.parentAuditEquipmentId === -this.rackData.equipmentData.id &&
          this.initialStateObject?.equipmentData.id !== auditEquipmentItem.equipmentData.id)
        } else {
          return this.auditEquipmentEwc.filter(
            auditEquipmentItem => auditEquipmentItem.location.parentEquipmentEwcId === this.rackData.equipmentData.id &&
          this.initialStateObject?.equipmentData.id !== auditEquipmentItem.equipmentData.id)
        }
      },
      rackDataChildren () {
        if (!this.rackData.children) return []
        return this.rackData.children.filter(child => child.equipmentData.id !== this.initialStateObject?.equipmentData.id && child.equipmentData.id !== this.initialStateObject?.equipmentToEditId)
      },
      uHeightPixels () {
        if (!this.viewBoxH) return 0
        return this.$refs.ewcRackSvg ? this.$refs.ewcRackSvg.clientHeight / this.rackData.equipmentModel.modelRows : 0
      },
      uWidthPixels () {
        if (!this.viewBoxW) return 0
        return this.$refs.ewcRackSvg ? this.$refs.ewcRackSvg.clientWidth - 100 : 0
      },
    },

    created () {
      for (let i = 0; i < this.rackData.equipmentModel.capacity; i++) {
        this.SVGArray.push(i)
      }

      this.localBus.$on('onSelectRackRectangle', uPosition => {
        if (this.selectedItems.includes(uPosition)) {
          this.selectedItems = this.selectedItems.filter(item => item !== uPosition)
        } else {
          this.selectedItems.push(uPosition)
        }

        this.localBus.$emit('selectedRackItemsResponse', this.selectedItems)
      })

      this.localBus.$on('openRackSetLocationDialogInitialRectangles', selectedRackItems => {
        this.selectedItems = [...selectedRackItems]
        this.localBus.$emit('selectedRackItemsResponse', this.selectedItems)
      })

      this.localBus.$on('openEnclosureItemSetLocationDialog', (selectedEnclosureItems, selectedEnclosure) => {
        this.selectedItemId = selectedEnclosure.equipmentData?.id
      })

      this.localBus.$on('onSelectFloorItem', floorItem => {
        this.selectedItems = []
      })

      this.localBus.$on('clearSelectedItems', () => {
        this.selectedItems = []
        this.selectedItemId = -1
      })
      this.localBus.$on('clearSelectedRectanglesRack', () => {
        this.selectedItems = []
      })
      this.localBus.$on('clearSelectedRackItem', () => {
        this.selectedItemId = -1
      })

      this.localBus.$on('onSelectRackItem', (rackItem) => {
        this.selectedItemId = rackItem.equipmentData.id
      })
      this.localBus.$on('onSelectEnclosure', enclosure => {
        this.selectedItemId = enclosure.equipmentData.id
      })
    },
    mounted () {
      this.setView()
      const vm = this

      // set up drag select
      if (vm.setRackLocation) {
        this.calculateOccupiedSpaces()
        let startX
        let startY

        this.$refs.ewcRackSvg.addEventListener('mousedown', (e) => {
          this.mouseDownTime = Date.now()
          this.quickClick = false
          setTimeout(() => {
            this.isDrawing = true
            startX = e.offsetX
            startY = e.offsetY - 22
          }, [200])
        })
        this.$refs.ewcRackSvg.addEventListener('mouseup', this.onMouseUp)
        this.$refs.ewcRackSvg.addEventListener('mousemove', (e) => {
          if (this.isDrawing && (!this.quickClick)) {
            if (e.layerY < startY) {
              this.dragRectangleY = e.offsetY - 22
              this.dragRectangleHeight = startY - e.offsetY + 22
            } else {
              this.dragRectangleY = startY
              this.dragRectangleHeight = e.offsetY - startY - 22
            }
            if (e.layerX < startX) {
              this.dragRectangleX = e.layerX
              this.dragRectangleWidth = startX - e.offsetX
            } else {
              this.dragRectangleX = startX
              this.dragRectangleWidth = e.offsetX - startX
            }
          }
        })
        window.addEventListener('mouseup', this.onMouseUp)
      }

      window.addEventListener('resize', () => this.setView())
    },

    updated () {
      this.setView()
    },

    destroyed () {
      window.removeEventListener('mouseup', this.onMouseUp)
    },

    methods: {
      dragSelect () {
        const selectRectangle = document.getElementById('selectRectangle')
        const ewcRackRectangles = document.getElementById('ewcRackRectangles')
        ewcRackRectangles.getElementsByTagName('rect').forEach(el => {
          if (parseInt(el.attributes.y.value) + this.uHeightPixels > parseInt(selectRectangle.attributes.y.value) &&
            parseInt(el.attributes.y.value) < parseInt(selectRectangle.attributes.y.value) + parseInt(selectRectangle.attributes.height.value) &&
            parseInt(el.attributes.x.value) < parseInt(selectRectangle.attributes.x.value) + parseInt(selectRectangle.attributes.width.value)) {
            if (!this.selectedItems.includes(parseInt(el.id)) && !this.occupiedSpaces.includes(parseInt(el.id))) {
              this.selectedItems.push(parseInt(el.id))
            }
          }
        })
        this.localBus.$emit('selectedRackItemsResponse', this.selectedItems)
      },
      calculateOccupiedSpaces () {
        this.rackData.children.concat(this.pendingChildren).forEach(child => {
          for (let i = child.location.gridY; i < child.location.gridY + child.location.uheight; i++) {
            this.occupiedSpaces.push(i)
          }
        })
      },
      onMouseUp (e) {
        if (this.dragRectangleHeight > 0) {
          this.dragSelect()
        }
        if (this.mouseDownTime > Date.now() - 200) {
          this.quickClick = true
          setTimeout(() => {
            this.isDrawing = false
            this.dragRectangleWidth = 0
            this.dragRectangleHeight = 0
            this.dragRectangleX = 0
            this.dragRectangleY = 0
          }, [200])
        } else {
          this.isDrawing = false
          this.dragRectangleWidth = 0
          this.dragRectangleHeight = 0
          this.dragRectangleX = 0
          this.dragRectangleY = 0
        }
      },
      setView () {
        const vm = this
        if (!vm.$refs.ewcRackSvg?.clientHeight) return
        this.viewBoxH = vm.$refs.ewcRackSvg.clientHeight + 10
        this.viewBoxW = vm.$refs.ewcRackSvg.clientWidth
        const viewBoxX = -35
        const viewBoxY = 0

        this.$refs.ewcRackSvg.setAttribute('viewBox', `${viewBoxX} ${viewBoxY} ${this.viewBoxW} ${this.viewBoxH}`)
      },
    },
  }
</script>
