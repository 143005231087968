<template>
  <v-container
    id="admin-view"
    fluid
  >
    <v-card
      style="width: 100%; height: 100%"
      class="pa-2"
    >
      <div class="d-flex align-center">
        <v-card-title
          class="text-h4 font-weight-bold grey--text"
        >
          {{ siteName }}
        </v-card-title>
        <v-spacer />
        <v-tooltip
          bottom
          open-delay="1000"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              text
              v-bind="attrs"
              min-width="50"
              width="50"
              v-on="on"
              @click="$emit('addUserToSite', {siteId, siteName})"
            >
              <v-icon>
                {{ mdiAccountPlus }}
              </v-icon>
            </v-btn>
          </template>
          <span>Add user to {{ siteName }}</span>
        </v-tooltip>
        <v-tooltip
          bottom
          open-delay="1000"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              text
              min-width="50"
              width="50"
              v-bind="attrs"
              v-on="on"
              @click="$emit('downloadUserData', users, siteName)"
            >
              <v-icon>
                {{ mdiDownload }}
              </v-icon>
            </v-btn>
          </template>
          <span>Download user data</span>
        </v-tooltip>
      </div>
      <v-data-table
        :headers="headers"
        item-key="email"
        :loading="loadingUsersForSite === siteId"
        :items="users"
        :fixed-header="true"
        calculate-widths
        height="600"
      >
        <template v-slot:[`body.prepend`]>
          <tr class="no-pointer">
            <td>
              <v-text-field
                v-model="tableEmail"
                type="text"
                class="pt-4"
                outlined
                dense
                label="filter..."
              />
            </td>
            <td>
              <v-text-field
                v-model="tableName"
                type="text"
                class="pt-4"
                outlined
                dense
              />
            </td>
            <td>
              <v-text-field
                v-model="tableRole"
                type="text"
                class="pt-4"
                outlined
                dense
              />
            </td>
          </tr>
        </template>
        <template v-slot:[`item.userRole`]="props">
          <td
            class="d-flex align-center"
          >
            <div class="d-flex align-center">
              <div> {{ props.item.userRole }}</div>
            </div>
            <v-spacer />
            <div class="admin-icon">
              <v-tooltip
                bottom
                open-delay="1000"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :color="removeIconActive === props.item.email ? 'primary' : 'grey darken-1'"
                    text
                    v-bind="attrs"
                    v-on="on"
                    @mouseenter="removeIconActive = props.item.email"
                    @mouseleave="removeIconActive = ''"
                    @click="$emit('removeUser', {user: props.item, site: {siteName, siteId } })"
                  >
                    <v-icon>
                      {{ mdiAccountMinus }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Remove user from {{ siteName }}</span>
              </v-tooltip>
            </div>
            <div class="admin-icon">
              <v-tooltip
                bottom
                open-delay="1000"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :color="updateIconActive === props.item.email ? 'primary' : 'grey darken-1'"
                    text
                    v-bind="attrs"
                    v-on="on"
                    @mouseenter="updateIconActive = props.item.email"
                    @mouseleave="updateIconActive = ''"
                    @click="$emit('updateUser', {user: props.item, site: {siteName, siteId } })"
                  >
                    <v-icon>
                      {{ mdiAccountArrowUp }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Update user role</span>
              </v-tooltip>
            </div>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
  import { mdiAccountPlus, mdiAccountMinus, mdiAccountArrowUp, mdiDownload } from '@mdi/js'

  export default {
    name: 'AdminDataTable',
    props: {
      users: {
        type: Array,
        default: () => ([]),
      },
      siteId: {
        type: Number,
        default: -1,
      },
      siteName: {
        type: String,
        default: '',
      },
      loadingUsersForSite: Number,
    },
    data: () => ({
      mdiAccountPlus: mdiAccountPlus,
      mdiAccountMinus: mdiAccountMinus,
      mdiAccountArrowUp: mdiAccountArrowUp,
      mdiDownload: mdiDownload,

      tableEmail: '',
      tableName: '',
      tableRole: '',

      updateIconActive: '',
      removeIconActive: '',
    }),
    computed: {
      headers () {
        return [
          {
            text: 'Email',
            value: 'email',
            filter: value => {
              if (!this.tableEmail) return true
              return (value)?.toLowerCase().includes(this.tableEmail.toLowerCase())
            },
          },
          {
            text: 'Name',
            value: 'name',
            filter: value => {
              if (!this.tableName) return true
              return (value)?.toLowerCase().includes(this.tableName.toLowerCase())
            },
          },
          {
            text: 'Role',
            value: 'userRole',
            filter: value => {
              if (!this.tableRole) return true
              return (value)?.toLowerCase().includes(this.tableRole.toLowerCase())
            },
          },
        ]
      },
    },
    methods: {
    },
  }
</script>
<style css-scoped>
.admin-icon  :hover {
  cursor: pointer;
}
.admin-icon .v-btn {
  min-width: 50px !important;
  max-width: 50px !important;
}
</style>
