import axios from 'axios'
import common from '@/api/common.js'

const api = axios.create()

export default {
  getSocketNotificationUrl: function () {
    console.log('getSocketNotificationUrl()')
    const headers = common.createHeaders()

    return api({
      method: 'get',
      headers: headers,
      url: `${common.getUrlPrefix()}/websocket/get/notification/url`,
    })
  },
}
