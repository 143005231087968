<template>
  <div
    style="width: 100%; height: 100%;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0);
        border-radius: 6px;"
  >
    <v-data-table
      class="no-hover"
      :headers="headers"
      selectable-key="-1"
      item-key="id"
      :items="selectedEquipmentData"
      :fixed-header="true"
      disable-sort
      calculate-widths
      hide-default-footer
    >
      <template
        v-for="header in headers.filter((header) =>
          header.hasOwnProperty('formatter')
        )"
        v-slot:[`item.${header.value}`]="{ value }"
      >
        {{ header.formatter(value) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
  import moment from 'moment'

  export default {
    name: 'EwcSingleSelectedInfo',
    props: {
      selectedEquipmentData: {
        type: Array,
      },
    },
    data: () => ({
    }),
    computed: {
      headers () {
        return [
          {
            text: 'Label',
            value: 'label',
            width: '150',
          },
          {
            text: 'Status',
            value: 'status',
            width: '200',
          },
          {
            text: 'Serial',
            value: 'serial',
            width: '150',
          },
          {
            text: 'Asset Tag',
            value: 'assetTag',
            width: '150',
          },
          {
            text: 'Model',
            value: 'model',
            width: '150',
          },
          {
            text: 'Rack Location',
            value: 'rackLocation',
            width: '200',
          },
          {
            text: 'Unit Location',
            value: 'unitLocation',
            width: '200',
          },
          {
            text: 'Bay',
            value: 'bay',
            width: '100',
          },
          {
            text: 'Notes',
            value: 'notes',
            width: '150',
          },
          {
            text: 'Asset Id',
            value: 'assetId',
            width: '150',
          },
          {
            text: 'Project Number',
            value: 'projectNumber',
            width: '200',
          },
          {
            text: 'Project Name',
            value: 'projectName',
            width: '200',
          },
          {
            text: 'Department',
            value: 'departmentNumber',
            width: '150',
          },
          {
            text: 'Acquisition Date',
            value: 'acquisitionDate',
            width: '200',
            formatter: (x) => x ? moment(x).format('YYYY/MM/DD') : '',
          },
          {
            text: 'Decommission Date',
            value: 'decommissionDate',
            width: '220',
            formatter: (x) => x ? moment(x).format('YYYY/MM/DD') : '',
          },
          {
            text: 'Disposal Date',
            value: 'disposalDate',
            width: '200',
            formatter: (x) => x ? moment(x).format('YYYY/MM/DD') : '',
          },
          {
            text: 'Description',
            value: 'description',
            width: '150',
          },
        ]
      },
    },
    created () {
    },
    methods: {
    },
  }
</script>
<style >
.no-hover tbody > tr {
  pointer-events: none;
}
</style>
