import { library } from '../assets/companyLibrary'
import router from '@/router'
import store from '@/store'

export function leadingSlash (str) {
  return str.startsWith('/') ? str : '/' + str
}

export function trailingSlash (str) {
  return str.endsWith('/') ? str : str + '/'
}

export const wait = timeout => {
  return new Promise(resolve => setTimeout(resolve, timeout))
}

export const companyCheck = (company) => {
  return library.has(company)
}

export const companyCheckGuard = (to, from, next) => {
  const pathArray = to.path.split('/')
    if (pathArray.length > 4 || !companyCheck(pathArray[2])) {
      return router.push({ path: 'login-path-error/', query: { loginRoute: pathArray[2] } })
  }
  return to.path.endsWith('/') ? next() : next(trailingSlash(to.path))
}

export const adminGuard = (to, from, next) => {
  // if user is not logged on send to landing, if logged on but not admin send back to dashboard
  if (store.get('user/role') === -1) {
    return next('/')
  } else if (store.get('user/role') === 2) {
    return next('/dashboard')
  }
  return to.path.endsWith('/') ? next() : next(trailingSlash(to.path))
}

export const binarySearchArrayOfObjects = (array, property, left, right, value) => {
  if (right >= left) {
    const mid = left + Math.floor((right - left) / 2)
    if (array[mid][property] === value) return mid
    if (array[mid][property] > value) return binarySearchArrayOfObjects(array, property, left, mid - 1, value)
    return binarySearchArrayOfObjects(array, property, mid + 1, right, value)
  }
  return -1
}
