<template>
  <v-container
    fluid
    style="width: 100%; height: 800px;"
  >
    <v-card
      v-if="loading"
      class="d-flex justify-center align-center flex-wrap"
      style="width: 100%; height: 100%; "
    >
      <v-progress-circular

        indeterminate
        color="grey"
      />
    </v-card>
    <v-card
      v-else
      class="d-flex justify-center flex-wrap"
      style="width: 100%; height: 100%; "
    >
      <v-toolbar
        class="pt-3 mt-1"
        width="100%"
        height="15%"
        flat
      >
        <v-card-title
          class="text-h4 font-weight-bold grey--text"
        >
          Power Information
        </v-card-title>
      </v-toolbar>
      <v-card-text
        v-if="powerEquipmentData"
        style="height: 85%;"
      >
        <v-treeview
          :items="powerEquipmentEwcList"
          :active="activePowerSelection"
          item-children="powerChildren"
          activatable
          @update:active="updateActive"
        />
      </v-card-text>
      <v-card-text v-else>
        <v-icon>
          mdi-image
        </v-icon>
        <span> No power information is available </span>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

  export default {
    name: 'EwcPowerInfoBox',
    props: {
      localBus: {
        type: Object,
      },
      powerEquipmentData: Object,
      loading: Boolean,
    },
    data: () => ({
      selectedItem: {},
      activePowerSelection: [],
    }),
    computed: {
      powerEquipmentEwcList () {
        if (!this.powerEquipmentData) return []
        return this.powerEquipmentData.powerEquipmentEwcList
      },
      powerStripsEwc () {
        if (!this.powerEquipmentData) return []
        return this.powerEquipmentData.powerStripsEwc
      },
    },
    created () {
      this.localBus.$on('clearSelectedPowerItem', () => {
        this.activePowerSelection = []
      })
    },

    methods: {
      updateActive (event) {
        if (event.length === 0) {
          this.localBus.$emit('selectPowerSource', [])
        } else {
          const powerEquipment = this.searchThroughPowerEquipment(this.powerEquipmentEwcList[0], event[0])
          const powerStripArray = this.findAttachedPowerStrips(powerEquipment)
          this.localBus.$emit('selectPowerSource', powerStripArray)
        }
      },

      searchThroughPowerEquipment (powerEquipment, id) {
        if (powerEquipment.id === id) return powerEquipment
        let powerEquipmentMatch = {}
        for (let i = 0; i < powerEquipment.powerChildren.length; i++) {
          if (powerEquipment.powerChildren[i].id === id) {
            powerEquipmentMatch = powerEquipment.powerChildren[i]
            break
          } else {
            powerEquipmentMatch = this.searchThroughPowerEquipment(powerEquipment.powerChildren[i], id)
            if (powerEquipmentMatch.id === id) break
          }
        }

        return powerEquipmentMatch
      },

      findAttachedPowerStrips (powerEquipment) {
        const powerStripArray = []
        const powerStripParents = this.findPowerStripParents(powerEquipment)

        this.powerStripsEwc.forEach(powerStrip => {
          powerStripParents.forEach(parent => {
            if (parent.id === powerStrip.powerEquipmentEwcParentId) powerStripArray.push(powerStrip)
          })
        })

        return powerStripArray
      },

      findPowerStripParents (powerEquipment) {
        const powerStripParents = []
        if (powerEquipment.powerChildren.length > 0) {
          powerEquipment.powerChildren.forEach(child => powerStripParents.push(this.findPowerStripParents(child)))
        } else powerStripParents.push(powerEquipment)
        return powerStripParents.flat()
      },

    },
  }
</script>
