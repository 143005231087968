<template>
  <svg
    ref="ewcMiniRackSvg"
    height="100%"
    width="100%"
  >
    <template
      v-for="(i) in SVGArray"
    >
      <ewc-rack-rectangle
        :key="'rack rectangle' + i"
        :uPosition="i + 1"
        :rectangleWidth="200"
        :rectangleHeight="uHeightPixels"
        :xOffsetPixels="xOffsetPixels"
        :yOffsetPixels="yOffsetPixels"
        :localBus="localBus"
        :selectedItems="selectedItems"
        :selectable="setLocation"
        :total-rectangles="rackData.equipmentModel.capacity - 1"
        mini
      />
      <line
        :key="'tick' + i"
        :x1="xOffsetPixels - 15"
        :y1="(i+1)*uHeightPixels + yOffsetPixels"
        :x2="xOffsetPixels"
        :y2="(i+1)*uHeightPixels + yOffsetPixels"
        stroke="rgb(207, 207, 207)"
        stroke-width="1"
      />
      <text
        v-if="(i+1)%5 === 0 || i === 0"
        :key="'label' + i"
        :x="0"
        :y="(rackData.equipmentModel.capacity*uHeightPixels - i*uHeightPixels) + 5 + yOffsetPixels"
        style="text-align: right;"
      >
        {{ `${i+1 &lt; 10 ? '&nbsp;' : ''} ${i+1} ` }}
      </text>
    </template>

    <template v-for="child in rackDataChildren">
      <ewc-rack-item
        :key="child.equipmentData.id"
        :rack-item="child"
        :uPosition="child.location.gridY"
        :rectangleWidth="200"
        :rectangleHeight="7"
        :xOffsetPixels="xOffsetPixels"
        :yOffsetPixels="yOffsetPixels"
        :localBus="localBus"
        :selected-item-id="selectedItemId"
        :total-rectangles="rackData.equipmentModel.capacity - 1"
      />
    </template>
    <template v-for="child in pendingChildren">
      <ewc-rack-item
        :key="child.equipmentData.id"
        :rack-item="child"
        :uPosition="child.location.gridY"
        :rectangleWidth="200"
        :rectangleHeight="7"
        :xOffsetPixels="xOffsetPixels"
        :yOffsetPixels="yOffsetPixels"
        :localBus="localBus"
        :selected-item-id="selectedItemId"
        :total-rectangles="rackData.equipmentModel.capacity - 1"
      />
    </template>
    <template v-for="child in auditChildren">
      <ewc-rack-item
        :key="child.equipmentData.id"
        :rack-item="child"
        :uPosition="child.location.gridY"
        :rectangleWidth="200"
        :rectangleHeight="7"
        :xOffsetPixels="xOffsetPixels"
        :yOffsetPixels="yOffsetPixels"
        :localBus="localBus"
        :selected-item-id="selectedItemId"
        :total-rectangles="rackData.equipmentModel.capacity - 1"
      />
    </template>
  </svg>
</template>

<script>

  export default {
    name: 'EwcRackMini',
    props: {
      localBus: {
        type: Object,
      },
      rackData: {
        type: Object,
        default: () => ({ equipmentModel: { capacity: 42 } }),
      },
      setLocation: Boolean,
      pendingEquipmentEwc: Array,
      auditEquipmentEwc: Array,
      initialStateObject: Object,
    },
    data: () => ({
      SVGArray: [],
      uHeightPixels: 7,
      xOffsetPixels: 35,
      yOffsetPixels: 0,

      selectedItems: [],
      selectedItemId: -1,
    }),
    computed: {
      pendingChildren () {
        if (!this.pendingEquipmentEwc || !this.rackData.equipmentData?.id) return

        return this.pendingEquipmentEwc.filter(
          pendingEquipmentItem => pendingEquipmentItem.location.parentEquipmentEwcId === this.rackData.equipmentData.id &&
          this.initialStateObject?.equipmentData.id !== pendingEquipmentItem.equipmentData.id,
        )
      },
      auditChildren () {
        if (!this.auditEquipmentEwc || !this.rackData.equipmentData?.id) return

        if (this.rackData.equipmentData.id < 0) {
          return this.auditEquipmentEwc.filter(
            auditEquipmentItem => auditEquipmentItem.location.parentAuditEquipmentId === -this.rackData.equipmentData.id &&
          this.initialStateObject?.equipmentData.id !== auditEquipmentItem.equipmentData.id)
        } else {
          return this.auditEquipmentEwc.filter(
            auditEquipmentItem => auditEquipmentItem.location.parentEquipmentEwcId === this.rackData.equipmentData.id &&
          this.initialStateObject?.equipmentData.id !== auditEquipmentItem.equipmentData.id)
        }
      },
      rackDataChildren () {
        if (!this.rackData.children) return []
        return this.rackData.children.filter(child => child.equipmentData.id !== this.initialStateObject?.equipmentData.id && child.equipmentData.id !== this.initialStateObject?.equipmentToEditId)
      },
    },

    created () {
      for (let i = 0; i < this.rackData.equipmentModel.capacity; i++) {
        this.SVGArray.push(i)
      }
      this.localBus.$on('setMinimapRackLocation', selectedLocation => {
        this.selectedItems = selectedLocation.selectedRackRectangles
        this.$nextTick(() => this.setupRack())
      })
      this.localBus.$on('setMinimapEnclosureLocation', selectedLocation => {
        this.selectedItemId = selectedLocation.selectedEnclosure.equipmentData.id
        this.$nextTick(() => this.setupRack())
      })
      this.localBus.$on('clearSelectedItems', () => {
        this.selectedItems = []
        this.selectedItemId = -1
      })
    },
    methods: {
      setupRack () {
        if (!this.$refs.ewcMiniRackSvg) return
        const viewBoxH = this.rackData.equipmentModel.capacity * 7 + 30
        const viewBoxW = this.$refs.ewcMiniRackSvg.clientWidth
        const viewBoxX = -(viewBoxW / 2 - 117.5)
        const viewBoxY = -(viewBoxH / 2 - this.rackData.equipmentModel.capacity * 7 / 2 + 3.5)

        this.$refs.ewcMiniRackSvg.setAttribute('viewBox', `${viewBoxX} ${viewBoxY} ${viewBoxW} ${viewBoxH}`)
      },
    },
  }
</script>
