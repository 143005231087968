import store from '@/store'

let currentFloorplan = null
let latestSiteAndFloorSelection = null
let dataCenters = null
let latestMainEwcPageSelection = null

store.set('app/pendingRequests', 0)
export default {
    getFloorplan: function () {
        return currentFloorplan
    },
    setFloorplan: function (newFloorplan) {
        currentFloorplan = newFloorplan
        if (newFloorplan) store.set('app/pendingRequests', newFloorplan.pendingEquipmentEwc.length)
        return true
    },

    getDataCenters: function () {
        return dataCenters
    },
    setDataCenters: function (sites) {
        dataCenters = sites
        return true
    },

    getLatestSiteAndFloorSelection: function () {
        return latestSiteAndFloorSelection
    },
    setLatestSiteAndFloorSelection: function (newSelection) {
        latestSiteAndFloorSelection = newSelection
        return true
    },

    getLatestMainEwcPageSelection: function () {
        return latestMainEwcPageSelection
    },
    setLatestMainEwcPageSelection: function (newSelection) {
        latestMainEwcPageSelection = newSelection
    },
}
