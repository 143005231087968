<template>
  <g>
    <image
      v-if="rackItem.equipmentModel.imagePath"
      :href="rackItem.equipmentModel.imagePath"
      :width="rectangleWidth"
      :height="rectangleHeight * rackItem.location.uheight"
      :x="xOffsetPixels"
      :y="(totalRectangles - (rackItem.location.gridY - 1 + rackItem.location.uheight -1)) * rectangleHeight + yOffsetPixels"
      preserveAspectRatio="none"
    />
    <rect
      :x="xOffsetPixels"
      :y="(totalRectangles - (rackItem.location.gridY - 1 + rackItem.location.uheight -1)) * rectangleHeight + yOffsetPixels"
      :width="rectangleWidth"
      :height="rectangleHeight * rackItem.location.uheight"
      :style="rackItem.equipmentModel.imagePath ?
        selectedItemId === rackItem.equipmentData.id ? 'fill: rgba(180, 237, 224, .2); stroke-width:3; stroke: rgb(44, 158, 115); cursor: pointer;' : 'opacity: 0; cursor: pointer;' :
        fillStyle"
      @click="selectable ? onSelect() : ''"
      @mousemove="onMouseMove"
      @mouseleave="localBus.$emit('hide-tooltip', rackItem.equipmentData.label ? rackItem.equipmentData.label : rackItem.equipmentModel.name)"
    />
  </g>
</template>

<script>
  import fillStyles from './fillStyles'

  export default {
    name: 'EwcRackItem',
    props: {
      rackItem: Object,
      uPosition: Number,
      rectangleWidth: Number,
      rectangleHeight: Number,
      xOffsetPixels: Number,
      yOffsetPixels: Number,
      localBus: Object,
      selectable: Boolean,
      totalRectangles: Number,
      selectedItemId: Number,
      selectedPowerEquipment: Array,
    },
    data: (instance) => ({
      selectedFillStyle: fillStyles.selectedFillStyle,
      standardFillStyle: fillStyles.standardFillStyle,
      unselectableFillStyle: fillStyles.unselectableFillStyle,

      lastMove: 0,
      eventThrottle: 25,
    }),
    computed: {
      fillStyle () {
        if (this.selectedPowerEquipment?.includes(this.rackItem.equipmentData.id)) {
          return this.selectedFillStyle
        }
        if (this.selectedItemId === this.rackItem.equipmentData.id) {
          return this.selectedFillStyle
        } else {
          if (this.selectable) {
            return this.standardFillStyle
          } else {
            return this.unselectableFillStyle
          }
        }
      },
    },
    methods: {
      onSelect: function () {
        if (this.selectedItemId !== this.rackItem.equipmentData.id && this.selectable) {
          if (this.rackItem.equipmentModel.capacity > 0) {
            this.localBus.$emit('onSelectEnclosure', this.rackItem)
          } else {
            this.localBus.$emit('onSelectRackItem', this.rackItem)
          }
        } else if (this.selectedItemId === this.rackItem.equipmentData.id) {
          this.localBus.$emit('clearSelectedRackItem')
        }
      },
      onMouseMove (e) {
        e.preventDefault()
        // use eventThrottle to limit frequency of events emitted because of mouse move
        const now = Date.now()
        if (now > this.lastMove + this.eventThrottle) {
          this.lastMove = now
          this.localBus.$emit('display-tooltip', ({ e: e, label: this.rackItem.equipmentData.label ? this.rackItem.equipmentData.label : this.rackItem.equipmentModel.name }))
        }
      },
    },
  }
</script>
