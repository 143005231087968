import axios from 'axios'
import common from '@/api/common.js'

const api = axios.create()

export default {
  getEquipmentDataHumanNames: function (equipmentType) {
    console.log('getEquipmentDatHumanNames')

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/equipment/dataPointsDictionary/${equipmentType}`,
    })
  },

  getInitialGraphData: function (equipmentName, dataPath) {
    console.log('getInitialGraphData() ' + equipmentName)

    api.interceptors.request.use(request => {
      console.log('Starting Request', JSON.stringify(request, null, 2))
      return request
    })

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      data: {
        equipmentName: equipmentName,
        dataPath: dataPath,
      },
      url: `${common.getUrlPrefix()}/dashboard/chartData/getLatestDataPoint`,
    })
  },

  getInitialChartData: function (equipmentData) {
    console.log('getInitialChartData() ')
    console.log(equipmentData)
    /*
    api.interceptors.request.use(request => {
      console.log('Starting Request', JSON.stringify(request, null, 2))
      return request
    })
    */

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      data: equipmentData,
      url: `${common.getUrlPrefix()}/dashboard/chartData/getInitial`,
    })
  },

  getLastMinuteOfChartData: function (equipmentData) {
    console.log('getLastMinuteOfChartData() ' + equipmentData)
    /*
    api.interceptors.request.use(request => {
      console.log('Starting Request', JSON.stringify(request, null, 2))
      return request
    })
    */

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/dashboard/chartData/getMinute`,
      data: equipmentData,
    })
  },

  // equipmentData should match the structure built in equipment-data-point-holder.js
  pushEquipmentData: function (equipmentData) {
    console.log('pushEquipmentData() ' + equipmentData)
    /*
    api.interceptors.request.use(request => {
      console.log('Starting Request', JSON.stringify(request, null, 2))
      return request
    })
    */

    const headers = common.createHeaders()

    // TODO: Needs to be like the other API calls and not handle responses here
    api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/dashboard/add`,
      data: {
        dashboard: {
          userId: 1,
          title: 'test',
        },
        dashboardData: equipmentData,
      },
    })
    .then(response => {
      console.log(response)
    })
    .catch(error => {
      console.log(error)
    })
  },

  getEquipmentImage: function (equipmentTypeEnum) {
    console.log('getEquipmentImage() ' + equipmentTypeEnum)
    /*
    api.interceptors.request.use(request => {
      console.log('Starting Request', JSON.stringify(request, null, 2))
      return request
    })
    */

    const headers = common.createHeaders()

    return api({
      method: 'get',
      headers: headers,
      url: `${common.getUrlPrefix()}/equipment/getEquipmentImage/${equipmentTypeEnum}`,
    })
  },

  getByDateRange: function (data) {
    console.log('getByDateRange', data)

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/dashboard/chartData/getByDateRange`,
      data,
    })
  },

  getSetpointsForEquipment: function (equipmentId) {
    console.log('getSetpointsForEquipment()', equipmentId)

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/equipment/setpoint/get/${equipmentId}`,
    })
  },
  setSetpointsForEquipment: function (data) {
    console.log('setSetpointsForEquipment()', data)

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/equipment/setpoint/set`,
      data,
    })
  },
  getEquipmentInformation: function (id) {
    console.log('getEquipmentInformation', id)

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/equipment/get/${id}`,
    })
  },

  addEquipmentModel: function (model) {
    console.log('addEquipmentModel()', model)

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/equipment/ewc/add/model`,
      data: { ...model },
    })
  },

  getEquipmentModels: function () {
    console.log('getEquipmentModels()')

    const headers = common.createHeaders()

    return api({
      method: 'get',
      headers: headers,
      url: `${common.getUrlPrefix()}/equipment/ewc/get/models`,
    })
  },

  addEquipmentEwc: function (equipment) {
    console.log('addEquipmentEwc()', equipment)

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/equipment/ewc/add`,
      data: { ...equipment },
    })
  },

  getEquipmentEwcForSite: function (gridId) {
    console.log('getEquipmentEwcForSite()', gridId)

    const headers = common.createHeaders()

    return api({
      method: 'get',
      headers: headers,
      url: `${common.getUrlPrefix()}/equipment/ewc/get/${gridId}`,
    })
  },
}
