export const library = new Map([['afs', { id: 1 }], ['fiserv', { id: 2 }], ['union_bank', { id: 3 }], ['us_foods', { id: 4 }]])
export const libraryGetByValue = (searchValue) => {
  for (const [key, value] of library.entries()) {
    if (value.id === parseInt(searchValue)) {
      return key
    }
  }
  return false
}
export const libraryGetByCompany = (searchValue) => {
  for (const [key, value] of library.entries()) {
    if (key === searchValue) {
      return value.id
    }
  }
  return false
}
