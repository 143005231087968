<template>
  <rect
    :id="uPosition"
    :x="xOffsetPixels"
    :y="totalRectangles ? (totalRectangles - (uPosition - 1)) * rectangleHeight + yOffsetPixels : 0"
    :width="rectangleWidth"
    :height="rectangleHeight"
    :style="fillStyle"
    @click="selectable ? onSelect() : ''"
    @mousemove="e => setLocationDialog ? onMouseMove(e) : ''"
    @mouseleave="setLocationDialog ? localBus.$emit('hide-tooltip') : ''"
  />
</template>

<script>
  import fillStyles from './fillStyles'

  export default {
    name: 'EwcRackRectangle',
    props: {
      uPosition: Number,
      rectangleWidth: Number,
      rectangleHeight: Number,
      xOffsetPixels: Number,
      yOffsetPixels: Number,
      localBus: {
        type: Object,
      },
      selectedItems: Array,
      selectable: Boolean,
      mini: Boolean,
      totalRectangles: Number,
      setLocationDialog: Boolean,
    },
    data: (instance) => ({
      standardFillStyle: `fill: rgba(255, 255, 255); stroke-width:${instance.mini ? '1' : '3'}; stroke: rgb(207, 207, 207); ${instance.selectable ? 'cursor: pointer' : ''}`,
      selectedFillStyle: `${fillStyles.selectedFillStyle} ${instance.selectable ? 'cursor: pointer' : ''}`,

      lastMove: 0,
      eventThrottle: 25,
    }),
    computed: {
      fillStyle () {
        let selected = false
        this.selectedItems.forEach((uPosition) => {
          if (uPosition === this.uPosition) {
            selected = true
          }
        })
        if (selected) {
          return this.selectedFillStyle
        } else {
          return this.standardFillStyle
        }
      },
    },
    methods: {
      onSelect: function () {
        this.localBus.$emit('onSelectRackRectangle', this.uPosition)
      },
      onMouseMove (e) {
        e.preventDefault()
        // use eventThrottle to limit frequency of events emitted because of mouse move
        const now = Date.now()
        if (now > this.lastMove + this.eventThrottle) {
          this.lastMove = now
          this.localBus.$emit('display-tooltip', ({ e: e, label: `U${this.uPosition}` }))
        }
      },
    },
  }
</script>
