<template>
  <v-container class="d-flex justify-center">
    <v-card style="width: 500px;">
      <v-form
        v-model="isFormValid"
        onsubmit="return false;"
        @submit="submit()"
      >
        <v-card-text class="pb-0">
          <v-container class="d-flex justify-center flex-wrap pb-0">
            <img
              :src="require(`../assets/${this.$route.params.company && this.$route.params.company != 'afs' ? this.$route.params.company : 'logo'}.${!this.$route.params.company || this.$route.params.company === 'afs' ? 'png': 'svg'}`)"
            >
            <v-card-title
              class="text-h2 font-weight-bold grey--text justify-center flex-wrap pa-0"
            >
              Reset Password
            </v-card-title>
          </v-container>
          <v-sheet
            width="80%"
            style="margin-right: 10%; margin-left: 10%"
          >
            <v-text-field
              id="forgotPasswordFieldEmail"
              v-model="formEmail"
              label="Email"
              :rules="[rules.required, rules.email]"
            />
          </v-sheet>
        </v-card-text>
        <v-card-actions class="justify-center pb-0 pt-0">
          <v-btn
            v-if="!loading"
            id="forgotPasswordButtonSendEmail"
            :disabled="!isFormValid"
            color="green darken-1"
            text
            width="80%"
            height="56px"
            style="font-size: 14px"
            type="submit"
          >
            Send Email
          </v-btn>
          <v-container
            v-else
            style="width: 80%"
            class="d-flex justify-center align-center green lighten-3 text-center"
          >
            <v-progress-circular
              indeterminate
              color="green"
            />
            <span class="ml-2 green--text text--darken-2">Sending email...</span>
          </v-container>
        </v-card-actions>
        <v-container class="d-flex justify-center pb-6 pt-0">
          <div
            tabindex="0"
            class="reset"
            @keyup.enter="reset"
            @click="reset"
          >
            back
          </div>
        </v-container>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
  import registerApi from '@/api/register.js'
  import { Mode } from '@/util/enums'
  import { library } from '@/assets/companyLibrary'

  export default {
    name: 'ForgotPasswordCard',
    props: {
      email: String,
    },
    data: () => ({
      title: 'Password Reset',
      loading: false,
      isFormValid: false,
      formEmail: '',
      rules: {
        required: (value) => !!value || 'Required.',
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
    }),

    created () {
      this.formEmail = this.email
    },

    methods: {
      submit () {
        if (this.formEmail) {
          this.loading = true
          const companyId = library.get(this.$router.history.current.path.split('/')[2]).id
          registerApi
            .startResetRequest(companyId, this.formEmail)
            .then((response) => {
              console.log(response)
              this.$emit('reset')
              this.$emit('alert', Mode.SUCCESS, 'An email will be sent to the account provided with instructions on how to change your password.')
            })
            .catch((error) => {
              console.log(error)
              if (error.response.status === 500) {
                this.$emit('reset')
                this.$emit('alert', Mode.FAILURE, 'We are unable to complete your request at this time. Please try again later.')
              } else {
                this.$emit('alert', Mode.SUCCESS, 'An email will be sent to the account provided with instructions on how to change your password.')
              }
            })
            .finally(() => {
              this.loading = false
            })
        }
      },
      reset () {
        this.$emit('reset')
      },
    },
  }
</script>
<style scoped>

.reset {
  font-size: 12px;
  color: gray;
}
.reset:hover {
  cursor: pointer;
  color: #701111;
}
</style>
