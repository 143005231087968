import axios from 'axios'
import common from '@/api/common.js'

const api = axios.create()

export default {

  /**
   * /dashboard/add
   *
   * dashboard
   * val user_id = reference("user_id", UserTable.id)
   * val title = varchar("title", 100)
   *
   * dashboardData - List of points
   * url: dataUrl,
   * data: [
   *  {
   *     equipmentName: equipmentName,
   *     equipmentDataPoints: [<list of data points>],
   *  }
   *
   * @returns function for API call to add a chart to the dashboard
   */
  saveChartForDashboard: function (dashboardTitle, dataPoints, colorList, rootSiteId) {
    console.log('saveChartForDashboard() ' + dashboardTitle + ' ' + dataPoints + colorList + rootSiteId)
    /*
    api.interceptors.request.use(request => {
      console.log('Starting Request', JSON.stringify(request, null, 2))
      return request
    })
    */

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/dashboard/add`,
      data: {
        dashboard: {
          title: dashboardTitle,
          colorList: colorList,
          rootSiteId: rootSiteId,
        },
        dashboardData: dataPoints,
      },
    })
  },

  getUserCharts: function () {
    console.log('getUserCharts()')
    /*
    api.interceptors.request.use(request => {
      console.log('Starting Request', JSON.stringify(request, null, 2))
      return request
    })
    */

    const headers = common.createHeaders()

    return api({
      method: 'get',
      headers: headers,
      url: `${common.getUrlPrefix()}/dashboard/get`,
    })
  },

  removeChart: function (chartId) {
    console.log('removeChart()')

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/dashboard/remove/${chartId}`,
    })
  },
}
