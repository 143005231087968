import cookie from '@/api/cookie.js'
import loginApi from '@/api/login.js'
import store from '@/store'
import { libraryGetByValue } from '@/assets/companyLibrary'

export default {

  createHeaders: function (extraHeaders) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cookie.getCookie('jwt'),
      'Access-Control-Allow-Origin': '*',
    }

    return Object.assign(headers, extraHeaders)
  },

  getUrlPrefix: function () {
     return process.env.VUE_APP_URL_PREFIX
  },

  handleBadCall: async function (error, router) {
    console.log('handling call')
    let expiredJwtRefreshed = false
    if (error.response) {
      switch (error.response.status) {
        case 401:
          await loginApi.refreshToken()
              .then(response => {
                console.log(response)

                cookie.createCookie('jwt', response.data.token, 1)
                expiredJwtRefreshed = true
              })
              .catch(error => {
                console.log(error)

                this.logoutUser(router)
              })
    }
    return new Promise((resolve, reject) => resolve(expiredJwtRefreshed))
  }
  },

  logoutUser: function (router) {
    loginApi.tenantId()
      .then((response) => {
        console.log(response)

        this.clearUserData()
        router.push({ path: `/login/${libraryGetByValue(response.data.tenantId)}/` })
      })
      .catch((error) => {
        /* This is very unlikely to happen given that it means:
            The user cleared cookies where we store the JWT
            The user somehow did that when we were triggering a 401 from another call
        */
        console.log(error)

        this.clearUserData()
        router.push({
          name: '404Login',
        })
      })
  },

  clearUserData: function () {
    cookie.deleteCookie('jwt')
    cookie.deleteCookie('refreshToken')
    store.set('user/role', -1)
    store.set('app/clearItems')
  },
}
