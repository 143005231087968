<template>
  <v-container
    fluid
    style="width: 100%; height: 100%;"
  >
    <v-card
      v-if="selectedItem"
      class="d-flex justify-center flex-wrap"
      style="width: 100%; height: 100%; "
    >
      <v-toolbar
        class="pt-3"
        width="100%"
        height="20%"
        flat
      >
        <v-card-title
          class="text-h4 font-weight-bold grey--text"
        >
          {{ selectedItem.equipmentModel.name }}
        </v-card-title>
      </v-toolbar>
      <div
        style="width: 100%; height: 80%;"
      >
        <ewc-enclosure
          :key="selectedItem.equipmentData.id"
          :update-svg="updateSvg"
          :enclosure-data="selectedItem"
          :set-location-dialog="setLocationDialog"
          :local-bus="localBus"
          :initial-state-object="initialStateObject"
          :pending-equipment-ewc="pendingEquipmentEwc"
          :audit-equipment-ewc="auditEquipmentEwc"
        />
      </div>
    </v-card>
  </v-container>
</template>

<script>

  export default {
    name: 'EwcEnclosureCard',
    props: {
      localBus: {
        type: Object,
      },
      setLocationDialog: Boolean,
      updateSvg: Number,
      initialStateObject: Object,
      pendingEquipmentEwc: Array,
      auditEquipmentEwc: Array,
    },
    data: () => ({
      selectedItem: null,
      showEnclosureItem: false,
    }),
    created () {
      this.localBus.$on('onSelectEnclosure', (enclosure) => {
        this.selectedItem = enclosure
      })

      this.localBus.$on('openEnclosureItemSetLocationDialog', (selectedEnclosureItems, selectedEnclosure) => {
        if (Object.keys(selectedEnclosure).length > 0) this.selectedItem = selectedEnclosure
        this.$nextTick(() => this.localBus.$emit('openEnclosureSetLocationDialogInitialRectangles', selectedEnclosureItems))
      })

      this.localBus.$on('clearSelectedItems', () => {
        this.selectedItem = null
      })
    },
    methods: {
    },
  }
</script>
