<template>
  <v-container
    fluid
    style="width: 100%; height: 800px;"
  >
    <v-card
      v-if="networkEquipmentData"
      class="d-flex justify-center flex-wrap"
      style="width: 100%; height: 100%; "
    >
      <div
        v-if="loadingNetworkEquipment || noGridHasBeenSetup"
        class="d-flex justify-center align-center"
      >
        <v-progress-circular
          v-if="loadingNetworkEquipment === true"
          indeterminate
        />
        <div
          v-else
          class="d-flex justify-center flex-wrap"
        >
          <v-card-title
            class="text-h3 font-weight-bold grey--text"
          >
            No network architecture has been assigned to this floorplan
          </v-card-title>
          <v-btn
            color="green darken-2"
            class="font-weight-bold white--text mt-2"
          >
            Add Network Architecture
          </v-btn>
        </div>
      </div>
      <template v-else>
        <v-toolbar
          class="pt-3 mt-5"
          width="100%"
          height="15%"
          flat
        >
          <v-card-title
            class="text-h3 font-weight-bold grey--text"
          >
            Network Architecture
          </v-card-title>
        </v-toolbar>
        <v-row
          class="ml-7 mt-3 align-center"
          style="width: 100%;"
        >
          <v-col
            cols="2"
            class="d-flex justify-center flex-wrap"
          >
            <v-card
              class="pa-6 d-flex justify-center flex-wrap"
              color="rgb(219, 195, 171)"
              style="width:150px; height: 150px;"
              raised
            >
              <div class="font-weight-bold mr-2 d-flex flex-wrap justify-center">
                <div
                  style="width: 100%;"
                  class="d-flex justify-center"
                >
                  Core
                </div>
                <div>Layer</div>
              </div>
              <v-tooltip
                bottom
                open-delay="1000"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    color="green darken-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="localBus.$emit('open-add-network-equipment', 'Core Layer', networkEquipmentData)"
                  >
                    <v-icon large>
                      {{ mdiPlus }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Add Network Equipment To Core Layer</span>
              </v-tooltip>
            </v-card>
          </v-col>
          <v-col
            cols="10"
            class="d-flex justify-center"
          >
            <div
              v-for="coreEquipment in networkEquipmentData.coreLayer"
              :key="coreEquipment.id"
              class="ma-2"
            >
              <div
                :style="`height: 150px; width: 200px; ${coreEquipment === selectedEquipment ? selectedStyle : 'background-color: rgba(119, 103, 224, .6); border: solid rgba(119, 103, 224);'} cursor: pointer;`"
                @mousemove="e => localBus.$emit('display-tooltip', ({e, label: coreEquipment.equipmentModelNetworkInformation.model}))"
                @mouseleave="localBus.$emit('hide-tooltip')"
                @click="onSelectEquipment(coreEquipment)"
              />
            </div>
          </v-col>
          <v-col
            cols="2"
            class="pa-0"
          />
          <v-col
            cols="9"
            style="border-bottom: dashed rgb(73, 101, 227)"
            class="pa-0 ml-9"
          />
        </v-row>
        <v-row
          class="ml-7 align-center"
          style="width: 100%;"
        >
          <v-col
            cols="2"
            class="d-flex justify-center flex-wrap"
          >
            <v-card
              class="pa-6 d-flex justify-center flex-wrap"
              color="rgb(219, 195, 171)"
              style="width:150px; height: 150px;"
              raised
            >
              <div class="font-weight-bold mr-2 d-flex flex-wrap justify-center">
                Aggregate <span>Layer</span>
              </div>
              <v-tooltip
                bottom
                open-delay="1000"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    color="green darken-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="localBus.$emit('open-add-network-equipment', 'Aggregate Layer', networkEquipmentData)"
                  >
                    <v-icon large>
                      {{ mdiPlus }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Add Network Equipment To Aggregate Layer</span>
              </v-tooltip>
            </v-card>
          </v-col>
          <v-col
            cols="10"
            class="d-flex justify-center"
          >
            <div
              v-for="aggregateEquipment in networkEquipmentData.aggregateLayer"
              :key="aggregateEquipment.id"
              class="ma-2"
            >
              <div
                :style="`height: 300px; width: 200px; ${aggregateEquipment === selectedEquipment ? selectedStyle : 'background-color: rgba(103, 200, 224, .6); border: solid rgb(103, 200, 224);'} cursor: pointer;`"
                @mousemove="e => localBus.$emit('display-tooltip', ({e, label: aggregateEquipment.equipmentModelNetworkInformation.model}))"
                @mouseleave="localBus.$emit('hide-tooltip')"
                @click="onSelectEquipment(aggregateEquipment)"
              />
            </div>
          </v-col>
          <v-col
            cols="2"
            class="pa-0"
          />
          <v-col
            cols="9"
            style="border-bottom: dashed rgb(73, 101, 227)"
            class="pa-0 ml-9"
          />
        </v-row>
        <v-row
          class="ml-7 mb-2  align-center"
          style="width: 100%;"
        >
          <v-col
            cols="2"
            class="d-flex justify-center align-center flex-wrap"
          >
            <v-card
              class="pa-6 d-flex justify-center align-center flex-wrap"
              color="rgb(219, 195, 171)"
              raised
              style="width:150px; height: 150px;"
            >
              <div class="font-weight-bold mr-2 d-flex flex-wrap justify-center">
                Access <span>Layer</span>
              </div>
              <v-tooltip
                bottom
                open-delay="1000"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    color="green darken-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="localBus.$emit('open-add-network-equipment', 'Access Layer', networkEquipmentData)"
                  >
                    <v-icon large>
                      {{ mdiPlus }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Add Network Equipment To Access Layer</span>
              </v-tooltip>
            </v-card>
          </v-col>
          <v-col
            cols="10"
            class="d-flex justify-center flex-wrap pt-0"
          >
            <div
              v-for="accessEquipment in networkEquipmentData.accessLayer"
              :key="accessEquipment.id"
              class="ma-2"
            >
              <div
                :style="`height: 50px; width: 100px; ${accessEquipment === selectedEquipment ? selectedStyle : 'background-color: rgba(52, 111, 194, .6); border: solid rgb(52, 111, 194);'} cursor: pointer;`"
                @mousemove="e => localBus.$emit('display-tooltip', ({e, label: accessEquipment.equipmentModelNetworkInformation.model}))"
                @mouseleave="localBus.$emit('hide-tooltip')"
                @click="onSelectEquipment(accessEquipment)"
              />
            </div>
          </v-col>
        </v-row>
      </template>
    </v-card>
  </v-container>
</template>

<script>
  import { mdiPlus } from '@mdi/js'
  import networkApi from '@/api/network_ewc.js'
  import common from '@/api/common.js'

  export default {
    name: 'EwcTraditionalNetworkMainCard',
    props: {
      localBus: {
        type: Object,
      },
      gridId: Number,
    },
    data: () => ({
      mdiPlus: mdiPlus,
      selectedEquipment: null,
      selectedStyle: 'background-color: rgba(180, 237, 224, 0.95); border: solid rgb(44, 158, 115);',
      networkEquipmentData: null,
      noGridHasBeenSetup: false,
      loadingNetworkEquipment: true,
      mockTradNetworkResponse: {
        architectureType: 'TRADITIONAL_THREE_TIER',
        coreLayer: [
          {
            equipmentId: '1',
            location: 'x110-y55',
            layerType: 'CORE',
            connectionProtocol: 'FTP',
            equipmentModelNetworkInformation: {
              name: 'Core Switch',
              imagePath: null,
              ports: '128',
              speed: '10',
            },
            connections: [],
          },
          {
            equipmentId: '2',
            location: 'x110-y55',
            layerType: 'CORE',
            connectionProtocol: 'FTP',
            equipmentModelNetworkInformation: {
              name: 'Core Switch',
              imagePath: null,
              ports: '128',
              speed: '10',
            },
            connections: [],
          },
        ],
        aggregateLayer: [
          {
            equipmentId: '3',
            location: 'x110-y55',
            layerType: 'AGGREGATE',
            connectionProtocol: 'VLAN',
            equipmentModelNetworkInformation: {
              name: 'Aggregate Switch',
              imagePath: null,
              ports: '64',
              speed: '10',
            },
            connections: [],
          },
          {
            equipmentId: '4',
            layerType: 'AGGREGATE',
            connectionProtocol: 'VLAN',
            equipmentModelNetworkInformation: {
              name: 'Aggregate Switch',
              imagePath: null,
            },
            connections: [],
          },
        ],
        accessLayer: [
          {
            equipmentId: '5',
            location: 'x110-y60',
            layerType: 'ACCESS',
            connectionProtocol: 'LAN',
            equipmentModelNetworkInformation: {
              name: 'Access Switch',
              imagePath: null,
              ports: '12',
              speed: '10',
            },
            connections: [],
          },
          {
            equipmentId: '6',
            layerType: 'ACCESS',
            connectionProtocol: 'LAN',
            equipmentModelNetworkInformation: {
              name: 'Access Switch',
              imagePath: null,
              ports: '12',
              speed: '10',
            },
            connections: [{ label: 'test label', equipmentModel: 'test model 1', equipmentId: 236, location: 'x110-y55', portsUsed: '2' },
                          { label: '', equipmentModel: 'test model 2', equipmentId: 543, location: 'x110-y55', portsUsed: '4' },
                          { label: '', equipmentModel: 'test model 3', equipmentId: 4368, location: 'x110-y55', portsUsed: '2' }],
          },
          {
            equipmentId: '6',
            location: 'x110-y65',
            layerType: 'ACCESS',
            connectionProtocol: 'LAN',
            equipmentModelNetworkInformation: {
              name: 'Access Switch',
              imagePath: null,
              ports: '12',
              speed: '10',
            },
            connections: [{ label: 'test label', equipmentModel: 'test model 1', equipmentId: 236, location: 'x110-y55', portsUsed: '2' },
                          { label: '', equipmentModel: 'test model 2', equipmentId: 543, location: 'x110-y55', portsUsed: '4' },
                          { label: '', equipmentModel: 'test model 3', equipmentId: 4368, location: 'x110-y55', portsUsed: '2' }],
          },
          {
            equipmentId: '7',
            location: 'x110-y65',
            layerType: 'ACCESS',
            connectionProtocol: 'LAN',
            equipmentModelNetworkInformation: {
              name: 'Access Switch',
              imagePath: null,
              ports: '12',
              speed: '10',
            },
            connections: [{ label: 'test label', equipmentModel: 'test model 1', equipmentId: 236, location: 'x110-y55', portsUsed: '2' },
                          { label: '', equipmentModel: 'test model 2', equipmentId: 543, location: 'x110-y55', portsUsed: '4' },
                          { label: '', equipmentModel: 'test model 3', equipmentId: 4368, location: 'x110-y55', portsUsed: '2' }],
          },
          {
            equipmentId: '8',
            location: 'x108-y55',
            layerType: 'ACCESS',
            connectionProtocol: 'LAN',
            equipmentModelNetworkInformation: {
              name: 'Access Switch',
              imagePath: null,
              ports: '12',
              speed: '10',
            },
            connections: [{ label: 'test label', equipmentModel: 'test model 1', equipmentId: 236, location: 'x110-y55', portsUsed: '2' },
                          { label: '', equipmentModel: 'test model 2', equipmentId: 543, location: 'x110-y55', portsUsed: '4' },
                          { label: '', equipmentModel: 'test model 3', equipmentId: 4368, location: 'x110-y55', portsUsed: '2' }],
          },
          {
            equipmentId: '9',
            location: 'x108-y55',
            layerType: 'ACCESS',
            connectionProtocol: 'LAN',
            equipmentModelNetworkInformation: {
              name: 'Access Switch',
              imagePath: null,
              ports: '12',
              speed: '10',
            },
            connections: [{ label: 'test label', equipmentModel: 'test model 1', equipmentId: 236, location: 'x110-y55', portsUsed: '2' },
                          { label: '', equipmentModel: 'test model 2', equipmentId: 543, location: 'x110-y55', portsUsed: '4' },
                          { label: '', equipmentModel: 'test model 3', equipmentId: 4368, location: 'x110-y55', portsUsed: '2' }],
          },
          {
            equipmentId: '10',
            location: 'x110-y55',
            layerType: 'ACCESS',
            connectionProtocol: 'LAN',
            equipmentModelNetworkInformation: {
              name: 'Access Switch',
              imagePath: null,
              ports: '12',
              speed: '10',
            },
            connections: [{ label: 'test label', equipmentModel: 'test model 1', equipmentId: 236, location: 'x110-y55', portsUsed: '2' },
                          { label: '', equipmentModel: 'test model 2', equipmentId: 543, location: 'x110-y55', portsUsed: '4' },
                          { label: '', equipmentModel: 'test model 3', equipmentId: 4368, location: 'x110-y55', portsUsed: '2' }],
          },
          {
            equipmentId: '11',
            location: 'x110-y55',
            layerType: 'ACCESS',
            connectionProtocol: 'LAN',
            equipmentModelNetworkInformation: {
              name: 'Access Switch',
              imagePath: null,
              ports: '12',
              speed: '10',
            },
            connections: [{ label: 'test label', equipmentModel: 'test model 1', equipmentId: 236, location: 'x110-y55', portsUsed: '2' },
                          { label: '', equipmentModel: 'test model 2', equipmentId: 543, location: 'x110-y55', portsUsed: '4' },
                          { label: '', equipmentModel: 'test model 3', equipmentId: 4368, location: 'x110-y55', portsUsed: '2' }],
          },
        ],
      },
    }),
    computed: {
    },
    created () {
      this.getNetworkEquipment(this.gridId)

      this.localBus.$on('add-network-equipment-success', () => {
        this.getNetworkEquipment(this.gridId)
      })

      this.localBus.$on('update-network-model-success', () => {
        this.getNetworkEquipment(this.gridId)
      })

      this.localBus.$on('remove-network-equipment-success', () => {
        this.getNetworkEquipment(this.gridId)
      })
    },

    methods: {
      onSelectEquipment (equipment) {
        if (this.selectedEquipment === equipment) {
          this.selectedEquipment = null
          this.localBus.$emit('clearSelectedNetworkEquipment')
        } else {
          this.selectedEquipment = equipment
          const activeConnections = this.calculateActiveNetworkConnections(equipment, this.networkEquipmentData)
          this.localBus.$emit('selectNetworkEquipment', { ...equipment, activeConnections })
        }
      },
      calculateActiveNetworkConnections (equipment, networkDataMap) {
        if (equipment.layerType === 'CORE') {
          return networkDataMap.coreLayer.length - 1 + networkDataMap.aggregateLayer.length
        } else if (equipment.layerType === 'AGGREGATE') {
          return networkDataMap.coreLayer.length + networkDataMap.aggregateLayer.length - 1 + networkDataMap.accessLayer.length
        } else if (equipment.layerType === 'ACCESS') {
          return networkDataMap.aggregateLayer.length
        }
      },

      getNetworkEquipment (gridId) {
        this.loadingNetworkEquipment = true
        networkApi.getNetworkEquipment(gridId)
          .then((response) => {
            console.log(response)
            this.networkEquipmentData = response.data
          })
          .catch(async (error) => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              this.getNetworkEquipment(gridId)
            } else {
              this.showAlert = true
              this.alertType = 'error'
              this.alertMessage = this.alertMessageGeneral
            }
          })
          .finally(() => { this.loadingNetworkEquipment = false })
      },

    },
  }
</script>
