<template>
  <v-container
    v-if="!loadingChart"
  >
    <v-toolbar
      style="position: relative"
      dense
      justify="end"
    >
      <v-spacer />
      <v-tooltip
        bottom
        open-delay="1000"
      >
        <template v-slot:activator="{ on, attrs }">
          <div
            v-bind="attrs"
            v-on="on"
          >
            <v-menu
              v-model="dateMenu"
              :close-on-content-click="false"
              offset-y
            >
              <template v-slot:activator="props">
                <v-btn
                  color="primary"
                  text
                  dark
                  v-bind="props.attrs"
                  v-on="props.on"
                >
                  <v-icon>
                    mdi-calendar-search
                  </v-icon>
                </v-btn>
              </template>
              <div
                class="d-flex justify-center flex-wrap pb-7"
                style="width: 290px; background: white; padding-bottom: 5px;"
              >
                <v-date-picker
                  v-model="dates"
                  range
                  :allowed-dates="(date) => date <= new Date().toISOString().substr(0, 10)"
                  @change="(input) => {
                    if (input[0] === input[1]) {
                      dates = []
                    }
                  }"
                />
                <v-btn
                  color="green darken-1 mb-4"
                  text
                  :loading="loadingHistoricData"
                  :disabled="dates.length === 0"
                  @click="() => {isHistoric ? setChartHistoricData() : navigateToHistoricData() }"
                >
                  Show data for selected date(s)
                </v-btn>
              </div>
            </v-menu>
          </div>
        </template>
        <span>View historic data for selected range</span>
      </v-tooltip>
      <v-tooltip
        bottom
        open-delay="1000"
      >
        <template v-slot:activator="{ on, attrs }">
          <div
            v-bind="attrs"
            v-on="on"
          >
            <v-menu
              v-if="isPreview"

              :close-on-content-click="false"
              offset-y
            >
              <template v-slot:activator="props">
                <v-btn
                  color="primary"
                  text
                  dark
                  v-bind="props.attrs"
                  v-on="props.on"
                >
                  <v-icon>
                    mdi-palette
                  </v-icon>
                </v-btn>
              </template>

              <div
                v-if="!showCustomColors"
                style="width: 280px; background: white; padding: 5px;"
              >
                <v-data-table
                  item-key="id"
                  class="elevation-1"
                  hide-default-footer
                  :items="colorPalletes"
                  :headers="colorHeaders"
                  :single-select="true"
                  show-select
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr
                        v-for="item in items"
                        :key="item.id"
                      >
                        <td>
                          <v-checkbox
                            v-model="selectedItem"
                            style="margin:0px;padding:0px"
                            hide-details
                            :value="item.id"
                            @click.native.prevent.stop.capture="onChangeSelection(item)"
                          />
                        </td>
                        <td class="px-1">
                          <div class="d-flex">
                            <div
                              v-for="color in item.colors"
                              :key="color"
                            >
                              <div
                                :style="`
                            background-color: ${color};
                            width: 20px;
                            height: 20px;
                            margin: 5px;
                          `"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                  <v-data-table />
                </v-data-table>
                <v-btn
                  color="green darken-1"
                  class="mt-2"
                  text
                  @click="showCustomColors = true"
                >
                  Customize
                </v-btn>
              </div>
              <div
                v-else
                style="background: white;"
              >
                <v-color-picker
                  v-model="selectedColor"
                  mode="hexa"
                  @input="onColorPickerUpdate"
                />
                <table style="overflow-y: auto; max-height: 200px; display: block; margin-left: 5px;">
                  <tbody>
                    <tr
                      v-for="(name, i) in customColors"
                      :key="name"
                    >
                      <td>
                        <div
                          class="custom-color-box"
                          :style="`
                            background-color: ${customColorArray[i] ? customColorArray[i] : 'lightGrey' };
                            width: 26px;
                            height: 20px;
                            margin: 5px;
                            margin-right: 10px;
                            border-left: ${i === selectedColorIndex ? '6px solid lightGrey' : '6px solid white'};
                      `"
                          @click="onChangeSelectedColor(i)"
                        />
                      </td>
                      <td>
                        {{ name }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="d-flex pa-3">
                  <v-btn
                    color="grey darken-1"
                    text
                    @click="showCustomColors = false"
                  >
                    <v-icon>
                      mdi-arrow-left
                    </v-icon>
                  </v-btn>
                  <v-btn
                    color="primary"
                    text
                    @click="applyCustomColors"
                  >
                    Apply Changes
                  </v-btn>
                </div>
              </div>
            </v-menu>
          </div>
        </template>
        <span>Adjust Chart Colors</span>
      </v-tooltip>
      <v-tooltip
        v-if="isHistoric"
        bottom
        open-delay="1000"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="grey darken-1"
            text
            dark
            v-bind="attrs"
            v-on="on"
            @click="localBus.$emit('resetZoom')"
          >
            <v-icon>
              mdi-arrow-expand-all
            </v-icon>
          </v-btn>
        </template>
        <span>Reset Chart Zoom</span>
      </v-tooltip>
      <v-tooltip
        bottom
        open-delay="1000"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            text
            dark
            v-bind="attrs"
            v-on="on"
            @click="showDownload(true)"
          >
            <v-icon>
              mdi-download
            </v-icon>
          </v-btn>
        </template>
        <span>Download CSV</span>
      </v-tooltip>
      <v-tooltip
        bottom
        open-delay="1000"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            text
            dark
            v-bind="attrs"
            v-on="on"
            @click="showDownload(false)"
          >
            <v-icon>
              mdi-image-move
            </v-icon>
          </v-btn>
        </template>
        <span>Export Image</span>
      </v-tooltip>
      <div
        v-if="!isHistoric"
      >
        <v-tooltip
          v-if="!isPreview"
          bottom
          open-delay="1000"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              id="chartToolbarDeleteChartButton"
              color="red darken-3"
              text
              dark
              v-bind="attrs"
              v-on="on"
              @click="deleteChart"
            >
              <v-icon>
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
          <span>Delete Chart From Dashboard</span>
        </v-tooltip>
        <v-tooltip
          v-else
          bottom
          open-delay="1000"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              id="chartToolbarSaveChartButton"
              color="green darken-1"
              text
              dark
              v-bind="attrs"
              :loading="savingChart"
              v-on="on"
              @click="saveChart"
            >
              <v-icon>
                mdi-content-save
              </v-icon>
            </v-btn>
          </template>
          <span>Save Chart To Dashboard</span>
        </v-tooltip>
      </div>
      <v-dialog
        v-model="showDownloadChartDialog"
        persistent
        max-width="400"
      >
        <v-card class="pa-4 px-6">
          <v-card-title class="text-h5 pl-0">
            Download {{ downloadType }}
          </v-card-title>
          <v-text-field
            v-model="filename"
            label="Filename"
          />
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="green darken-1"
              text
              @click="download"
            >
              Save
            </v-btn>
            <v-btn
              color="grey darken-1"
              text
              @click="showDownloadChartDialog = false"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
  </v-container>
</template>

<script>
  import store from '@/store'

  export default {
    name: 'ChartTools',

    props: {
      chartInitialData: {
        type: Object,
        default: () => {},
      },
      localBus: Object,
      isPreview: {
        type: Boolean,
        default: false,
      },
      isHistoric: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({

      color: 'info',
      loadingChart: true,
      showDownloadChartDialog: false,
      downloadType: '',
      downloadImage: false,
      filename: '',
      savingChart: false,

      loadingHistoricData: false,

      selectedItem: 1,
      colorHeaders: [
        {
          text: 'Color Palletes',
          align: 'start',
          sortable: false,
          value: 'id',
        },
      ],
      colorPalletes: [
        { id: 1, colors: ['#d73027', '#f46d43', '#fdae61', '#fee090', '#ffffbf', '#e0f3f8'] },
        { id: 2, colors: ['#2c6321', '#B3EFFF', '#C09DFF', '#FF8A2F', '#AC2721', '#FFD377'] },
        { id: 3, colors: ['#775D93', '#2DCB95', '#C1776F', '#2834AA', '#8B9047', '#8D88FF'] },
        { id: 4, colors: ['#62C1FF', '#D830A0', '#C5CBFF', '#AC79FF', '#61D5B5', '#F17555'] },
        { id: 5, colors: ['#1C89AA', '#FFB29D', '#683A21', '#FFF7A9', '#3E1F6C', '#BEFFC2'] },
        { id: 6, colors: ['#57CEBD', '#fcb72b', '#B88ADE', '#DECE57', '#3D979E', '#17458F'] },
      ],

      showCustomColors: false,
      selectedColor: '#FF0000',
      selectedColorIndex: 0,
      customColorArray: ['#d73027', '#f46d43', '#fdae61', '#fee090', '#ffffbf', '#e0f3f8', '#abd9e9', '#74add1', '#4575b4'],

      dates: [],
      dateMenu: false,
    }),

    computed: {
      customColors: function () {
        return this.chartInitialData.datasets.map(dataset => {
          return dataset.data.map((equipment) => {
            const equipmentPoints = []
            equipment.equipmentDataPoints.forEach((point) => {
              equipmentPoints.push(equipment.equipmentName.replaceAll('_', ' ') +
                ' - ' + point.dataHumanLabel.replaceAll('_', ' '))
            })
            return equipmentPoints
          }).flat()
        }).flat()
      },
    },

    created () {
      this.localBus.$on('chartLoaded', () => {
        this.loadingChart = false
      })
      this.localBus.$on('saveError', () => {
        this.savingChart = false
      })
      this.selectedColor = this.customColorArray[0]
      if (this.customColors.length > this.customColorArray.length) this.setOverflowColors()

      this.localBus.$on('getByDateRangeError', () => {
        this.loadingHistoricData = false
        this.dateMenu = false
      })
      this.localBus.$on('getByDateRangeSuccess', () => {
        this.loadingHistoricData = false
        this.dateMenu = false
        if (this.dates.length > 1 && this.dates[0] !== this.dates[1]) {
          this.localBus.$emit('dataAverageInfo')
        }
      })
      this.localBus.$on('getByDateRangePartialSuccess', () => {
        this.loadingHistoricData = false
        this.dateMenu = false
        if (this.dates.length > 1 && this.dates[0] !== this.dates[1]) {
          this.localBus.$emit('dataAverageInfo')
        }
      })
    },

    methods: {

      showDownload: function (csv) {
        if (csv) {
          this.filename = this.chartInitialData.title + '.csv'
          this.downloadType = 'CSV'
          this.downloadImage = false
        } else {
          this.filename = this.chartInitialData.title + '.png'
          this.downloadType = 'Image'
          this.downloadImage = true
        }
        this.showDownloadChartDialog = true
      },
      download: function () {
        if (this.downloadImage) {
          this.localBus.$emit('downloadImage', this.filename)
        } else {
          this.localBus.$emit('downloadCSV', this.filename)
        }
        this.showDownloadChartDialog = false
      },
      deleteChart: function () {
        this.localBus.$emit('deleteChart')
      },
      saveChart: function () {
        this.savingChart = true
        this.localBus.$emit('saveChart')
      },
      onChangeSelection: function (item) {
        if (this.selectedItem !== item.id) {
          this.selectedItem = item.id
          this.customColorArray = item.colors
          this.selectedColor = this.customColorArray[this.selectedColorIndex]
          if (this.customColors.length > this.customColorArray.length) this.setOverflowColors()

          this.localBus.$emit('changeColors', item.colors)
        }
      },
      onChangeSelectedColor: function (i) {
        this.selectedColorIndex = i
        this.selectedColor = this.customColorArray[i]
      },
      onColorPickerUpdate: function (color) {
        this.$set(this.customColorArray, this.selectedColorIndex, color)
      },
      applyCustomColors: function () {
        this.localBus.$emit('changeColors', this.customColorArray)
      },
      setOverflowColors: function () {
        let counter = 0
        for (let i = this.customColorArray.length - 1; i < this.customColors.length; i++) {
          this.customColorArray.push(this.customColorArray[counter])
          if (counter >= this.customColorArray.length) {
            counter = 0
          } else {
            counter++
          }
        }
      },

      navigateToHistoricData: function () {
        store.set('app/historicData', { datapoints: this.chartInitialData, initialDates: this.dates })
        this.$router.push('/historic/')
      },
      setChartHistoricData: function () {
        this.emptyDatasets = []
        this.loadingHistoricData = true
        this.localBus.$emit('setChartHistoryData', this.dates)
      },
    },

  }
</script>
<style scoped>
.custom-color-box:hover {
  cursor: pointer;
}
</style>
