// Imports
import Vue from 'vue'
import Router from 'vue-router'
import { trailingSlash, companyCheckGuard, adminGuard } from '@/util/helpers'
import {
  layout,
  route,
} from '@/util/routes'
import store from '@/store'

Vue.use(Router)

/**
 * Something to be aware of.
 */
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    layout('Default', [
      // Landing-ish this might get rolled into the main AFS site
      route('Landing', '404Login', ''),

      // Chart dashboard
      route('Dashboard', null, 'dashboard'),

      // These both will route to /site to render stuff
      route('Site', null, 'site/:site?/:id?'),
      route('Equipment', null, 'equipment/:site/:rootSiteId/:equipmentId'),

      // Alarms for a particular site
      route('Alarms', null, 'site/:site?/:id?/alarms'),

      // Diagrams for a particular site
      route('Diagrams', null, 'site/:site?/:id?/diagrams'),

      // Administration
      route('Admin', null, 'admin', adminGuard),
      route('Register', null, 'admin/register', adminGuard),

      // Historic Data
      route('HistoricData', null, 'historic'),

      // EWC
      route('EWC', null, 'ewc'),
      route('Add Equipment', 'EWCAddEquipment', 'ewc/add-equipment'),
      route('Requests', 'EWCRequests', 'ewc/requests'),
      route('Equipment Models', 'EWCModels', 'ewc/models'),
      route('Edit Pending Request', 'EWCEditPendingRequests', 'ewc/requests/edit/:requestId/:requestEquipmentId/:gridId/:siteId'),
      route('Edit Equipment', 'EWCEditEquipment', 'ewc/edit/:equipmentId/:gridId/:siteId/:requestId?/:equipmentToEditId?'),
      route('Power And Network', 'EWCPowerAndNetwork', 'ewc/power-and-network'),
      route('Equipment Search', 'EWCSearch', 'ewc/search'),
      route('Configure Floor', 'EWCConfigureFloor', 'ewc/configure-floor'),
      route('Audit View', 'EWCAuditView', 'ewc/audit-view'),

      // Components
      route('Notifications', null, 'components/notifications'),
      route('Icons', null, 'components/icons'),
      route('Typography', null, 'components/typography'),

      // Tables
      route('Regular Tables', null, 'tables/regular'),

      // Maps
      route('Google Maps', null, 'maps/google'),

    ]),
    layout('Restricted', [
      // Login
      route('Login', null, 'login/:company?', companyCheckGuard),

      // General 404
      route('404Login', null, 'login-path-error/*'),

      // Set/reset Password
      route('SetPassword', null, '/reset'),

      // General 404
      route('404', null, '*'),

    ]),
  ],
})
// todo push router to redirect page once created
router.beforeEach((to, from, next) => {
  const crumb = { to: to.path }
  // this checks to see if navigation will call the same route again with / at the end
  if (to.path.charAt(to.path.length - 1) === '/') {
    // series of if elses to check what title to give crumb being sent to update the store
    if (to.params.site) {
      if (to.path.split('/')[4] === 'alarms') {
        store.set('app/addBreadCrumb', { ...crumb, title: 'Alarms' })
      } else if (to.path.split('/')[4] === 'diagrams') {
        store.set('app/addBreadCrumb', { ...crumb, title: 'Diagrams' })
      } else {
      store.set('app/addBreadCrumb', { ...crumb, title: to.params.site })
      }
    } else if (to.params.equipment) {
      store.set('app/addBreadCrumb', { ...crumb, title: to.params.equipment })
    } else {
      store.set('app/addBreadCrumb', { ...crumb, title: to.name })
    }
  }
  return to.path.endsWith('/') ? next() : next(trailingSlash(to.path))
})

export default router
