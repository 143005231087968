var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"width":"100%","height":"800px"},attrs:{"fluid":""}},[(_vm.networkEquipmentData)?_c('v-card',{staticClass:"d-flex justify-center flex-wrap",staticStyle:{"width":"100%","height":"100%"}},[(_vm.loadingNetworkEquipment || _vm.noGridHasBeenSetup)?_c('div',{staticClass:"d-flex justify-center align-center"},[(_vm.loadingNetworkEquipment === true)?_c('v-progress-circular',{attrs:{"indeterminate":""}}):_c('div',{staticClass:"d-flex justify-center flex-wrap"},[_c('v-card-title',{staticClass:"text-h3 font-weight-bold grey--text"},[_vm._v(" No network architecture has been assigned to this floorplan ")]),_c('v-btn',{staticClass:"font-weight-bold white--text mt-2",attrs:{"color":"green darken-2"}},[_vm._v(" Add Network Architecture ")])],1)],1):[_c('v-toolbar',{staticClass:"pt-3 mt-5",attrs:{"width":"100%","height":"15%","flat":""}},[_c('v-card-title',{staticClass:"text-h3 font-weight-bold grey--text"},[_vm._v(" Network Architecture ")])],1),_c('v-row',{staticClass:"ml-7 mt-3 align-center",staticStyle:{"width":"100%"}},[_c('v-col',{staticClass:"d-flex justify-center flex-wrap",attrs:{"cols":"2"}},[_c('v-card',{staticClass:"pa-6 d-flex justify-center flex-wrap",staticStyle:{"width":"150px","height":"150px"},attrs:{"color":"rgb(219, 195, 171)","raised":""}},[_c('div',{staticClass:"font-weight-bold mr-2 d-flex flex-wrap justify-center"},[_c('div',{staticClass:"d-flex justify-center",staticStyle:{"width":"100%"}},[_vm._v(" Core ")]),_c('div',[_vm._v("Layer")])]),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"green darken-2"},on:{"click":function($event){return _vm.localBus.$emit('open-add-network-equipment', 'Core Layer', _vm.networkEquipmentData)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"large":""}},[_vm._v(" "+_vm._s(_vm.mdiPlus)+" ")])],1)]}}],null,false,2528488645)},[_c('span',[_vm._v("Add Network Equipment To Core Layer")])])],1)],1),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"10"}},_vm._l((_vm.networkEquipmentData.coreLayer),function(coreEquipment){return _c('div',{key:coreEquipment.id,staticClass:"ma-2"},[_c('div',{style:(("height: 150px; width: 200px; " + (coreEquipment === _vm.selectedEquipment ? _vm.selectedStyle : 'background-color: rgba(119, 103, 224, .6); border: solid rgba(119, 103, 224);') + " cursor: pointer;")),on:{"mousemove":function (e) { return _vm.localBus.$emit('display-tooltip', ({e: e, label: coreEquipment.equipmentModelNetworkInformation.model})); },"mouseleave":function($event){return _vm.localBus.$emit('hide-tooltip')},"click":function($event){return _vm.onSelectEquipment(coreEquipment)}}})])}),0),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"2"}}),_c('v-col',{staticClass:"pa-0 ml-9",staticStyle:{"border-bottom":"dashed rgb(73, 101, 227)"},attrs:{"cols":"9"}})],1),_c('v-row',{staticClass:"ml-7 align-center",staticStyle:{"width":"100%"}},[_c('v-col',{staticClass:"d-flex justify-center flex-wrap",attrs:{"cols":"2"}},[_c('v-card',{staticClass:"pa-6 d-flex justify-center flex-wrap",staticStyle:{"width":"150px","height":"150px"},attrs:{"color":"rgb(219, 195, 171)","raised":""}},[_c('div',{staticClass:"font-weight-bold mr-2 d-flex flex-wrap justify-center"},[_vm._v(" Aggregate "),_c('span',[_vm._v("Layer")])]),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"green darken-2"},on:{"click":function($event){return _vm.localBus.$emit('open-add-network-equipment', 'Aggregate Layer', _vm.networkEquipmentData)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"large":""}},[_vm._v(" "+_vm._s(_vm.mdiPlus)+" ")])],1)]}}],null,false,2995692351)},[_c('span',[_vm._v("Add Network Equipment To Aggregate Layer")])])],1)],1),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"10"}},_vm._l((_vm.networkEquipmentData.aggregateLayer),function(aggregateEquipment){return _c('div',{key:aggregateEquipment.id,staticClass:"ma-2"},[_c('div',{style:(("height: 300px; width: 200px; " + (aggregateEquipment === _vm.selectedEquipment ? _vm.selectedStyle : 'background-color: rgba(103, 200, 224, .6); border: solid rgb(103, 200, 224);') + " cursor: pointer;")),on:{"mousemove":function (e) { return _vm.localBus.$emit('display-tooltip', ({e: e, label: aggregateEquipment.equipmentModelNetworkInformation.model})); },"mouseleave":function($event){return _vm.localBus.$emit('hide-tooltip')},"click":function($event){return _vm.onSelectEquipment(aggregateEquipment)}}})])}),0),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"2"}}),_c('v-col',{staticClass:"pa-0 ml-9",staticStyle:{"border-bottom":"dashed rgb(73, 101, 227)"},attrs:{"cols":"9"}})],1),_c('v-row',{staticClass:"ml-7 mb-2  align-center",staticStyle:{"width":"100%"}},[_c('v-col',{staticClass:"d-flex justify-center align-center flex-wrap",attrs:{"cols":"2"}},[_c('v-card',{staticClass:"pa-6 d-flex justify-center align-center flex-wrap",staticStyle:{"width":"150px","height":"150px"},attrs:{"color":"rgb(219, 195, 171)","raised":""}},[_c('div',{staticClass:"font-weight-bold mr-2 d-flex flex-wrap justify-center"},[_vm._v(" Access "),_c('span',[_vm._v("Layer")])]),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"green darken-2"},on:{"click":function($event){return _vm.localBus.$emit('open-add-network-equipment', 'Access Layer', _vm.networkEquipmentData)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"large":""}},[_vm._v(" "+_vm._s(_vm.mdiPlus)+" ")])],1)]}}],null,false,1636761114)},[_c('span',[_vm._v("Add Network Equipment To Access Layer")])])],1)],1),_c('v-col',{staticClass:"d-flex justify-center flex-wrap pt-0",attrs:{"cols":"10"}},_vm._l((_vm.networkEquipmentData.accessLayer),function(accessEquipment){return _c('div',{key:accessEquipment.id,staticClass:"ma-2"},[_c('div',{style:(("height: 50px; width: 100px; " + (accessEquipment === _vm.selectedEquipment ? _vm.selectedStyle : 'background-color: rgba(52, 111, 194, .6); border: solid rgb(52, 111, 194);') + " cursor: pointer;")),on:{"mousemove":function (e) { return _vm.localBus.$emit('display-tooltip', ({e: e, label: accessEquipment.equipmentModelNetworkInformation.model})); },"mouseleave":function($event){return _vm.localBus.$emit('hide-tooltip')},"click":function($event){return _vm.onSelectEquipment(accessEquipment)}}})])}),0)],1)]],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }