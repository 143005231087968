<template>
  <rect
    :x="position.x * rectangleWidth + xOffsetPixels"
    :y="position.y * rectangleHeight + yOffsetPixels"
    :width="rectangleWidth"
    :height="rectangleHeight"
    :style="fillStyle"
    @click="selectable ? onSelect() : ''"
    @mousemove="e => selectable ? onMouseMove(e) : ''"
    @mouseleave="selectable ? localBus.$emit('hide-tooltip') : ''"
  />
</template>
<script>
  import fillStyles from './fillStyles'

  export default {
    name: 'EwcEnclosureRectangle',
    props: {
      position: Object,
      rectangleWidth: Number,
      rectangleHeight: Number,
      xOffsetPixels: Number,
      yOffsetPixels: Number,
      localBus: {
        type: Object,
      },
      selectedItems: Array,
      selectable: Boolean,
      mini: Boolean,
    },
    data: (instance) => ({
      standardFillStyle: `fill: rgba(255, 255, 255); stroke-width:${instance.mini ? '1' : '3'}; stroke: rgb(207, 207, 207); ${instance.selectable ? 'cursor: pointer' : ''}`,
      selectedFillStyle: `${fillStyles.selectedFillStyle} ${instance.selectable ? 'cursor: pointer' : ''}`,

      lastMove: 0,
      eventThrottle: 25,
    }),
    computed: {
      fillStyle () {
        let selected = false
        this.selectedItems.forEach(item => {
          if (item.x === this.position.x && item.y === this.position.y) {
            selected = true
          }
        })
        if (selected) {
          return this.selectedFillStyle
        } else {
          return this.standardFillStyle
        }
      },
    },
    methods: {
      onSelect: function () {
        if (this.selectable) {
          this.localBus.$emit('onSelectEnclosureRectangle', this.position)
        }
      },
      onMouseMove (e) {
        e.preventDefault()
        // use eventThrottle to limit frequency of events emitted because of mouse move
        const now = Date.now()
        if (now > this.lastMove + this.eventThrottle) {
          this.lastMove = now
          this.localBus.$emit('display-tooltip-bay', ({ e: e, label: { x: this.position.x, y: this.position.y } }))
        }
      },
    },
  }
</script>
