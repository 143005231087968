<template>
  <v-toolbar
    width="100%"
    flat
  >
    <v-row
      align="center"
    >
      <slot name="siteSelection" />
      <v-spacer />
      <slot name="pageSpecificIcons" />
    </v-row>
  </v-toolbar>
</template>

<script>

  export default {
    name: 'EwcToolbar',
    props: {
      pendingRequestsCount: Number,
    },
    data: () => ({
    }),
    methods: {
    },
  }
</script>
