<template>
  <g>
    <image
      v-if="enclosureItem.equipmentModel.imagePath"
      :href="enclosureItem.equipmentModel.imagePath"
      :width="rectangleWidth * enclosureItem.location.uwidth"
      :height="rectangleHeight * enclosureItem.location.uheight"
      :x="enclosureItem.location.pixelTopX ? enclosureItem.location.pixelTopX : (enclosureItem.location.gridX )*rectangleWidth + xOffsetPixels"
      :y="enclosureItem.location.pixelTopY ? enclosureItem.location.pixelTopY : (enclosureItem.location.gridY ) *rectangleHeight + yOffsetPixels"
      preserveAspectRatio="none"
    />
    <rect
      :x="enclosureItem.location.pixelTopX ? enclosureItem.location.pixelTopX : (enclosureItem.location.gridX )*rectangleWidth + xOffsetPixels"
      :y="enclosureItem.location.pixelTopY ? enclosureItem.location.pixelTopY : (enclosureItem.location.gridY ) *rectangleHeight + yOffsetPixels"
      :width="rectangleWidth * enclosureItem.location.uwidth"
      :height="rectangleHeight * enclosureItem.location.uheight"
      :style="enclosureItem.equipmentModel.imagePath ?
        selectedItemId === enclosureItem.equipmentData.id ? 'fill: rgba(180, 237, 224, .2); stroke-width:3; stroke: rgb(44, 158, 115); cursor: pointer;' : 'opacity: 0; cursor: pointer;' :
        fillStyle"
      @click="selectable ? onSelect() : ''"
      @mousemove="onMouseMove"
      @mouseleave="localBus.$emit('hide-tooltip', enclosureItem.equipmentData.label ? enclosureItem.equipmentData.label : enclosureItem.equipmentModel.name)"
    />
  </g>
</template>

<script>
  import fillStyles from './fillStyles'

  export default {
    name: 'EwcEnclosureItem',
    props: {
      enclosureItem: Object,
      rectangleWidth: Number,
      rectangleHeight: Number,
      xOffsetPixels: Number,
      yOffsetPixels: Number,
      localBus: Object,
      selectable: Boolean,
      selectedItemId: Number,
    },
    data: (instance) => ({
      selectedFillStyle: fillStyles.selectedFillStyle,
      standardFillStyle: fillStyles.standardFillStyle,
      unselectableFillStyle: fillStyles.unselectableFillStyle,

      lastMove: 0,
      eventThrottle: 25,
    }),
    computed: {
      fillStyle () {
        if (this.selectedItemId === this.enclosureItem.equipmentData.id) {
          return this.selectedFillStyle
        } else {
          if (this.selectable) {
            return this.standardFillStyle
          } else {
            return this.unselectableFillStyle
          }
        }
      },
    },
    methods: {
      onSelect: function () {
        if (this.selectedItemId !== this.enclosureItem.equipmentData.id && this.selectable) {
          this.localBus.$emit('onSelectEnclosureItem', this.enclosureItem)
        } else if (this.selectedItemId === this.enclosureItem.equipmentData.id) {
          this.localBus.$emit('clearSelectedEnclosureItem')
        }
      },
      onMouseMove (e) {
        e.preventDefault()
        // use eventThrottle to limit frequency of events emitted because of mouse move
        const now = Date.now()
        if (now > this.lastMove + this.eventThrottle) {
          this.lastMove = now
          this.localBus.$emit('display-tooltip', ({ e: e, label: this.enclosureItem.equipmentData.label ? this.enclosureItem.equipmentData.label : this.enclosureItem.equipmentModel.name }))
        }
      },
    },
  }
</script>
