<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-card
      class="py-2 px-8 "
      style="width: 100%; height: 100%;"
    >
      <div class="d-flex align-vertical pa-2">
        <v-card-title class="text-h4 font-weight-bold grey--text pt-2">
          Add Network Connection
        </v-card-title>
        <v-btn
          color="primary"
          :text="!addSingleConnection"
          :class="`${addSingleConnection ? 'active-select-btn' : '' }`"
          @click="
            addSingleConnection = true
            selectedTableItem = null
            selectedTableItems = []
            multiConnectionSelections = []
          "
        >
          Single
        </v-btn>
        <v-btn
          color="primary"
          :text="addSingleConnection"
          :class="`${!addSingleConnection ? 'active-select-btn' : '' }`"
          @click=" event => {
            addSingleConnection = false
            if(selectedTableItem){multiConnectionSelections.push(selectedTableItem)}
            stateNumberOfPorts = null
          }
          "
        >
          Multiple
        </v-btn>
      </div>
      <v-card-text>
        <v-row>
          <v-col :cols="addSingleConnection ? 12 : 7">
            <div class="mb-3 font-weight-bold black--text">
              Select a piece of equipment from the active floor equipment:
            </div>
            <v-data-table
              v-model="selectedTableItems"
              :headers="headers"
              :single-select="addSingleConnection"
              item-key="id"
              :items="activeEquipmentDataTableArray"
              :fixed-header="true"
              calculate-widths
              :options.sync="pagination"
              height="480"
              :value="selectedRow"
              @pagination="event => {
                renderedEquipmentDataTableLength = event.itemsLength
              }"
              @click:row="
                (item, row) => {
                  if(checkIfItemIsSelected(item)){
                    row.select(false)
                    removeMultiConnection(item)
                  }else {
                    if(!addSingleConnection) multiConnectionSelections.push(item)
                    row.select(true)
                  }
                  selectedTableItem = item
                }
              "
            >
              <template
                v-for="header in headers.filter((header) =>
                  header.hasOwnProperty('formatter')
                )"
                v-slot:[`item.${header.value}`]="{ value }"
              >
                {{ header.formatter(value) }}
              </template>
              <template v-slot:[`body.prepend`]>
                <tr>
                  <td>
                    <v-text-field
                      v-model="tableFieldLabel"
                      type="text"
                      class="pt-4"
                      outlined
                      dense
                      label="filter..."
                    />
                  </td>
                  <td>
                    <v-select
                      v-model="tableFieldStatus"
                      :items="['Arrived', 'Deployed', 'Decommissioned', 'Disposed']"
                      class="pt-4"
                      outlined
                      dense
                    >
                      <v-btn
                        v-if="tableFieldStatus"
                        slot="prepend-inner"
                        x-small
                        style="margin-top: 3px;"
                        text
                        @click="tableFieldStatus = ''"
                      >
                        <v-icon size="small">
                          mdi-close
                        </v-icon>
                      </v-btn>
                    </v-select>
                  </td>
                  <td>
                    <v-text-field
                      v-model="tableFieldSerial"
                      type="text"
                      class="pt-4"
                      outlined
                      dense
                    />
                  </td>
                  <td>
                    <v-text-field
                      v-model="tableFieldAssetTag"
                      type="text"
                      class="pt-4"
                      outlined
                      dense
                    />
                  </td>
                  <td>
                    <v-text-field
                      v-model="tableFieldModel"
                      type="text"
                      class="pt-4"
                      outlined
                      dense
                    />
                  </td>
                  <td>
                    <v-text-field
                      v-model="tableFieldRackLocation"
                      type="text"
                      class="pt-4"
                      outlined
                      dense
                    />
                  </td>
                  <td>
                    <v-text-field
                      v-model="tableFieldUnitLocation"
                      type="text"
                      class="pt-4"
                      outlined
                      dense
                    />
                  </td>
                  <td>
                    <v-text-field
                      v-model="tableFieldBay"
                      type="text"
                      class="pt-4"
                      outlined
                      dense
                    />
                  </td>
                  <td>
                    <v-text-field
                      v-model="tableFieldNotes"
                      type="text"
                      class="pt-4"
                      outlined
                      dense
                    />
                  </td>
                  <td>
                    <v-text-field
                      v-model="tableFieldAssetId"
                      type="text"
                      class="pt-4"
                      outlined
                      dense
                    />
                  </td>
                  <td>
                    <v-text-field
                      v-model="tableFieldProjectNumber"
                      type="text"
                      class="pt-4"
                      outlined
                      dense
                    />
                  </td>
                  <td>
                    <v-text-field
                      v-model="tableFieldProjectName"
                      type="text"
                      class="pt-4"
                      outlined
                      dense
                    />
                  </td>
                  <td>
                    <v-text-field
                      v-model="tableFieldDepartment"
                      type="text"
                      class="pt-4"
                      outlined
                      dense
                    />
                  </td>
                  <td>
                    <v-text-field
                      v-model="tableFieldAcquisitionDate"
                      type="text"
                      class="pt-4"
                      outlined
                      dense
                    />
                  </td>
                  <td>
                    <v-text-field
                      v-model="tableFieldDecommissionDate"
                      type="text"
                      class="pt-4"
                      outlined
                      dense
                    />
                  </td>
                  <td>
                    <v-text-field
                      v-model="tableFieldDisposalDate"
                      type="text"
                      class="pt-4"
                      outlined
                      dense
                    />
                  </td>
                  <td>
                    <v-text-field
                      v-model="tableFieldDescription"
                      type="text"
                      class="pt-4"
                      outlined
                      dense
                    />
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
          <v-col
            v-if="!addSingleConnection"
            cols="5"
          >
            <div class="mb-3 font-weight-bold black--text">
              Current Selections:  {{ selectedNetworkEquipment.equipmentModelNetworkInformation.ports - totalPortsUsed }} available ports
            </div>
            <div>
              <v-row>
                <v-col cols="6 font-weight-bold">
                  Serial Or Asset Tag
                </v-col>
                <v-col cols="4 font-weight-bold">
                  Ports
                </v-col>
              </v-row>
              <template v-for="(item, index) in multiConnectionSelections">
                <v-row
                  :key="item.equipmentEwcId"
                  class="mt-0 align-center"
                >
                  <v-col
                    cols="6"
                    class="mb-3 black--text"
                  >
                    {{ item.serial }} {{ item.assetTag }}
                  </v-col>
                  <v-col cols="4">
                    <div
                      style="height: 65px;"
                    >
                      <v-text-field
                        v-model="multiConnectionSelections[index].statePorts"
                        dense
                        outlined
                        label="Number Of Ports"
                        type="number"
                        :rules="[rules.required, rules.wholeNumber, rules.positiveNumber, rules.lessThanAvailablePortsMulti]"
                      />
                    </div>
                  </v-col>
                  <v-col
                    cols="2"
                    class="mb-5"
                  >
                    <v-btn
                      color="primary"
                      text
                      min-width="50"
                      width="50"
                      @click="selectedTableItems = selectedTableItems.filter(selectedItem => {
                                return selectedItem.equipmentEwcId !== item.equipmentEwcId
                              })
                              removeMultiConnection(item)"
                    >
                      <v-icon>
                        {{ mdiClose }}
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </div>
          </v-col>
        </v-row>
        <v-expand-transition v-if="selectedTableItem !== null && addSingleConnection">
          <div>
            <div class="mb-2 font-weight-bold black--text">
              Assign the number of the ports connected -
              {{ selectedNetworkEquipment.equipmentModelNetworkInformation.ports - totalPortsUsed }} available ports
            </div>
            <v-text-field
              v-model="stateNumberOfPorts"
              style="width: 400px;"
              class="mt-4"
              dense
              outlined
              label="Number Of Ports"
              type="number"
              :rules="[rules.required, rules.wholeNumber, rules.positiveNumber, rules.lessThanAvailablePorts]"
            />
          </div>
        </v-expand-transition>
        <v-alert
          v-if="showAddNetworkConnectionAlert"
          type="error"
          class="mt-2 mb-0"
        >
          {{ addNetworkConnectionAlertMessage }}
        </v-alert>
        <div class="d-flex justify-center">
          <v-btn
            :disabled="!addConnectionsFormComplete"
            :loading="addingNetworkConnection"
            color="green darken-1 white--text"
            class="font-weight-bold ma-4"
            @click="addSingleConnection ? addNetworkConnection() : addMultipleNetworkConnections()"
          >
            Add
          </v-btn>
          <v-btn
            color="grey"
            class="font-weight-bold white--text ma-4"
            @click="
              selectedTableItem = null
              selectedTableItems = []
              stateNumberOfPorts = null
              showAddNetworkConnectionAlert = false
              localBus.$emit('close-add-network-connection')
            "
          >
            Cancel
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

  import moment from 'moment'
  import networkApi from '@/api/network_ewc.js'
  import common from '@/api/common.js'
  import { mdiClose } from '@mdi/js'

  export default {
    name: 'EwcAddNetworkConnection',
    props: {
      localBus: {
        type: Object,
      },
      layerType: String,
      activeEquipment: {
        type: Array,
        default: () => [],
      },
      selectedNetworkEquipment: {
        type: Object,
      },
      networkEquipmentList: Object,
      selectedFloorId: Number,
    },
    data: () => ({
      selectedTableItem: null,
      selectedTableItems: [],
      tableFieldLabel: '',
      tableFieldStatus: '',
      tableFieldSerial: '',
      tableFieldAssetTag: '',
      tableFieldModel: '',
      tableFieldRackLocation: '',
      tableFieldUnitLocation: '',
      tableFieldBay: '',
      tableFieldNotes: '',
      tableFieldAssetId: '',
      tableFieldProjectNumber: '',
      tableFieldProjectName: '',
      tableFieldDepartment: '',
      tableFieldAcquisitionDate: '',
      tableFieldDecommissionDate: '',
      tableFieldDisposalDate: '',
      tableFieldDescription: '',
      selectedRow: [],
      renderedEquipmentDataTableLength: 0,

      stateNumberOfPorts: null,
      showNetworkEquipmentIsInUseDialog: false,

      addingNetworkConnection: false,
      showAddNetworkConnectionAlert: false,
      addNetworkConnectionAlertMessage: '',
      alertMessageGeneral: 'We were unable to complete your request at this time. Please, try again later.',

      addSingleConnection: true,
      mdiClose: mdiClose,
      multiConnectionSelections: [],

      pagination: {
        itemsPerPage: 10,
      },
    }),
    computed: {

      headers () {
        return [
          {
            text: 'Label',
            value: 'label',
            width: '150',
            filter: value => {
              if (!this.tableFieldLabel) return true
              return (value)?.toLowerCase().includes(this.tableFieldLabel.toLowerCase())
            },
          },
          {
            text: 'Status',
            value: 'status',
            width: '200',
            filter: value => {
              if (!this.tableFieldStatus) return true
              return (value)?.toLowerCase().includes(this.tableFieldStatus.toLowerCase())
            },
          },
          {
            text: 'Serial',
            value: 'serial',
            width: '150',
            filter: value => {
              if (!this.tableFieldSerial) return true
              return (value)?.toLowerCase().includes(this.tableFieldSerial.toLowerCase())
            },
          },
          {
            text: 'Asset Tag',
            value: 'assetTag',
            width: '150',
            filter: value => {
              if (!this.tableFieldAssetTag) return true
              return (value)?.toLowerCase().includes(this.tableFieldAssetTag.toLowerCase())
            },
          },
          {
            text: 'Model',
            value: 'model',
            width: '150',
            filter: value => {
              if (!this.tableFieldModel) return true
              return (value)?.toLowerCase().includes(this.tableFieldModel.toLowerCase())
            },
          },
          {
            text: 'Rack Location',
            value: 'rackLocation',
            width: '200',
            filter: value => {
              if (!this.tableFieldRackLocation) return true
              return (value)?.toLowerCase().includes(this.tableFieldRackLocation.toLowerCase())
            },
          },
          {
            text: 'Unit Location',
            value: 'unitLocation',
            width: '200',
            filter: value => {
              if (!this.tableFieldUnitLocation) return true
              return (value)?.toLowerCase().includes(this.tableFieldUnitLocation.toLowerCase())
            },

          },
          {
            text: 'Bay',
            value: 'bay',
            width: '100',
            filter: value => {
              if (!this.tableFieldBay) return true
              return (value)?.toLowerCase().includes(this.tableFieldBay.toLowerCase())
            },
          },
          {
            text: 'Notes',
            value: 'notes',
            width: '300',
            filter: value => {
              if (!this.tableFieldNotes) return true
              return (value)?.toLowerCase().includes(this.tableFieldNotes.toLowerCase())
            },
          },
          {
            text: 'Asset Id',
            value: 'assetId',
            width: '150',
            filter: value => {
              if (!this.tableFieldAssetId) return true
              return (value)?.toLowerCase().includes(this.tableFieldAssetId.toLowerCase())
            },
          },
          {
            text: 'Project Number',
            value: 'projectNumber',
            width: '200',
            filter: value => {
              if (!this.tableFieldProjectNumber) return true
              return (value)?.toLowerCase().includes(this.tableFieldProjectNumber.toLowerCase())
            },
          },
          {
            text: 'Project Name',
            value: 'projectName',
            width: '200',
            filter: value => {
              if (!this.tableFieldProjectName) return true
              return (value)?.toLowerCase().includes(this.tableFieldProjectName.toLowerCase())
            },
          },
          {
            text: 'Department',
            value: 'departmentNumber',
            width: '150',
            filter: value => {
              if (!this.tableFieldDepartment) return true
              return (value)?.toLowerCase().includes(this.tableFieldDepartment.toLowerCase())
            },
          },
          {
            text: 'Acquisition Date',
            value: 'acquisitionDate',
            width: '200',
            formatter: (x) => x ? moment(x).format('YYYY/MM/DD') : '',
            filter: value => {
              if (!this.tableFieldAcquisitionDate) return true
              return (value)?.toLowerCase().includes(this.tableFieldAcquisitionDate.toLowerCase())
            },
          },
          {
            text: 'Decommission Date',
            value: 'decommissionDate',
            width: '220',
            formatter: (x) => x ? moment(x).format('YYYY/MM/DD') : '',
            filter: value => {
              if (!this.tableFieldDecommissionDate) return true
              return (value)?.toLowerCase().includes(this.tableFieldDecommissionDate.toLowerCase())
            },
          },
          {
            text: 'Disposal Date',
            value: 'disposalDate',
            width: '200',
            formatter: (x) => x ? moment(x).format('YYYY/MM/DD') : '',
            filter: value => {
              if (!this.tableFieldDisposalDate) return true
              return (value)?.toLowerCase().includes(this.tableFieldDisposalDate.toLowerCase())
            },
          },
          {
            text: 'Description',
            value: 'description',
            width: '150',
            filter: value => {
              if (!this.tableFieldDescription) return true
              return (value)?.toLowerCase().includes(this.tableFieldDescription.toLowerCase())
            },
          },
        ]
      },

      rules () {
        const rules = {}
        rules.required = (value) => !!value || 'Required.'
        rules.wholeNumber = (value) => value % 1 === 0 || 'Please enter a whole number'
        rules.positiveNumber = (value) => value > 0 || 'Please enter a positive number'
        rules.lessThanAvailablePorts = (value) => value <= this.selectedNetworkEquipment.equipmentModelNetworkInformation.ports - this.totalPortsUsed ||
          'Select a number less than or equal to the available number of ports'
        rules.lessThanAvailablePortsMulti = () => this.selectedNetworkEquipment.equipmentModelNetworkInformation.ports - this.totalPortsUsed >= 0 ||
          'Select a number less than or equal to the available number of ports'
        return rules
      },

      activeEquipmentDataTableArray () {
        if (Object.keys(this.activeEquipment).length) {
          return this.filterNetworkEquipmentAndCurrentConnections(this.activeEquipment.map(equipment => this.parseEquipment(equipment)).flat())
        } else return []
      },

      totalPortsUsed () {
        if (!this.selectedNetworkEquipment || !this.selectedNetworkEquipment.connections) return 0
        let totalPorts = 0
        this.selectedNetworkEquipment.connections.forEach(connection => {
          totalPorts += connection.portsUsed
        })
        if (!this.addSingleConnection) {
          this.selectedTableItems.forEach(tableItem => {
            if (parseInt(tableItem.statePorts) > 0)totalPorts += parseInt(tableItem.statePorts) || 0
          })
        }
        return totalPorts
      },

      addConnectionsFormComplete () {
        if (this.addSingleConnection) {
          return (!(!this.stateNumberOfPorts || this.stateNumberOfPorts <= 0 ||
            this.stateNumberOfPorts > this.selectedNetworkEquipment.equipmentModelNetworkInformation.ports - this.totalPortsUsed ||
            this.stateNumberOfPorts % 1 !== 0))
        } else {
          if (this.selectedTableItems.length === 0) return false
          let allComplete = true
          this.multiConnectionSelections.forEach(connection => {
            if (
              !connection.statePorts || connection.statePorts <= 0 ||
              this.selectedNetworkEquipment.equipmentModelNetworkInformation.ports - this.totalPortsUsed < 0 ||
              connection.statePorts % 1 !== 0
            ) { allComplete = false }
          })
          return allComplete
        }
      },
    },
    created () {
    },

    methods: {

      parseEquipment (equipment) {
        const array = [{ ...equipment.equipmentData, model: equipment.equipmentModel.name, ...equipment.location }]
        equipment.children.forEach(c => array.push(this.parseEquipment(c)))
        return array.flat()
      },
      filterNetworkEquipmentAndCurrentConnections (equipmentArray) {
        return equipmentArray.filter(equipment => {
          return (!this.checkNetworkEquipmentIsInUse(equipment) &&
            (this.selectedNetworkEquipment.connections
              ? !this.selectedNetworkEquipment.connections.some(connection => connection.equipmentId === equipment.equipmentEwcId)
              : true))
        })
      },
      checkNetworkEquipmentIsInUse (equipment) {
        for (const layer in this.networkEquipmentList) {
          if (Array.isArray(this.networkEquipmentList[layer])) {
            const inUse = this.networkEquipmentList[layer].some(layerItem => {
              return layerItem.equipmentId === equipment.equipmentEwcId
            })
            if (inUse) {
              return true
            }
          }
        }
        return false
      },

      addMultipleNetworkConnections () {
        this.addingNetworkConnection = true
        this.showAddNetworkConnectionAlert = false
        const connections = this.multiConnectionSelections.map(connection => {
          return {
            networkEquipmentId: this.selectedNetworkEquipment.equipmentId,
            rackEquipmentId: connection.equipmentEwcId,
            portsConnected: connection.statePorts,
          }
        })
        networkApi.batchInsertNetworkToRackEquipmentConnections({ equipmentConnections: connections })
          .then(response => {
            console.log(response)
            this.selectedTableItem = null
            this.selectedTableItems = []
            this.multiConnectionSelections = []
            this.localBus.$emit('add-network-batch-connections-success', this.selectedNetworkEquipment)
          })
          .catch(async error => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              this.addMultipleNetworkConnections()
            }
            this.showAddNetworkConnectionAlert = true
            this.addNetworkConnectionAlertMessage = this.alertMessageGeneral
          })
          .finally(() => { this.addingNetworkConnection = false })
      },
      addNetworkConnection () {
        this.addingNetworkConnection = true
        this.showAddNetworkConnectionAlert = false
        networkApi.upsertNetworkConnection({
          networkEquipmentId: this.selectedNetworkEquipment.equipmentId,
          rackEquipmentId: this.selectedTableItem.equipmentEwcId,
          portsConnected: this.stateNumberOfPorts,
        })
          .then(response => {
            console.log(response)
            this.selectedTableItem = null
            this.selectedTableItems = []
            this.stateNumberOfPorts = null
            this.localBus.$emit('add-network-connection-success', this.selectedNetworkEquipment)
          })
          .catch(async error => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              this.addNetworkConnection()
            }
            this.showAddNetworkConnectionAlert = true
            this.addNetworkConnectionAlertMessage = this.alertMessageGeneral
          })
          .finally(() => { this.addingNetworkConnection = false })
      },

      checkIfItemIsSelected (tableItem) {
        return this.selectedTableItems.some(selectedTableItem => {
          return selectedTableItem.equipmentEwcId === tableItem.equipmentEwcId
        })
      },
      removeMultiConnection (connection) {
        let index = -1
        this.multiConnectionSelections.forEach((selection, i) => {
          if (selection.equipmentEwcId === connection.equipmentEwcId) {
            index = i
          }
        })
        this.multiConnectionSelections.splice(index, 1)
      },
    },
  }
</script>
<style scoped>
.active-select-btn {
  pointer-events: none;
}
</style>
