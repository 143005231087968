<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-card
      class="pa-2 "
      style="width: 100%; height: 100%;"
    >
      <v-card-title class="text-h4 font-weight-bold grey--text">
        Current Connections - {{ networkEquipment.label || networkEquipment.equipmentModelNetworkInformation.model }}
        - Total Ports In Use: {{ totalPorts }} Of {{ networkEquipment.equipmentModelNetworkInformation.ports || 0 }}
      </v-card-title>
      <v-progress-circular
        v-if="loadingConnections"
        indeterminate
      />
      <v-card-text v-else>
        <div v-if="!networkEquipment.equipmentModelNetworkInformation.ports">
          There is no network equipment model setup for this piece of equipment. Please
          <span
            class="text-decoration-underline blue--text hvr"
            @click="localBus.$emit('open-update-network-model', networkEquipment.equipmentModelNetworkInformation)"
          >
            update
          </span>
          the model in order to add connections
        </div>
        <div v-else-if="!networkEquipment.connections">
          There are currently no connections for this equipment
        </div>
        <template v-else>
          <v-data-table
            v-model="selectedTableItems"
            class="row-pointer mx-8"
            :headers="headers"
            single-select
            item-key="equipmentId"
            :items="networkEquipment.connections"
            :fixed-header="true"
            @click:row="
              (item, row) => {
                row.select(true)
                selectedTableItem = item
              }
            "
          >
            <template v-slot:[`body.prepend`]>
              <tr class="no-pointer">
                <td>
                  <v-text-field
                    v-model="tableFieldLabel"
                    type="text"
                    class="pt-4"
                    outlined
                    dense
                  />
                </td>
                <td>
                  <v-text-field
                    v-model="tableFieldSerial"
                    type="text"
                    class="pt-4"
                    outlined
                    dense
                  />
                </td>
                <td>
                  <v-text-field
                    v-model="tableFieldAssetTag"
                    type="text"
                    class="pt-4"
                    outlined
                    dense
                  />
                </td>
                <td>
                  <v-text-field
                    v-model="tableFieldModel"
                    type="text"
                    class="pt-4"
                    outlined
                    dense
                  />
                </td>
                <td>
                  <v-text-field
                    v-model="tableFieldLocation"
                    type="text"
                    class="pt-4"
                    outlined
                    dense
                  />
                </td>
                <td>
                  <v-text-field
                    v-model="tableFieldPortsUsed"
                    type="number"
                    class="pt-4"
                    outlined
                    dense
                  />
                </td>
              </tr>
            </template>
          </v-data-table>
        </template>
        <div class="d-flex justify-center">
          <v-btn
            color="green darken-1 white--text"
            :disabled="!networkEquipment.equipmentModelNetworkInformation.ports"
            class="font-weight-bold ma-4"
            @click="localBus.$emit('start-add-network-connection', networkEquipment)"
          >
            Add New Connection
          </v-btn>
          <v-btn
            color="blue darken-1"
            :disabled="selectedTableItem === null"
            class="font-weight-bold white--text ma-4"
            @click="
              stateNumberOfPorts = selectedTableItem.portsUsed
              initialStateNumberOfPorts = selectedTableItem.portsUsed
              showUpdateNetworkConnectionDialog = true
            "
          >
            Update
          </v-btn>
          <v-btn
            color="red darken-1"
            :disabled="selectedTableItem === null"
            class="font-weight-bold white--text ma-4"
            @click="showRemoveNetworkConnectionDialog = true"
          >
            Remove
          </v-btn>
          <v-btn
            color="grey"
            class="font-weight-bold white--text ma-4"
            @click="
              selectedTableItem = null
              selectedTableItems = []
              localBus.$emit('close-network-rack-connections')
            "
          >
            Close
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
    <v-dialog
      v-if="selectedTableItem"
      v-model="showRemoveNetworkConnectionDialog"
      :persistent="removingNetworkConnection"
      width="660"
    >
      <v-card class="pa-4 px-6">
        <v-card-title class="text-h5 pl-0 grey--text">
          Remove Network Connection
        </v-card-title>
        <v-card-text class="black--text font-weight-medium pb-0">
          <div>Please confirm removing {{ selectedTableItem.serial || selectedTableItem.assetTag }} from the connections list</div>
          <div class="d-flex justify-center">
            <v-btn
              :loading="removingNetworkConnection"
              color="red darken-1 white--text"
              class="font-weight-bold ma-4"
              @click="removeNetworkConnection"
            >
              Remove
            </v-btn>
            <v-btn
              color="grey"
              class="font-weight-bold white--text ma-4"
              @click="showRemoveNetworkConnectionDialog = false"
            >
              Cancel
            </v-btn>
          </div>
        </v-card-text>
        <v-alert
          v-if="showRemoveNetworkConnectionAlert"
          type="error"
          class="mt-2 mb-0"
        >
          {{ removeNetworkConnectionAlertMessage }}
        </v-alert>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="selectedTableItem"
      v-model="showUpdateNetworkConnectionDialog"
      :persistent="updatingNetworkConnection"
      width="660"
    >
      <v-card class="pa-4 px-6">
        <v-card-title class="text-h5 pl-0 grey--text">
          Update Network Connection - {{ selectedTableItem.serial || selectedTableItem.assetTag }}
        </v-card-title>
        <v-card-text class="black--text font-weight-medium pb-0">
          <div>
            <div class="mb-2 font-weight-bold black--text">
              Assign the number of the ports connected -
              {{ networkEquipment.equipmentModelNetworkInformation.ports - totalPortsUsed }} available slots
            </div>
            <v-text-field
              v-model="stateNumberOfPorts"
              style="width: 400px;"
              class="mt-4"
              dense
              outlined
              label="Number Of Ports"
              type="number"
              :rules="[rules.required, rules.wholeNumber, rules.positiveNumber, rules.lessThanAvailablePorts]"
            />
          </div>
          <div class="d-flex justify-center">
            <v-btn
              :disabled="!stateNumberOfPorts || stateNumberOfPorts <= 0
                || stateNumberOfPorts > networkEquipment.equipmentModelNetworkInformation.ports - totalPortsUsed
                || stateNumberOfPorts % 1 !== 0
                || parseInt(stateNumberOfPorts) === initialStateNumberOfPorts"
              :loading="updatingNetworkConnection"
              color="red darken-1 white--text"
              class="font-weight-bold ma-4"
              @click="updateNetworkConnection"
            >
              Update
            </v-btn>
            <v-btn
              color="grey"
              class="font-weight-bold white--text ma-4"
              @click="showUpdateNetworkConnectionDialog = false"
            >
              Cancel
            </v-btn>
          </div>
        </v-card-text>
        <v-alert
          v-if="showUpdateNetworkConnectionAlert"
          type="error"
          class="mt-2 mb-0"
        >
          {{ updateNetworkConnectionAlertMessage }}
        </v-alert>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import networkApi from '@/api/network_ewc.js'
  import common from '@/api/common.js'

  export default {
    name: 'EwcNetworkRackConnections',
    props: {
      localBus: {
        type: Object,
      },
      networkEquipment: Object,
    },
    data: () => ({
      selectedTableItem: null,
      selectedTableItems: [],
      tableFieldModel: '',
      tableFieldLocation: '',
      tableFieldPortsUsed: '',
      tableFieldLabel: '',
      tableFieldSerial: '',
      tableFieldAssetTag: '',

      showAddConnectionDialog: false,
      loadingConnections: false,

      showRemoveNetworkConnectionDialog: false,
      removingNetworkConnection: false,
      showRemoveNetworkConnectionAlert: false,
      removeNetworkConnectionAlertMessage: '',
      alertMessageGeneral: 'We were unable to complete your request at this time. Please, try again later.',

      showUpdateNetworkConnectionDialog: false,
      updatingNetworkConnection: false,
      updateNetworkConnectionAlertMessage: '',
      showUpdateNetworkConnectionAlert: false,
      stateNumberOfPorts: 0,
      initialStateNumberOfPorts: 0,
    }),
    computed: {
      headers () {
        return [
          {
            text: 'Label',
            value: 'label',
            filter: value => {
              if (!this.tableFieldLabel) return true
              return value.toLowerCase().includes(this.tableFieldLabel)
            },
          },
          {
            text: 'Serial',
            value: 'serial',
            filter: value => {
              if (!this.tableFieldSerial) return true
              return value.toLowerCase().includes(this.tableFieldSerial)
            },
          },
          {
            text: 'Asset Tag',
            value: 'assetTag',
            filter: value => {
              if (!this.tableFieldAssetTag) return true
              return value.toLowerCase().includes(this.tableFieldAssetTag)
            },
          },
          {
            text: 'Equipment Model',
            value: 'model',
            filter: value => {
              if (!this.tableFieldModel) return true
              return (value).toLowerCase().includes(this.tableFieldModel)
            },
          },
          {
            text: 'Location',
            value: 'location',
            filter: value => {
              if (!this.tableFieldLocation) return true
              return value.toLowerCase().includes(this.tableFieldLocation)
            },
          },
          {
            text: 'Ports Used',
            value: 'portsUsed',
            filter: value => {
              if (!this.tableFieldPortsUsed) return true
              return value.toString().includes(this.tableFieldPortsUsed)
            },
          },
        ]
      },
      totalPorts () {
        if (!this.networkEquipment || !this.networkEquipment.connections) return 0
        let totalPorts = 0
        this.networkEquipment.connections.forEach(connection => {
          totalPorts += connection.portsUsed
        })
        return totalPorts
      },
      rules () {
        const rules = {}
        rules.required = (value) => !!value || 'Required.'
        rules.wholeNumber = (value) => value % 1 === 0 || 'Please enter a whole number'
        rules.positiveNumber = (value) => value > 0 || 'Please enter a positive number'
        rules.lessThanAvailablePorts = (value) => value <= this.networkEquipment.equipmentModelNetworkInformation.ports - this.totalPortsUsed ||
          'Select a number less than or equal to the available number of ports'
        return rules
      },
      totalPortsUsed () {
        if (!this.networkEquipment || !this.networkEquipment.connections) return 0
        let totalPorts = 0
        this.networkEquipment.connections.forEach(connection => {
          totalPorts += connection.portsUsed
        })
        return totalPorts
      },
    },
    created () {
      this.localBus.$on('add-network-connection-success', () => {
        this.loadingConnections = true
      })
    },

    methods: {
      removeNetworkConnection () {
        this.removingNetworkConnection = true
        this.showRemoveNetworkConnectionAlert = false
        networkApi.removeNetworkConnection(this.selectedTableItem.equipmentId, this.networkEquipment.equipmentId)
          .then(response => {
            console.log(response)
            this.loadingConnections = true
            this.localBus.$emit('remove-network-connection-success', this.networkEquipment)
            this.selectedTableItem = null
            this.selectedTableItems = []
          })
          .catch(async error => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              this.removeNetworkConnection()
            } else {
              this.showRemoveNetworkConnectionAlert = true
              this.removeNetworkConnectionAlertMessage = this.alertMessageGeneral
            }
          })
          .finally(() => { this.removingNetworkConnection = false })
      },
      updateNetworkConnection () {
        this.updatingNetworkConnection = true
        this.showUpdateNetworkConnectionAlert = false
        networkApi.upsertNetworkConnection({
          networkEquipmentId: this.networkEquipment.equipmentId,
          rackEquipmentId: this.selectedTableItem.equipmentId,
          portsConnected: this.stateNumberOfPorts,
          connectionId: this.selectedTableItem.connectionId,
        })
          .then(response => {
            console.log(response)
            this.selectedTableItem = null
            this.selectedTableItems = []
            this.stateNumberOfPorts = null
            this.localBus.$emit('update-network-connection-success', this.networkEquipment)
          })
          .catch(async error => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              this.updateNetworkConnection()
            }
            this.showUpdateNetworkConnectionAlert = true
            this.updateNetworkConnectionAlertMessage = this.alertMessageGeneral
          })
          .finally(() => { this.updatingNetworkConnection = false })
      },
    },
  }
</script>
<style scoped>
.hvr:hover {
  cursor: pointer;
}
</style>
