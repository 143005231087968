<template>
  <svg
    :key="updateSvg ? updateSvg : -1"
    ref="ewcEnclosure"
    height="100%"
    width="100%"
  >

    <template
      v-for="(position) in gridArray"
    >

      <ewc-enclosure-rectangle
        :key="`x:${position.x}y:${position.y}`"
        :position="position"
        :rectangle-width="uWidthPixels"
        :rectangle-height="uHeightPixels"
        :yOffsetPixels="yOffsetPixels"
        :xOffsetPixels="xOffsetPixels"
        :selectedItems="selectedItems"
        :local-bus="localBus"
        :selectable="setLocationDialog"
      />
    </template>
    <template
      v-for="(i) in SVGArray"
    >
      <line
        :key="'tick' + i"
        :x1="xOffsetPixels - 15"
        :y1="uHeightPixels * i"
        :x2="xOffsetPixels"
        :y2="uHeightPixels * i"
        stroke="rgb(207, 207, 207)"
      />
      <text
        :key="'label' + i"
        :x="0"
        :y="uHeightPixels * (SVGArray.length - 1 - i) + 5"
        style="text-align: right;"
      >
        {{ `${i &lt; 10 ? '&nbsp;' : ''} ${i} ` }}
      </text>
    </template>
    <template v-for="child in enclosureDataChildren">
      <ewc-enclosure-item
        :id="`enclosureItem-${child.equipmentData.id}`"
        :key="child.equipmentData.id"
        :enclosure-item="child"
        :rectangleWidth="uWidthPixels"
        :rectangleHeight="uHeightPixels"
        :xOffsetPixels="xOffsetPixels"
        :yOffsetPixels="yOffsetPixels"
        :localBus="localBus"
        :selectable="!setLocationDialog"
        :selected-item-id="selectedItemId"
      />
    </template>
    <template v-for="child in pendingChildren">
      <ewc-enclosure-item
        :id="`pendingEnclosureItem-${child.equipmentData.id}`"
        :key="child.equipmentData.id"
        :enclosure-item="child"
        :rectangleWidth="uWidthPixels"
        :rectangleHeight="uHeightPixels"
        :xOffsetPixels="xOffsetPixels"
        :yOffsetPixels="yOffsetPixels"
        :localBus="localBus"
        :selectable="!setLocationDialog"
        :selected-item-id="selectedItemId"
      />
    </template>
    <template v-for="child in auditChildren">
      <ewc-enclosure-item
        :id="`auditEnclosureItem-${child.equipmentData.id}`"
        :key="child.equipmentData.id"
        :enclosure-item="child"
        :rectangleWidth="uWidthPixels"
        :rectangleHeight="uHeightPixels"
        :xOffsetPixels="xOffsetPixels"
        :yOffsetPixels="yOffsetPixels"
        :localBus="localBus"
        :selectable="!setLocationDialog"
        :selected-item-id="selectedItemId"
      />
    </template>
  </svg>
</template>

<script>

  export default {
    name: 'EwcEnclosure',
    props: {
      localBus: {
        type: Object,
      },
      enclosureData: {
        type: Object,
        default: () => ({ equipmentModel: { capacity: 10 } }),
      },
      setLocationDialog: Boolean,
      updateSvg: Number,
      initialStateObject: Object,
      pendingEquipmentEwc: Array,
      auditEquipmentEwc: Array,
    },
    data: () => ({
      gridArray: [],
      SVGArray: [],
      xOffsetPixels: 35,
      yOffsetPixels: 0,

      selectedItems: [],
      selectedItemsInitialState: [],
      selectedItemId: -1,

      viewBoxW: 0,
      viewBoxH: 0,
    }),
    computed: {
      pendingChildren () {
        if (!this.pendingEquipmentEwc || !this.enclosureData.equipmentData?.id) return []

        return this.pendingEquipmentEwc.filter(
          pendingEquipmentItem => pendingEquipmentItem.location.parentEquipmentEwcId === this.enclosureData.equipmentData.id &&
          this.initialStateObject?.equipmentData.id !== pendingEquipmentItem.equipmentData.id,
        )
      },
      auditChildren () {
        if (!this.auditEquipmentEwc) return []
        // less than zero means it is a piece of audit equipment, assigning all of those negative values
        if (this.enclosureData.equipmentData.id < 0) {
          return this.auditEquipmentEwc.filter(
            auditEquipmentItem => auditEquipmentItem.location.parentAuditEquipmentId === -this.enclosureData.equipmentData.id &&
          this.initialStateObject?.equipmentData.id !== auditEquipmentItem.equipmentData.id)
        } else {
          return this.auditEquipmentEwc.filter(
            auditEquipmentItem => auditEquipmentItem.location.parentEquipmentEwcId === this.enclosureData.equipmentData.id &&
          this.initialStateObject?.equipmentData.id !== auditEquipmentItem.equipmentData.id)
        }
      },
      enclosureDataChildren () {
        if (!this.enclosureData.children) return []
        return this.enclosureData.children.filter(child => child.equipmentData.id !== this.initialStateObject?.equipmentData.id && child.equipmentData.id !== this.initialStateObject?.equipmentToEditId)
      },
      uHeightPixels () {
        if (!this.viewBoxH) return 0
        return this.$refs.ewcEnclosure ? this.$refs.ewcEnclosure.clientHeight / this.enclosureData.equipmentModel.modelRows : 0
      },
      uWidthPixels () {
        if (!this.viewBoxW) return 0
        return this.$refs.ewcEnclosure ? this.$refs.ewcEnclosure.clientWidth / this.enclosureData.equipmentModel.modelColumns : 0
      },
    },

    created () {
      for (let i = 0; i < this.enclosureData.equipmentModel.modelRows + 1; i++) {
        this.SVGArray.push(i)
      }
      let x = 0
      let y = 0
      for (let i = 0; i < this.enclosureData.equipmentModel.modelRows * this.enclosureData.equipmentModel.modelColumns; i++) {
        this.gridArray.push({ x, y })
        if (x < this.enclosureData.equipmentModel.modelColumns - 1) {
          x++
        } else {
          x = 0
          y++
        }
      }

      this.localBus.$on('onSelectEnclosureRectangle', uPosition => {
        if (this.selectedItems.some(item => item.x === uPosition.x && item.y === uPosition.y)) {
          this.selectedItems = this.selectedItems.filter(item => item.x !== uPosition.x || item.y !== uPosition.y)
        } else {
          this.selectedItems.push(uPosition)
        }

        this.localBus.$emit('selectedEnclosureItemsResponse', this.selectedItems)
      })

      this.localBus.$on('onSelectEnclosureItem', enclosureItem => {
        this.selectedItemId = enclosureItem.equipmentData.id
      })

      this.localBus.$on('openEnclosureSetLocationDialogInitialRectangles', selectedEnclosureItems => {
        this.selectedItems = [...selectedEnclosureItems]
        this.selectedItemId = -1
        this.localBus.$emit('selectedEnclosureItemsResponse', this.selectedItems)
      })

      this.localBus.$on('onSelectFloorItem', floorItem => {
        this.selectedItems = []
        this.selectedItemId = -1
      })
      this.localBus.$on('onSelectEnclosure', enclosure => {
        this.selectedItems = []
        this.selectedItemId = -1
        this.localBus.$emit('selectedEnclosureItemsResponse', this.selectedItems)
      })

      this.localBus.$on('clearSelectedItems', () => {
        this.selectedItems = []
        this.selectedItemId = -1
      })
      this.localBus.$on('clearSelectedSquaresEnclosure', () => {
        this.selectedItems = []
      })
      this.localBus.$on('clearSelectedEnclosureItem', () => {
        this.selectedItemId = -1
      })

      window.addEventListener('resize', () => this.setView())
    },
    mounted () {
      this.setView()
    },
    updated () {
      this.setView()
    },
    methods: {
      setView () {
        const vm = this
        if (!vm.$refs.ewcEnclosure?.clientHeight) return
        this.viewBoxH = vm.$refs.ewcEnclosure.clientHeight + 50
        this.viewBoxW = vm.$refs.ewcEnclosure.clientWidth
        const viewBoxX = 15
        const viewBoxY = -25

        this.$refs.ewcEnclosure.setAttribute('viewBox', `${viewBoxX} ${viewBoxY} ${this.viewBoxW} ${this.viewBoxH}`)
      },
    },
  }
</script>
