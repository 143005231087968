<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-card
      class="py-2 px-8 "
      style="width: 100%; height: 100%;"
    >
      <v-card-title class="text-h4 font-weight-bold grey--text">
        Upate Network Model Information: {{ networkModelInformation.model }}
      </v-card-title>
      <v-card-text>
        <div>{{ networkModelInformation.name }} </div>
        <v-img
          v-if="networkModelInformation.imagePath"
          :aspect-ratio="16/9"
          height="200"
          width="200"
          contain
          :src="networkModelInformation.imagePath"
        />
        <div
          v-else
          style="height: 200px; width: 200px;"
          class="d-flex justify-center align-center"
        >
          <v-icon x-large>
            {{ mdiImageOffOutline }}
          </v-icon>
        </div>
        <div>
          <div class="mb-2 font-weight-bold black--text">
            Assign the connection speed (GB/s):
          </div>
          <v-text-field
            v-model="stateConnectionSpeed"
            style="width: 400px;"
            class="mt-4"
            dense
            outlined
            label="Connection Speed"
            type="number"
            :rules="[rules.positiveNumber, rules.wholeNumber, rules.required]"
          />
        </div>
        <div>
          <div class="mb-2 font-weight-bold black--text">
            Assign the total number of ports:
          </div>
          <v-text-field
            v-model="stateNumberOfPorts"
            style="width: 400px;"
            class="mt-4"
            dense
            outlined
            label="Number of Ports"
            type="number"
            :rules="[rules.required]"
          />
        </div>
        <div class="d-flex justify-center">
          <v-btn
            :disabled="!stateNumberOfPorts || !stateConnectionSpeed"
            :loading="updatingNetworkModelInformation"
            color="green darken-1 white--text"
            class="font-weight-bold ma-4"
            @click="updateNetworkModel"
          >
            Update
          </v-btn>
          <v-btn
            color="grey"
            class="font-weight-bold white--text ma-4"
            @click="
              showUpdateModelSetupAlert = false
              localBus.$emit('close-update-network-model')
            "
          >
            Cancel
          </v-btn>
        </div>
      </v-card-text>
      <v-alert
        v-if="showUpdateNetworkModelInformationAlert"
        type="error"
        class="mt-2 mb-0"
      >
        {{ updateNetworkModelInformationAlertMessage }}
      </v-alert>
    </v-card>
  </v-container>
</template>

<script>

  import networkApi from '@/api/network_ewc.js'
  import common from '@/api/common.js'
  import { mdiImageOffOutline } from '@mdi/js'

  export default {
    name: 'EwcUpdateNetworkModelInformation',
    props: {
      localBus: {
        type: Object,
      },
      networkModelInformation: {
        type: Object,
        default: () => {},
      },
    },
    data: () => ({

      mdiImageOffOutline: mdiImageOffOutline,

      stateConnectionSpeed: '',
      stateNumberOfPorts: '',

      updatingNetworkModelInformation: false,
      showUpdateNetworkModelInformationAlert: false,
      updateNetworkModelInformationAlertMessage: '',
      alertMessageGeneral: 'We were unable to complete your request at this time. Please, try again later.',

    }),
    computed: {

      rules () {
        const rules = {}
        rules.required = (value) => !!value || 'Required.'
        rules.characterLimit = (value) => value.length < 255 || 'Please enter a shorter value'
        rules.wholeNumber = (value) => value % 1 === 0 || 'Please enter a whole number'
        rules.positiveNumber = (value) => value > 0 || 'Please enter a positive number'
        return rules
      },

    },
    created () {
      if (Object.keys(this.networkModelInformation).length > 0) {
        this.stateConnectionSpeed = this.networkModelInformation.speed
        this.stateNumberOfPorts = this.networkModelInformation.ports
      }
    },

    methods: {

      updateNetworkModel () {
        this.updatingNetworkModelInformation = true
        this.showUpdateNetworkModelInformationAlert = false
        networkApi.updateNetworkModel({
          equipmentModelId: this.networkModelInformation.modelId,
          ports: this.stateNumberOfPorts,
          speed: this.stateConnectionSpeed,
        })
          .then(response => {
            console.log(response)
            this.localBus.$emit('update-network-model-success')
          })
          .catch(async error => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              this.updateNetworkModel()
            } else {
              this.showUpdateNetworkModelInformationAlert = true
              this.updateNetworkModelInformationAlertMessage = this.alertMessageGeneral
            }
          })
          .finally(() => { this.updatingNetworkModelInformation = false })
      },
    },
  }
</script>
