<template>
  <div
    class="my-6 mx-6 px-16"
  >
    <div
      v-if="showTooltip"
      ref="svgTooltip"
      class="d-flex justify-center align-center pa-2"
      :style="`position: fixed; top: ${tooltipY}px; left: ${tooltipX}px;
            min-width: 100px; min-height: 50px; background: white; border-radius: 15px;
            opacity: .9; z-index: 1000;
          `"
    >
      <span>
        {{ tooltipText }}
      </span>
    </div>
    <v-form
      id="form"
      ref="form"
      v-model="isFormValid"
      onsubmit="return false;"
      @submit="showConfirmDialog = true"
    >
      <div>
        {{ editEquipment ? 'Equipment model' : 'Select a model from the table' }}
      </div>
      <v-data-table
        v-model="selectedTableItems"
        class="row-pointer"
        :headers="headers"
        single-select
        item-key="name"
        :loading="loadingTable"
        loading-text="Retrieving models"
        :items="models"
        :search="tableSearchValue"
        :fixed-header="true"
        @click:row="
          (item, row) => {
            row.select(true)
            selectedTableItem = item
            stateEquipmentLocationQuestion = ''
            stateStatus = ''
            resetSetLocationState()
            if(editEquipment && initialStateObject.children.length > 0){
              showChangeModelWarningDialog = true
            }
          }
        "
      >
        <template v-slot:[`body.prepend`]>
          <tr class="no-pointer">
            <td>
              <v-text-field
                v-model="tableFieldName"
                type="text"
                class="pt-4"
                outlined
                dense
                label="filter..."
              />
            </td>
            <td>
              <v-text-field
                v-model="tableFieldUSize"
                type="number"
                class="pt-4"
                outlined
                dense
              />
            </td>
            <td>
              <v-text-field
                v-model="tableFieldCapacity"
                type="number"
                class="pt-4"
                outlined
                dense
              />
            </td>
          </tr>
        </template>
        <template v-slot:[`item.imagePath`]="props">
          <td>
            <v-btn
              text
              color="green lighten-1"
              :disabled="props.item.imagePath ? false : true"
              @click.stop="() => {
                showImage = true
                imageSrc = props.item.imagePath
              }
              "
            >
              <v-icon>
                {{ mdiImage }}
              </v-icon>
            </v-btn>
          </td>
        </template>
      </v-data-table>
      <v-expand-transition>
        <div class="d-flex justify-space-between flex-wrap">
          <div
            v-if="selectedTableItem.name"
            style="width: 48%; min-width: 400px;"
            class="my-6 mx-0"
          >
            <div>
              Select the site where the equipment will be located:
            </div>
            <v-select
              v-model="stateSelectedSite"
              :items="sites"
              item-text="siteName"
              :loading="loadingSites"
              return-object
              dense
              flat
              @change=" (selection) => {
                stateEquipmentLocationQuestion = ''
                grids = selection.siteGrids
                resetSetLocationState()
                if(grids.length === 1){
                  stateSelectedFloor = grids[0]
                  getEquipmentEwcForSite(stateSelectedFloor.id)
                } else {
                  stateSelectedFloor = {}
                }
              }
              "
            />
          </div>
          <div
            v-if="selectedTableItem.name && Object.keys(stateSelectedSite).length > 0"
            style="width: 48%; min-width: 400px;"
            class="my-6 mx-0"
          >
            <div>
              Select which floorplan:
            </div>
            <v-select
              v-model="stateSelectedFloor"
              :items="grids"
              :disabled="loadingFloorplan"
              :loading="loadingFloorplan"
              item-text="name"
              return-object
              dense
              @change=" (selection) => {
                resetSetLocationState()
                getEquipmentEwcForSite(selection.id)
              }"
            />
          </div>
        </div>
      </v-expand-transition>
      <v-expand-transition>
        <div class="d-flex justify-space-between flex-wrap">
          <div
            v-if="selectedTableItem.name && Object.keys(stateSelectedFloor).length > 0"
            style="width: 48%; min-width: 400px;"
            class="my-6 mx-0"
          >
            <div>
              Select the current status of the equipment
            </div>
            <v-select
              id="addEquipmentCurrentStatusDropdown"
              v-model="stateStatus"
              :items="['Arrived', 'Deployed', 'Decommissioned', 'Disposed']"
              dense
              :rules="[rules.required]"
            />
          </div>
          <div
            v-if="stateStatus && stateStatus !== 'Disposed'"
            style="width: 48%; min-width: 400px;"
            class="my-6 mx-0"
          >
            <div>
              Does the equipment have a location on the floor?
            </div>
            <v-select
              id="addEquipmentLocationQuestionDropdown"
              v-model="stateEquipmentLocationQuestion"
              :items="['Yes', 'No']"
              dense
              :rules="[rules.required]"
              @change="selection => {
                if(selection === 'Yes'){
                  getEquipmentEwcForSite(stateSelectedFloor.id)
                } else {
                  resetSetLocationState()
                }
              }"
            />
          </div>
        </div>
      </v-expand-transition>
      <v-expand-transition>
        <div v-show="stateEquipmentLocationQuestion === 'Yes' && stateStatus !== 'Disposed'">
          <div
            class="d-flex align-center justify-center"
          >
            <v-btn
              v-show="stateEquipmentLocationQuestion === 'Yes'"
              id="openSetLocationDialogButton"
              class="font-weight-bold"
              color="primary"
              @click="openSetLocation"
            >
              {{ Object.keys(setLocationObject).length ? 'Update Location' : 'Set Location' }}
            </v-btn>
          </div>
          <v-row
            class="d-flex justify-center mt-2"
            style="height: 400px; width: 100%;"
          >
            <v-col
              class="pa-0"
              :cols="`${selectedTableItem.usize === 0 ? 12 : 8}`"
              style="height: 400px; width: 100%;"
            >
              <grid-wrapper-minimap
                :local-bus="localBus"
                :floorplan="floorplan"
                :loading-floorplan="loadingFloorplan"
                :initial-state-object="initialStateObject"
                set-location
                :pending-equipment-ewc="floorplan.pendingEquipmentEwc"
              />
            </v-col>
            <v-col
              v-show="stateEquipmentLocationQuestion === 'Yes' && selectedTableItem.usize !== 0 && Object.keys(setLocationObject).length > 0"
              class="pa-0"
              cols="4"
            >
              <v-row
                style="height: 100%"
              >
                <v-col
                  class="pt-6 pb-0 px-3"
                  cols="12"
                >
                  <v-card
                    class="my-0"
                    :style="`height: ${miniRackHeight}; width: 100%;`"
                  >
                    <ewc-rack-mini
                      :key="miniMapRack.equipmentData ? miniMapRack.equipmentData.id : ''"
                      :local-bus="localBus"
                      :rack-data="miniMapRack"
                      :initial-state-object="initialStateObject"
                      :pending-equipment-ewc="floorplan.pendingEquipmentEwc"
                    />
                  </v-card>
                </v-col>
                <v-col
                  v-if="!selectedTableItem.rackMounted && selectedTableItem.capacity === 0"
                  class="pa-3"
                  cols="12"
                >
                  <v-card
                    class="my-0"
                    :style="`height: ${miniEnclosureHeight}; width: 100%;`"
                  >
                    <ewc-enclosure-mini
                      :key="miniMapEnclosure.equipmentData ? miniMapEnclosure.equipmentData.id : ''"
                      :local-bus="localBus"
                      :enclosure-data="miniMapEnclosure"
                      :initial-state-object="initialStateObject"
                      :pending-equipment-ewc="floorplan.pendingEquipmentEwc"
                    />
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-expand-transition>
      <v-expand-transition>
        <div
          v-if="stateEquipmentLocationQuestion === 'No' || stateStatus === 'Disposed' || Object.keys(setLocationObject).length"
          class="my-4"
        >
          <div class="mb-3">
            Enter a value for at least one of the following:
          </div>
          <div class="d-flex flex-wrap">
            <v-text-field
              v-model="stateSerial"
              class="mx-3"
              width="45%"
              dense
              outlined
              label="Serial"
              type="text"
              :rules="[rules.characterLimit, rules.uniqueSerial]"
            />
            <v-text-field
              v-model="stateAssetTag"
              class="mx-3"
              width="45%"
              dense
              outlined
              label="Asset Tag"
              type="text"
              :rules="[rules.characterLimit, rules.uniqueAssetTag]"
            />
          </div>
        </div>
      </v-expand-transition>
      <v-expand-transition>
        <div
          v-if="(stateSerial || stateAssetTag) && (stateEquipmentLocationQuestion === 'No' || stateStatus === 'Disposed' || Object.keys(setLocationObject).length > 0)"
          class="my-4"
        >
          <div class="mb-3">
            Enter a title to identify this request:
          </div>
          <v-text-field
            v-model="stateTitle"
            class="mx-3"
            width="45%"
            dense
            outlined
            label="Title"
            type="text"
            :rules="[rules.characterLimit, rules.required]"
          />
        </div>
      </v-expand-transition>
      <v-expand-transition>
        <div
          v-if="stateTitle && (stateSerial || stateAssetTag) && (stateEquipmentLocationQuestion === 'No' || stateStatus === 'Disposed' || Object.keys(setLocationObject).length > 0)"
        >
          <div class="mb-3">
            Enter in any optional information:
          </div>
          <div class="d-flex flex-wrap">
            <v-text-field
              v-model="stateLabel"
              class="mx-3"
              style="width: 25%"
              dense
              outlined
              label="Label"
              type="text"
              :rules="[rules.characterLimit]"
            />
            <v-text-field
              v-model="stateAssetId"
              style="width: 25%"
              class="mx-3"
              dense
              outlined
              label="Asset Id"
              type="text"
              :rules="[rules.characterLimit]"
            />
            <v-text-field
              v-model="stateDepartmentNumber"
              class="mx-3 wizard-equipment"
              style="width: 25%"
              dense
              outlined
              label="Department Number"
              type="text"
              :rules="[rules.characterLimit]"
            />
          </div>
          <div class="d-flex flex-wrap">
            <v-text-field
              v-model="stateProjectName"
              class="mx-3 wizard-equipment"
              dense
              outlined
              label="Project Name"
              type="text"
              :rules="[rules.characterLimit]"
            />
            <v-text-field
              v-model="stateProjectNumber"
              class="mx-3 wizard-equipment"
              dense
              outlined
              label="Project Number"
              type="text"
              :rules="[rules.characterLimit]"
            />
          </div>
          <div class="d-flex">
            <v-textarea
              v-model="stateDescription"
              class="mx-3"
              auto-grow
              rows="3"
              dense
              outlined
              label="Description"
              type="text"
              :rules="[rules.characterLimitLong]"
            />
            <v-textarea
              v-model="stateNotes"
              class="mx-3"
              auto-grow
              rows="3"
              dense
              outlined
              label="Notes"
              type="text"
              :rules="[rules.characterLimitLong]"
            />
          </div>
          <div
            v-if="!editEquipment"
            style="height: 24px;"
            class="d-flex justify-center mt-0 mb-2"
          >
            <v-checkbox
              v-model="saveOptionalAnswers"
              label="Save optional fields"
              dense
            />
          </div>
        </div>
      </v-expand-transition>
      <v-expand-transition>
        <div
          v-if="editEquipment && stateTitle && (stateSerial || stateAssetTag) && (stateEquipmentLocationQuestion === 'No' || stateStatus === 'Disposed' || Object.keys(setLocationObject).length > 0)"
          class="my-4"
        >
          <div class="mb-3">
            Check that the following dates are correct:
          </div>
          <div class="mb-3 ml-3 d-flex">
            <v-menu
              ref="acquisitionDateMenu"
              v-model="acquisitionDateMenu"
              :close-on-content-click="false"
              :return-value.sync="acquisitionDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="acquisitionDateFormatted"
                  label="Acquisition Date"
                  prepend-icon="mdi-calendar"
                  class="mx-1"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-btn
                    v-if="acquisitionDate"
                    slot="append"
                    x-small
                    style="margin-top: 3px;"
                    text
                    @click="acquisitionDate = ''"
                  >
                    <v-icon size="small">
                      {{ mdiClose }}
                    </v-icon>
                  </v-btn>
                </v-text-field>
              </template>
              <v-date-picker
                v-model="acquisitionDate"
                no-title
                scrollable
              >
                <v-spacer />
                <v-btn
                  text
                  class="font-weight-bold"
                  color="primary"
                  @click="acquisitionDateMenu = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  class="font-weight-bold"
                  color="primary"
                  @click="$refs.acquisitionDateMenu.save(acquisitionDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
            <v-menu
              ref="decommissionDateMenu"
              v-model="decommissionDateMenu"
              :close-on-content-click="false"
              :return-value.sync="decommissionDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="decommissionDateFormatted"
                  label="Decommission Date"
                  prepend-icon="mdi-calendar"
                  class="mx-1"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-btn
                    v-if="decommissionDate"
                    slot="append"
                    x-small
                    style="margin-top: 3px;"
                    text
                    @click="decommissionDate = ''"
                  >
                    <v-icon size="small">
                      {{ mdiClose }}
                    </v-icon>
                  </v-btn>
                </v-text-field>
              </template>
              <v-date-picker
                v-model="decommissionDate"
                no-title
                scrollable
              >
                <v-spacer />
                <v-btn
                  text
                  class="font-weight-bold"
                  color="primary"
                  @click="decommissionDateMenu = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  class="font-weight-bold"
                  color="primary"
                  @click="$refs.decommissionDateMenu.save(decommissionDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
            <v-menu
              ref="disposalDateMenu"
              v-model="disposalDateMenu"
              :close-on-content-click="false"
              :return-value.sync="disposalDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="disposalDateFormatted"
                  class="mx-1"
                  label="Disposal Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-btn
                    v-if="disposalDate"
                    slot="append"
                    x-small
                    style="margin-top: 3px;"
                    text
                    @click="disposalDate = ''"
                  >
                    <v-icon size="small">
                      {{ mdiClose }}
                    </v-icon>
                  </v-btn>
                </v-text-field>
              </template>
              <v-date-picker
                v-model="disposalDate"
                no-title
                scrollable
              >
                <v-spacer />
                <v-btn
                  text
                  class="font-weight-bold"
                  color="primary"
                  @click="disposalDateMenu = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  class="font-weight-bold"
                  color="primary"
                  @click="$refs.disposalDateMenu.save(disposalDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </div>
        </div>
      </v-expand-transition>
      <v-card-actions class="justify-center">
        <v-btn
          v-if="(stateEquipmentLocationQuestion === 'No' || stateStatus === 'Disposed') && (stateSerial || stateAssetTag)
            || (stateSerial || stateAssetTag) && Object.keys(setLocationObject).length"
          :disabled="!isFormValid "
          :loading="addingEquipment"
          color="green darken-1"
          text
          class="font-weight-bold"
          width="25%"
          height="56px"
          style="font-size: 14px"
          @click="addEquipment"
        >
          {{ initialStateObject ? editEquipment ? 'Submit Edit Request' : 'Edit Pending Request' : 'Submit Request' }}
        </v-btn>
      </v-card-actions>
    </v-form>
    <v-dialog
      v-model="showImage"
      max-width="500"
    >
      <v-sheet
        class="d-flex justify-center pa-4"
        width="500"
        height="500"
      >
        <v-img
          :src="imageSrc"
          max-width="475"
          max-height="475"
          contain
        />
      </v-sheet>
    </v-dialog>
    <v-dialog
      v-model="setLocationDialog"
      fullscreen
      style="position: relative"
      @keydown.esc="(e) => {
        e.preventDefault()
        setLocationDialog = false
        selectedFloorItem = initialSelectedFloorItem
        floorSelectionType = initialFloorSelectionType
        tempSelectedEnclosure = selectedEnclosure
        $nextTick(() => {
          localBus.$emit('cancelLocationDialog')
        })
      }"
    >
      <v-sheet
        style="min-height: 100vh; overflow: hidden; background: #EEEEEE"
        class="d-flex align-center justify-center flex-wrap"
      >
        <v-btn
          color="grey darken-1"
          class="ma-6"
          text
          style="position: absolute; z-index: 2; top: 10px; left: 10px;"
          @click="() => {
            setLocationDialog = false
            selectedFloorItem = initialSelectedFloorItem
            floorSelectionType = initialFloorSelectionType
            tempSelectedEnclosure = selectedEnclosure
            $nextTick(() => {
              localBus.$emit('cancelLocationDialog')
            })
          }"
        >
          <v-icon>
            {{ mdiCloseThick }}
          </v-icon>
        </v-btn>
        <v-row
          class="d-flex justify-center"
          style="width: 100%"
        >
          <v-col
            v-if="selectedTableItem.usize === 0"
            cols="10"
            class="mt-2 pb-0"
          >
            <v-toolbar
              dense
              class="mx-3 my-0"
            >
              <v-spacer />
              <v-tooltip
                bottom
                open-delay="1000"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    class="text--white ma-1"
                    :text="floorSelectionType !== 'whole'"
                    v-bind="attrs"
                    v-on="on"
                    @click="floorSelectionType = 'whole'"
                  >
                    <v-icon>
                      {{ mdiBorderOutside }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Select whole grid space</span>
              </v-tooltip>
              <v-tooltip
                bottom
                open-delay="1000"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :color="floorSelectionType === 'whole' ? 'primary' : 'secondary'"
                    class="ma-1"
                    :text="floorSelectionType === 'whole'"
                    v-bind="attrs"
                    v-on="on"
                    @click="latestHalfSelection === 'half-up-down' ? floorSelectionType = 'half-up-down' : floorSelectionType = 'half-left-right' "
                  >
                    <v-icon>
                      {{ mdiFlipHorizontal }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Select half grid space</span>
              </v-tooltip>
              <v-tooltip
                bottom
                open-delay="1000"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="secondary"
                    :text="floorSelectionType != 'half-left-right'"
                    class="text--white ma-1"
                    v-bind="attrs"
                    :disabled="floorSelectionType === 'whole'"
                    v-on="on"
                    @click="floorSelectionType = 'half-left-right'"
                  >
                    <v-icon>
                      {{ mdiArrowLeftRight }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Horizontal half grid orientation</span>
              </v-tooltip>
              <v-tooltip
                bottom
                open-delay="1000"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="secondary"
                    :text="floorSelectionType != 'half-up-down'"
                    class="text--white ma-1"
                    v-bind="attrs"
                    :disabled="floorSelectionType === 'whole'"
                    v-on="on"
                    @click="floorSelectionType = 'half-up-down'"
                  >
                    <v-icon>
                      {{ mdiArrowUpDown }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Vertical half grid orientation</span>
              </v-tooltip>
            </v-toolbar>
          </v-col>
          <v-col
            v-if="stateEquipmentLocationQuestion === 'Yes'"
            cols="12"
            :lg="selectedTableItem.usize === 0 ? 10 : 8"
          >
            <div :style="`height: ${selectedTableItem.usize === 0 ? '650px' : '800px' }; width: 100%;`">
              <grid-wrapper
                :key="selectedTableItem.id"
                :local-bus="localBus"
                :floorplan="floorplan"
                :loading-floorplan="loadingFloorplan"
                set-location-dialog
                :floor-selection-type="floorSelectionType"
                :latest-half-selection="latestHalfSelection"
                :set-floor-location="selectedTableItem.usize === 0"
                :set-enclosure-item-location="selectedTableItem.usize > 0 && !selectedTableItem.rackMounted"
                :initial-state-object="initialStateObject"
                :pending-equipment-ewc="floorplan.pendingEquipmentEwc"
              />
            </div>
          </v-col>
          <v-col
            v-if="selectedTableItem.usize !== 0"
            class="py-6 pl-0 pr-6"
            cols="12"
            lg="4"
          >
            <v-row>
              <v-col
                class="pa-0"
                cols="12"
              >
                <div :style="`height: ${infoCardHeight}; width: 100%;`">
                  <ewc-information-card
                    :local-bus="localBus"
                    set-location-dialog
                    :set-rack-location="selectedTableItem.rackMounted || (selectedTableItem.usize > 0 && selectedTableItem.capacity > 0)"
                    :set-enclosure-location="!(selectedTableItem.rackMounted || (selectedTableItem.usize > 0 && selectedTableItem.capacity > 0))"
                    :initial-state-object="initialStateObject"
                    :pending-equipment-ewc="floorplan.pendingEquipmentEwc"
                  />
                </div>
              </v-col>

              <v-col
                v-show="Object.keys(tempSelectedEnclosure).length > 0"
                class="pa-0"
                cols="12"
              >
                <div :style="`height: ${enclosureCardHeight}; width: 100%;`">
                  <ewc-enclosure-card
                    :local-bus="localBus"
                    set-location-dialog
                    :initial-state-object="initialStateObject"
                    :pending-equipment-ewc="floorplan.pendingEquipmentEwc"
                  />
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <div
          style="width: 80%"
          class="d-flex justify-center align-center mb-8"
        >
          <div
            class="mr-6 pa-4"
            :style="`border: solid 2px; border-radius: 5px; border-color: ${setLocationButtonEnabled ? 'green' : 'grey'};
             background-color: ${setLocationButtonEnabled ? 'rgba(158, 255, 181, 0.2)' : 'rgba(163, 163, 163, 0.2)'};
             transition: .5s`"
          >
            <template v-if="selectedTableItem.usize === 0 || !(selectedTableItem.rackMounted || (selectedTableItem.usize > 0 && selectedTableItem.capacity > 0))">
              <div
                class="mb-1"
              >
                Grid selections must:
              </div>
              <ul>
                <li
                  style="transition: .5s"
                  :class="`${allSquaresAdjacent ? 'green--text text--darken-3' : `${noSquaresSelected ? '' : 'red--text text--darken-3'}`}`"
                >
                  Be adjacent to each other
                </li>
                <li
                  style="transition: .5s"
                  :class="`${squareOrRectangle ? 'green--text text--darken-3' : `${noSquaresSelected ? '' : 'red--text text--darken-3'}`}`"
                >
                  Form a solid square or rectanglular shape
                </li>
                <li
                  v-if="selectedTableItem.usize > 0 && selectedTableItem.capacity === 0"
                  style="transition: .5s"
                  :class="`${usizeSelected ? 'green--text text--darken-3' : `${noSquaresSelected ? '' : 'red--text text--darken-3'}`}`"
                >
                  {{ `Include ${selectedTableItem.usize} spaces` }}
                </li>
              </ul>
              <div
                v-if="selectedTableItem.usize === 0"
                class="mt-1"
              >
                Rack Location: {{ tempRackLocation }}
              </div>
              <div
                v-else
                class="mt-1"
              >
                Bay Location: {{ tempSelectedItemsMinimap.length > 0 ? [...tempSelectedItemsMinimap].map((item) => calculateBay(item)).sort((a,b) => a-b) : '' }}
              </div>
            </template>
            <template v-else>
              <div
                class="mb-1"
              >
                Rack selections must:
              </div>
              <ul>
                <li
                  style="transition: .5s"
                  :class="`${allRectanglesAdjacent ? 'green--text text--darken-3' : `${noRectanglesSelected ? '' : 'red--text text--darken-3'}`}`"
                >
                  Be adjacent to each other
                </li>
                <li
                  style="transition: .5s"
                  :class="`${usizeSelected ? 'green--text text--darken-3' : `${noRectanglesSelected ? '' : 'red--text text--darken-3'}`}`"
                >
                  {{ `Include ${selectedTableItem.usize} spaces` }}
                </li>
              </ul>
              <div class="mt-1">
                Unit Location: {{ tempSelectedRackRectangles.length > 0 ? [...tempSelectedRackRectangles].sort((a,b) => a-b) : '' }}
              </div>
            </template>
          </div>
          <div
            class="d-flex flex-column justify-space-between"
          >
            <v-btn
              id="confirmSetLocationButton"
              color="green lighten-1"
              class="white--text mb-4 font-weight-bold"
              :disabled="!setLocationButtonEnabled"
              @click="selectedTableItem.usize === 0 ? setLocationFloor() : selectedTableItem.rackMounted || (selectedTableItem.usize > 0 && selectedTableItem.capacity > 0) ? setLocationRack() : setLocationEnclosure()"
            >
              Set Location
            </v-btn>

            <v-btn
              id="confirmSetLocationButton"
              color="grey darken-1"
              class="white--text font-weight-bold"
              :disabled="noSelectedItems"
              @click="clearLatestSelections"
            >
              Clear Latest Selections
            </v-btn>
          </div>
        </div>
      </v-sheet>
    </v-dialog>
    <v-dialog
      v-model="showChangeModelWarningDialog"
      max-width="400"
    >
      <v-card class="py-4 pl-6">
        <v-card-title class="text-h5 pl-0">
          Warning:
        </v-card-title>
        <div
          class="d-flex pr-6"
        >
          Changing the equpiment model will remove any items located inside. They will still exist in the system and be visible in the main ewc list view but will
          need to be added to a piece of equipment to be seen in the grid view.
        </div>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="green darken-1"
            text
            @click="showChangeModelWarningDialog = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <material-snackbar
      v-model="showSnackBar"
      :type="snackBarType"
      timeout="-1"
      class="ml-12"
      v-bind="{
        ['bottom']: true,
        ['left']: true
      }"
    >
      {{ snackBarMessage }}
      <v-btn
        v-if="snackBarType === 'info' && snackBarMessage === snackBarMessageUpdateFloorplan"
        color="white"
        class="cyan--text font-weight-bold"
        height="25"
        @click="
          showSnackBar = false
          refreshEquipment()
        "
      >
        Refresh?
      </v-btn>
    </material-snackbar>
  </div>
</template>

<script>
  import equipmentApi from '@/api/equipment.js'
  import ewcApi from '@/api/ewc.js'
  import common from '@/api/common.js'
  import ewcHolder from '@/util/ewc-holder'
  import moment from 'moment'
  import { mdiImage, mdiClose, mdiCloseThick, mdiBorderOutside, mdiFlipHorizontal, mdiArrowLeftRight, mdiArrowUpDown } from '@mdi/js'
  import socketApi from '@/api/websockets.js'
  import socketUtil from '@/util/websockets.js'

  export default {
    name: 'EwcEquipmentFlow',
    props: {
      localBus: Object,
      initialStateObject: Object,
      editEquipment: Boolean,
    },
    data: () => ({
      mdiImage: mdiImage,
      mdiClose: mdiClose,
      mdiCloseThick: mdiCloseThick,
      mdiBorderOutside: mdiBorderOutside,
      mdiFlipHorizontal: mdiFlipHorizontal,
      mdiArrowLeftRight: mdiArrowLeftRight,
      mdiArrowUpDown: mdiArrowUpDown,

      isFormValid: false,
      tableFieldName: '',
      tableFieldUSize: '',
      tableFieldCapacity: '',
      tableSearchValue: '',
      loadingTable: false,
      models: [],
      selectedTableItem: {},
      selectedTableItems: [],
      imageSrc: '',
      showImage: false,

      sites: [],
      loadingSites: false,
      stateSelectedSite: {},
      grids: [],
      stateSelectedFloor: {},

      floorplan: {},
      loadingFloorplan: false,

      stateEquipmentLocationQuestion: '',
      stateSerial: '',
      stateAssetTag: '',
      stateStatus: '',
      stateLabel: '',
      stateDescription: '',
      stateNotes: '',
      stateAssetId: '',
      stateProjectName: '',
      stateProjectNumber: '',
      stateDepartmentNumber: '',
      addingEquipment: false,
      uniqueSerialArray: [],
      uniqueAssetTagArray: [],
      stateTitle: '',

      setLocationDialog: false,
      tempLocationObject: {},
      setLocationObject: {},
      tempSelectedItemsMinimap: [],
      selectedFloorRectangles: [],
      noSquaresSelected: true,
      allSquaresAdjacent: false,
      squareOrRectangle: false,

      allRectanglesAdjacent: false,
      noRectanglesSelected: true,
      usizeSelected: false,
      tempSelectedRackRectangles: [],
      selectedRackRectangles: [],
      initialSelectedFloorItem: {},
      selectedFloorItem: {},
      miniMapRack: { equipmentModel: { capacity: 42 } },
      tempRackLocation: '',
      rackLocation: '',

      initialSelectedEnclosure: {},
      selectedEnclosure: {},
      tempSelectedEnclosure: {},
      tempSelectedEnlosureRectangles: [],
      selectedEnclosureRectangles: [],
      miniMapEnclosure: {},

      tempSelectedPartialItemsMinimap: [],
      selectedPartialRectangles: [],
      floorSelectionType: 'whole',
      initialFloorSelectionType: '',
      latestHalfSelection: '',

      alertMessage: '',
      alertMessageDataCenters: 'There was an error loading Data Centers. Please try again later.',
      alertMessageGeneral: 'We are unable to process your request at this time. Please try again later.',
      alertMessageUniqueSerial: 'An equipment item with that serial already exists',
      alertMessageUniqueAssetTag: 'An equipment item with that asset tag already exists',
      snackBarMessageAddEquipmentSuccess: 'Successfully submitted request!',
      snackBarMessagePendingEditSuccess: 'Successfully edited pending request!',
      snackBarMessageRequestEditEquipmentSuccess: 'Successfully submitted equipment edit request!',
      snackBarMessageUpdateFloorplan: 'The currently selected equipment floorplan has been updated:',
      showSnackBar: false,
      snackBarMessage: '',
      snackBarType: 'info',

      showTooltip: false,
      tooltipY: 0,
      tooltipX: 0,
      tooltipText: '',

      acquisitionDateMenu: false,
      acquisitionDate: '',
      decommissionDateMenu: false,
      decommissionDate: '',
      disposalDateMenu: false,
      disposalDate: '',

      saveOptionalAnswers: false,

      showChangeModelWarningDialog: false,
    }),
    computed: {
      headers () {
        return [
          {
            text: 'Equipment Name',
            align: 'left',
            value: 'name',
            filter: value => {
              if (!this.tableFieldName) return true
              return (value).toLowerCase().includes(this.tableFieldName.toLowerCase())
            },
          },
          {
            text: 'U Size',
            value: 'usize',
            filter: value => {
              if (!this.tableFieldUSize) return true
              return value.toString().includes(this.tableFieldUSize)
            },
          },
          {
            text: 'Capacity',
            value: 'capacity',
            filter: value => {
              if (!this.tableFieldCapacity) return true
              return value.toString().includes(this.tableFieldCapacity)
            },
          },
          {
            text: 'Image',
            value: 'imagePath',
          },
        ]
      },

      rules () {
        const serialArray = this.uniqueSerialArray
        const assetTagArray = this.uniqueAssetTagArray

        const rules = {}
        rules.required = (value) => !!value || 'Required.'
        rules.characterLimit = (value) => value.length < 255 || 'Please enter a shorter value'
        rules.characterLimitLong = (value) => value.length < 2000 || 'Please enter a shorter value'
        rules.uniqueSerial = (value) => !serialArray.includes(value) || 'Please enter a unique value'
        rules.uniqueAssetTag = (value) => !assetTagArray.includes(value) || 'Please enter a unique value'
        return rules
      },

      setLocationButtonEnabled () {
        return (this.squareOrRectangle && this.selectedTableItem.usize === 0) || (this.usizeSelected && this.allRectanglesAdjacent) || (this.squareOrRectangle && this.usizeSelected && this.selectedTableItem.usize > 0)
      },

      miniRackHeight () {
        if (this.selectedTableItem.rackMounted || (this.selectedTableItem.usize > 0 && this.selectedTableItem.capacity > 0)) {
          return '100%'
        } else {
          if (this.selectedEnclosure?.equipmentModel?.modelRows < 4) {
            return `${364 - this.tempSelectedEnclosure.equipmentModel?.modelRows * 45}px`
          } else {
            return '182px'
          }
        }
      },
      miniEnclosureHeight () {
        if (this.selectedTableItem.rackMounted || (this.selectedTableItem.usize > 0 && this.selectedTableItem.capacity > 0)) return '0px'
        if (this.selectedEnclosure?.equipmentModel?.modelRows < 4) {
          return `${this.tempSelectedEnclosure.equipmentModel?.modelRows * 45}px`
        } else {
          return '182px'
        }
      },
      infoCardHeight () {
        if (Object.keys(this.tempSelectedEnclosure).length === 0) {
          return '800px'
        } else {
          if (this.tempSelectedEnclosure.equipmentModel?.modelRows < 4) {
            return `${800 - this.tempSelectedEnclosure.equipmentModel?.modelRows * 120}px`
          } else {
            return '400px'
          }
        }
      },
      enclosureCardHeight () {
        if (Object.keys(this.tempSelectedEnclosure).length === 0) return '0px'
        if (this.tempSelectedEnclosure.equipmentModel?.modelRows < 4) {
          return `${this.tempSelectedEnclosure.equipmentModel?.modelRows * 120}px`
        } else {
          return '400px'
        }
      },

      acquisitionDateFormatted () {
        return this.acquisitionDate ? moment(this.acquisitionDate).format('YYYY/MM/DD') : null
      },
      decommissionDateFormatted () {
        return this.decommissionDate ? moment(this.decommissionDate).format('YYYY/MM/DD') : null
      },
      disposalDateFormatted () {
        return this.disposalDate ? moment(this.disposalDate).format('YYYY/MM/DD') : null
      },

      noSelectedItems () {
        if (this.selectedTableItem.usize === 0) {
          return this.noSquaresSelected
        } else {
          return Object.keys(this.selectedFloorItem).length === 0
        }
      },
    },

    watch: {
      rules: function () {
        setTimeout(() => {
          this.$refs.form.validate()
        }, 10)
      },
      floorSelectionType (newSelection, oldSelection) {
        if (newSelection === 'whole') {
          this.latestHalfSelection = oldSelection
        } else {
          this.latestHalfSelection = newSelection
        }
      },
      initialStateObject (newState, oldState) {
        if (!newState) return
        this.setState()
      },
      stateEquipmentLocationQuestion (newState, oldState) {
        if (newState === 'No') {
          if (this.editEquipment) {
            this.stateTitle = `Edit ${this.stateLabel ? this.stateLabel : this.selectedTableItem.name} in ${this.stateSelectedFloor.name}`
          } else {
            this.stateTitle = `Add ${this.stateLabel ? this.stateLabel : this.selectedTableItem.name} to ${this.stateSelectedFloor.name}`
          }
        }
      },
      rackLocation (newRackLocation, oldRackLocation) {
        if (this.selectedTableItem.usize === 0) {
          this.stateTitle = `${this.editEquipment ? 'Edit' : 'Add'} ${this.stateLabel ? this.stateLabel : this.selectedTableItem.name} at ${newRackLocation}`
        }
      },
      setLocationObject (newSetLocationObject, oldSetLocationObject) {
        if (!this.selectedFloorItem.equipmentData) return
        if ((Object.keys(newSetLocationObject).length > 0)) {
          if ((this.selectedTableItem.rackMounted || (this.selectedTableItem.usize > 0 && this.selectedTableItem.capacity > 0))) {
            this.stateTitle = `${this.editEquipment ? 'Edit' : 'Add'} ${this.stateLabel ? this.stateLabel : this.selectedTableItem.name} ${this.editEquipment ? 'at' : 'to'} rack ${this.selectedFloorItem.equipmentData.rackLocation} in unit location ${newSetLocationObject.uPosition}`
          } else {
            this.stateTitle = `${this.editEquipment ? 'Edit' : 'Add'} ${this.stateLabel ? this.stateLabel : this.selectedTableItem.name} ${this.editEquipment ? 'at' : 'to'} rack ${this.selectedFloorItem.equipmentData.rackLocation} in unit location ${this.selectedEnclosure.location.gridY} bay ${this.calculateBay({ x: newSetLocationObject.location.x, y: newSetLocationObject.location.y })}`
          }
        }
      },
      stateStatus (newStatus, oldStatus) {
        if (newStatus === 'Disposed') {
          this.stateTitle = `Dispose ${this.selectedTableItem.name}`
        }
      },
      floorplan (newFloorplan, oldFloorplan) {
        this.$emit('update-pending-requests-count', newFloorplan.pendingEquipmentEwc.length)
      },
    },

    created () {
      this.getModels()
      this.getDataCentersForUser()
      this.getSocketNotificationUrl()

      const latestSelection = ewcHolder.getLatestSiteAndFloorSelection()
      if (latestSelection) {
        this.stateSelectedSite = latestSelection.site
        this.grids = latestSelection.site.siteGrids
        this.stateSelectedFloor = latestSelection.floor
      }

      this.localBus.$on('selectedItemsResponse', (selectedItems, selectedPartialItems) => {
        if (selectedItems.length === 0 && selectedPartialItems?.length === 0) {
          this.tempRackLocation = ''
        } else if (selectedItems.length === 1 && selectedPartialItems.length === 0) {
          this.tempRackLocation = this.calculateRackLocation({ gridY: selectedItems[0].y, gridX: selectedItems[0].x })
        } else if (selectedItems.length === 0 && selectedPartialItems.length === 1) {
          this.tempRackLocation = this.calculateRackLocation({ gridY: Math.floor(selectedPartialItems[0].y), gridX: Math.floor(selectedPartialItems[0].x) })
        } else if (selectedItems[0] && this.tempSelectedItemsMinimap[0] !== selectedItems[0]) {
          if (this.tempSelectedItemsMinimap[0] === selectedPartialItems[0]) {
            this.tempRackLocation = this.calculateRackLocation({ gridY: selectedPartialItems[0].y, gridX: selectedPartialItems[0].x })
          } else {
            this.tempRackLocation = this.calculateRackLocation({ gridY: selectedItems[0].y, gridX: selectedItems[0].x })
          }
        } else if (selectedItems.length === 0 && selectedPartialItems[0] && this.tempSelectedPartialItemsMinimap[0] !== selectedPartialItems[0]) {
          this.tempRackLocation = this.calculateRackLocation({ gridY: Math.floor(selectedPartialItems[0].y), gridX: Math.floor(selectedPartialItems[0].x) })
        }

        this.tempSelectedItemsMinimap = selectedItems
        this.tempSelectedPartialItemsMinimap = selectedPartialItems
        selectedPartialItems?.length > 0 ? this.checkContiguousWithPartials(selectedItems, selectedPartialItems) : this.checkContiguousSquare(selectedItems)
      })

      this.localBus.$on('selectedRackItemsResponse', selectedItems => {
        this.tempSelectedRackRectangles = [...selectedItems]

        if (selectedItems.length === 0) {
          this.allRectanglesAdjacent = false
          this.usizeSelected = false
          this.noRectanglesSelected = true
          return
        } else {
          this.noRectanglesSelected = false
        }

        this.allRectanglesAdjacent = true
        const sortedItems = selectedItems.sort((a, b) => a - b)
        for (let i = 0; i < sortedItems.length - 1; i++) {
          if (sortedItems[i] + 1 !== sortedItems[i + 1]) {
            this.allRectanglesAdjacent = false
          }
        }

        if (selectedItems.length === this.selectedTableItem.usize) {
          this.usizeSelected = true
        } else {
          this.usizeSelected = false
        }
      })

      this.localBus.$on('selectedEnclosureItemsResponse', selectedItems => {
        this.tempSelectedItemsMinimap = selectedItems
        this.usizeSelected = selectedItems.length === this.selectedTableItem.usize
        this.checkContiguousSquare(selectedItems)
      })

      this.localBus.$on('onSelectFloorItem', (floorItem) => {
        this.selectedFloorItem = floorItem
        this.tempSelectedEnclosure = {}
        this.noRectanglesSelected = true
        this.allRectanglesAdjacent = false
        this.noSquaresSelected = true
        this.allSquaresAdjacent = false
        this.squareOrRectangle = false
        this.usizeSelected = false
      })
      this.localBus.$on('clearSelectedFloorItem', () => {
        this.selectedFloorItem = {}
        this.tempSelectedEnlosureRectangles = []
        this.tempSelectedItemsMinimap = []
        this.tempSelectedRackRectangles = []
        this.tempSelectedEnclosure = {}
        this.noRectanglesSelected = true
        this.allRectanglesAdjacent = false
        this.noSquaresSelected = true
        this.allSquaresAdjacent = false
        this.squareOrRectangle = false
        this.usizeSelected = false
      })

      this.localBus.$on('onSelectEnclosure', enclosure => {
        this.tempSelectedEnclosure = enclosure
        this.noRectanglesSelected = true
        this.allSquaresAdjacent = false
        this.usizeSelected = false
      })
      this.localBus.$on('clearSelectedRackItem', () => {
        this.tempSelectedEnlosureRectangles = []
        this.tempSelectedItemsMinimap = []
        this.tempSelectedEnclosure = {}
        this.noSquaresSelected = true
        this.allSquaresAdjacent = false
        this.squareOrRectangle = false
        this.usizeSelected = false
      })

      this.localBus.$on('display-tooltip', ({ e, label }) => {
        this.showTooltip = true
        this.tooltipY = e.clientY + 10
        this.tooltipX = e.clientX + 10
        this.tooltipText = label
      })
      this.localBus.$on('display-tooltip-grid-square', ({ e, label }) => {
        this.showTooltip = true
        this.tooltipY = e.clientY + 10
        this.tooltipX = e.clientX + 10
        this.tooltipText = `${this.floorplan.dataCenterGrid.ylabels[label.y]} - ${this.floorplan.dataCenterGrid.xlabels[label.x]}`
      })
      this.localBus.$on('display-tooltip-bay', ({ e, label }) => {
        this.showTooltip = true
        this.tooltipY = e.clientY + 10
        this.tooltipX = e.clientX + 10
        this.tooltipText = `Bay ${this.calculateBay(label)}`
      })
      this.localBus.$on('hide-tooltip', () => {
        this.showTooltip = false
      })
    },
    mounted () {
      if (this.initialStateObject) this.setState()
    },

    methods: {
      getModels: function () {
        this.loadingTable = true
        equipmentApi.getEquipmentModels()
          .then((response) => {
            console.log(response)
            this.loadingTable = false
            this.models = response.data
          })
          .catch((error) => {
            console.log(error)
            this.loadingTable = false
            this.localBus.$emit('showAlertMessage', this.alertMessageGeneral)
            common.handleBadCall(error, this.$router)
          })
      },

      addEquipment: function () {
        this.addingEquipment = true
        this.localBus.$emit('removeAlertMessage')
        let location = { gridId: this.stateSelectedFloor.id, siteId: this.stateSelectedSite.siteId, gridX: 0, gridY: 0, uWidth: 0, uHeight: 0 }
        let rackLocation = null
        let unitLocation = null
        let bay = null
        if (this.stateEquipmentLocationQuestion === 'Yes' && this.stateStatus !== 'Disposed') {
          if (this.selectedTableItem.usize === 0) {
            location = {
              ...location,
              parentEquipmentEwcId: null,
              gridX: this.setLocationObject.x,
              gridY: this.setLocationObject.y,
              uWidth: this.setLocationObject.width,
              uHeight: this.setLocationObject.length,
            }
            rackLocation = this.rackLocation
          } else if (this.selectedTableItem.rackMounted || (this.selectedTableItem.usize > 0 && this.selectedTableItem.capacity > 0)) {
            location = {
              ...location,
              parentEquipmentEwcId: this.selectedFloorItem.equipmentData.id,
              gridX: 1,
              gridY: this.setLocationObject.uPosition,
              uWidth: 1,
              uHeight: this.setLocationObject.usize,
            }
            rackLocation = this.selectedFloorItem.equipmentData.rackLocation
            unitLocation = this.setLocationObject.uPosition
          } else {
            location = {
              ...location,
              parentEquipmentEwcId: this.setLocationObject.enclosure.equipmentData.id,
              gridX: this.setLocationObject.location.x,
              gridY: this.setLocationObject.location.y,
              uWidth: this.setLocationObject.location.width,
              uHeight: this.setLocationObject.location.length,
            }
            rackLocation = this.selectedFloorItem.equipmentData.rackLocation
            unitLocation = this.selectedEnclosure.location.gridY
            bay = this.calculateBay({ x: this.setLocationObject.location.x, y: this.setLocationObject.location.y })
          }
        }

        const requestObject = {
          equipmentModelId: this.selectedTableItem.id.toString(),
          serial: this.stateSerial ? this.stateSerial : null,
          assetTag: this.stateAssetTag ? this.stateAssetTag : null,
          status: this.stateStatus,
          label: this.stateLabel,
          description: this.stateDescription,
          notes: this.stateNotes,
          assetId: this.stateAssetId,
          projectName: this.stateProjectName,
          projectNumber: this.stateProjectNumber,
          departmentNumber: this.stateDepartmentNumber,
          rackLocation: rackLocation,
          unitLocation: unitLocation,
          bay: bay,
          location: location,
          title: this.stateTitle,
        }
        if (this.initialStateObject && !this.editEquipment) {
          this.updateAddRequest(requestObject)
        } else if (this.initialStateObject && this.editEquipment) {
          this.$route.params.requestId ? this.updateEditRequest(requestObject) : this.editEquipmentRequestEwc(requestObject)
        } else {
          this.equipmentAddRequest(requestObject)
        }
      },
      updateAddRequest (requestObject) {
        ewcApi.updateAddRequest(this.$route.params.requestId, {
          ...requestObject,
        }).then((response) => {
          console.log(response)
          this.addingEquipment = false
          ewcHolder.setFloorplan(null)
          this.localBus.$emit('pendingEditSuccess', this.snackBarMessagePendingEditSuccess)
        }).catch((error) => {
          console.log(error)
          if (error.response?.status === 409) {
            if (error.response.data.includes('asset')) {
              this.alertMessage = this.alertMessageUniqueAssetTag
              this.uniqueAssetTagArray.push(this.stateAssetTag)
            } else {
              this.alertMessage = this.alertMessageUniqueSerial
              this.uniqueSerialArray.push(this.stateSerial)
            }
          } else {
            this.alertMessage = this.alertMessageGeneral
          }
          this.addingEquipment = false
          this.localBus.$emit('showAlertMessage', this.alertMessage)
          common.handleBadCall(error, this.$router)
        })
      },
      editEquipmentRequestEwc (requestObject) {
        ewcApi.editEquipmentRequestEwc({
          equipmentToEditId: this.$route.params.equipmentToEditId || this.$route.params.equipmentId,
          ewcEquipmentAddRequest: requestObject,
          acquisitionDate: this.acquisitionDate ? this.acquisitionDate.includes('T') ? this.acquisitionDate : this.acquisitionDate + 'T00:00:00.000Z' : null,
          decommissionDate: this.decommissionDate ? this.decommissionDate.includes('T') ? this.decommissionDate : this.decommissionDate + 'T00:00:00.000Z' : null,
          disposalDate: this.disposalDate ? this.disposalDate.includes('T') ? this.disposalDate : this.disposalDate + 'T00:00:00.000Z' : null,
        }).then((response) => {
          console.log(response)
          this.addingEquipment = false
          ewcHolder.setFloorplan(null)
          this.localBus.$emit('editEquipmentSuccess', this.snackBarMessageRequestEditEquipmentSuccess)
        }).catch((error) => {
          console.log(error)
          if (error.response?.status === 409) {
            if (error.response.data.includes('asset')) {
              this.alertMessage = this.alertMessageUniqueAssetTag
              this.uniqueAssetTagArray.push(this.stateAssetTag)
            } else {
              this.alertMessage = this.alertMessageUniqueSerial
              this.uniqueSerialArray.push(this.stateSerial)
            }
          } else {
            this.alertMessage = this.alertMessageGeneral
          }
          this.addingEquipment = false
          this.localBus.$emit('showAlertMessage', this.alertMessage)
          common.handleBadCall(error, this.$router)
        })
      },
      updateEditRequest (requestObject) {
        ewcApi.updateEditRequest({
          equipmentToEditId: this.$route.params.equipmentId,
          ewcEquipmentAddRequest: requestObject,
          acquisitionDate: this.acquisitionDate ? this.acquisitionDate.includes('T') ? this.acquisitionDate : this.acquisitionDate + 'T00:00:00.000Z' : null,
          decommissionDate: this.decommissionDate ? this.decommissionDate.includes('T') ? this.decommissionDate : this.decommissionDate + 'T00:00:00.000Z' : null,
          disposalDate: this.disposalDate ? this.disposalDate.includes('T') ? this.disposalDate : this.disposalDate + 'T00:00:00.000Z' : null,
        }, this.$route.params.requestId).then((response) => {
          console.log(response)
          this.addingEquipment = false
          ewcHolder.setFloorplan(null)
          this.localBus.$emit('pendingEditSuccess', this.snackBarMessagePendingEditSuccess)
        }).catch((error) => {
          console.log(error)
          if (error.response?.status === 409) {
            if (error.response.data.includes('asset')) {
              this.alertMessage = this.alertMessageUniqueAssetTag
              this.uniqueAssetTagArray.push(this.stateAssetTag)
            } else {
              this.alertMessage = this.alertMessageUniqueSerial
              this.uniqueSerialArray.push(this.stateSerial)
            }
          } else {
            this.alertMessage = this.alertMessageGeneral
          }
          this.addingEquipment = false
          this.localBus.$emit('showAlertMessage', this.alertMessage)
          common.handleBadCall(error, this.$router)
        })
      },
      equipmentAddRequest (requestObject) {
        ewcApi.equipmentAddRequest({
          ...requestObject,
        })
          .then((response) => {
            console.log(response)
            this.addingEquipment = false

            if (!this.saveOptionalAnswers) {
              this.stateLabel = ''
              this.stateDescription = ''
              this.stateAssetId = ''
              this.stateProjectName = ''
              this.stateProjectNumber = ''
              this.stateDepartmentNumber = ''
              this.stateNotes = ''
            }

            this.stateEquipmentLocationQuestion = ''
            this.stateSerial = ''
            this.stateAssetTag = ''
            this.stateStatus = ''
            this.selectedTableItem = {}
            this.selectedTableItems = []
            this.tableFieldName = ''
            this.tableFieldUSize = ''
            this.tableFieldCapacity = ''
            this.resetSetLocationState()
            ewcHolder.setFloorplan(null)
            this.localBus.$emit('addSuccess', this.snackBarMessageAddEquipmentSuccess)
          })
          .catch((error) => {
            console.log(error)
            if (error.response?.status === 409) {
              if (error.response.data.includes('asset')) {
                this.alertMessage = this.alertMessageUniqueAssetTag
                this.uniqueAssetTagArray.push(this.stateAssetTag)
              } else {
                this.alertMessage = this.alertMessageUniqueSerial
                this.uniqueSerialArray.push(this.stateSerial)
              }
            } else {
              this.alertMessage = this.alertMessageGeneral
            }
            this.addingEquipment = false
            this.localBus.$emit('showAlertMessage', this.alertMessage)
            common.handleBadCall(error, this.$router)
          })
      },

      openSetLocation: function () {
        this.setLocationDialog = true
        this.initialSelectedFloorItem = this.selectedFloorItem
        this.initialSelectedEnclosure = this.selectedEnclosure
        setTimeout(() => {
          this.localBus.$emit('openSetLocationDialog', this.selectedFloorRectangles, this.selectedPartialRectangles, Object.keys(this.selectedFloorItem).length > 0 ? this.selectedFloorItem.equipmentData.id : -1)
          if (this.selectedTableItem.usize !== 0) {
            this.localBus.$emit('openRackSetLocationDialog', this.selectedRackRectangles, this.selectedFloorItem)
            if (!this.selectedTableItem.rackMounted) {
              setTimeout(() => this.localBus.$emit('openEnclosureItemSetLocationDialog', this.selectedEnclosureRectangles, this.selectedEnclosure), [10])
            }
          }
        }, [10])
      },
      setLocationFloor: function () {
        this.setLocationObject = this.tempLocationObject
        this.selectedFloorRectangles = [...this.tempSelectedItemsMinimap]
        this.selectedPartialRectangles = [...this.tempSelectedPartialItemsMinimap]
        this.rackLocation = this.tempRackLocation
        this.initialFloorSelectionType = this.floorSelectionType
        this.setLocationDialog = false
        this.localBus.$emit('setMinimapFloorLocation', this.tempSelectedItemsMinimap, this.tempSelectedPartialItemsMinimap, this.latestHalfSelection)
      },
      setLocationRack: function () {
        this.selectedRackRectangles = [...this.tempSelectedRackRectangles]
        const startingUPosition = this.selectedRackRectangles.sort((a, b) => a - b)[0]
        this.setLocationObject = { rack: this.selectedFloorItem, uPosition: startingUPosition, usize: this.selectedRackRectangles.length }
        this.miniMapRack = this.selectedFloorItem
        this.$nextTick(() => {
          this.localBus.$emit('setMinimapRackLocation', { selectedFloorItem: this.selectedFloorItem, selectedRackRectangles: this.selectedRackRectangles })
        })
        this.setLocationDialog = false
      },
      setLocationEnclosure: function () {
        this.selectedEnclosure = this.tempSelectedEnclosure
        this.selectedEnclosureRectangles = [...this.tempSelectedItemsMinimap]
        this.setLocationObject = { enclosure: this.selectedEnclosure, location: this.tempLocationObject }
        this.miniMapRack = this.selectedFloorItem
        this.miniMapEnclosure = this.selectedEnclosure
        this.$nextTick(() => {
          this.localBus.$emit('setMinimapEnclosureLocation', { selectedFloorItem: this.selectedFloorItem, selectedEnclosure: this.selectedEnclosure, selectedRectangles: this.tempSelectedItemsMinimap })
        })
        this.setLocationDialog = false
      },
      resetSetLocationState: function () {
        this.setLocationObject = {}
        this.tempSelectedItemsMinimap = []
        this.tempSelectedPartialItemsMinimap = []
        this.noSquaresSelected = true
        this.allSquaresAdjacent = false
        this.squareOrRectangle = false
        this.tempRackLocation = ''
        this.rackLocation = ''
        this.stateTitle = ''

        this.allRectanglesAdjacent = false
        this.noRectanglesSelected = true
        this.usizeSelected = false
        this.selectedRackRectangles = []
        this.selectedFloorRectangles = []
        this.selectedFloorItem = {}
        this.miniMapRack = { equipmentModel: { capacity: 49 } }

        this.selectedEnclosure = {}
        this.tempSelectedEnclosure = {}
        this.miniMapEnclosure = {}

        this.floorSelectionType = 'whole'
        this.selectedPartialRectangles = []
        this.initialFloorSelectionType = ''

        this.localBus.$emit('setMinimapFloorLocation', [])
        this.localBus.$emit('clearSelectedItems')
      },

      checkContiguousSquare: function (selectedItems) {
        if (selectedItems.length === 0) {
          this.squareOrRectangle = false
          this.noSquaresSelected = true
          this.allSquaresAdjacent = false
          return
        } else {
          this.noSquaresSelected = false
        }

        let minX = selectedItems[0].x
        let minY = selectedItems[0].y
        let maxX = selectedItems[0].x
        let maxY = selectedItems[0].y
        this.allSquaresAdjacent = true
        for (let i = 0; i < selectedItems.length; i++) {
          if (selectedItems[i].x < minX) {
            minX = selectedItems[i].x
          }
          if (selectedItems[i].x > maxX) {
            maxX = selectedItems[i].x
          }
          if (selectedItems[i].y < minY) {
            minY = selectedItems[i].y
          }
          if (selectedItems[i].y > maxY) {
            maxY = selectedItems[i].y
          }

          // check if all selected items are contiguous
          let isAdjacent = selectedItems.length === 1
          for (let j = 0; j < selectedItems.length; j++) {
            if (j === i) continue
            if ((selectedItems[i].x - 1 === selectedItems[j].x && selectedItems[i].y === selectedItems[j].y) ||
              (selectedItems[i].x === selectedItems[j].x && selectedItems[i].y - 1 === selectedItems[j].y) ||
              (selectedItems[i].x + 1 === selectedItems[j].x && selectedItems[i].y === selectedItems[j].y) ||
              (selectedItems[i].x === selectedItems[j].x && selectedItems[i].y + 1 === selectedItems[j].y)
            ) {
              isAdjacent = true
              break
            }
          }
          if (!isAdjacent) {
            this.allSquaresAdjacent = false
          }
        }

        const length = maxY - minY + 1
        const width = maxX - minX + 1
        if (width * length === selectedItems.length) {
          this.tempLocationObject = {
            x: minX,
            y: minY,
            width,
            length,
          }
          this.squareOrRectangle = true
        } else {
          this.squareOrRectangle = false
        }
      },

      checkContiguousWithPartials (selectedItems, selectedPartialItems) {
        if (selectedItems.length === 0 && selectedPartialItems.length === 0) {
          this.squareOrRectangle = false
          this.noSquaresSelected = true
          this.allSquaresAdjacent = false
          return
        } else {
          this.noSquaresSelected = false
        }

        let minX = selectedPartialItems[0].x
        let minY = selectedPartialItems[0].y
        let maxX = selectedPartialItems[0].x
        let maxY = selectedPartialItems[0].y

        this.allSquaresAdjacent = true
        for (let i = 0; i < selectedItems.length; i++) {
          if (selectedItems[i].x < minX) {
            minX = selectedItems[i].x
          }
          if (selectedItems[i].x + 1 > maxX) {
            maxX = selectedItems[i].x + 1
          }
          if (selectedItems[i].y < minY) {
            minY = selectedItems[i].y
          }
          if (selectedItems[i].y + 1 > maxY) {
            maxY = selectedItems[i].y + 1
          }
          let isAdjacent = selectedItems.length === 1
          for (let j = 0; j < selectedItems.length; j++) {
            if (j === i) continue
            if ((selectedItems[i].x - 1 === selectedItems[j].x && selectedItems[i].y === selectedItems[j].y) ||
              (selectedItems[i].x === selectedItems[j].x && selectedItems[i].y - 1 === selectedItems[j].y) ||
              (selectedItems[i].x + 1 === selectedItems[j].x && selectedItems[i].y === selectedItems[j].y) ||
              (selectedItems[i].x === selectedItems[j].x && selectedItems[i].y + 1 === selectedItems[j].y)
            ) {
              isAdjacent = true
              break
            }
          }
          // for if a user wants to place a square and then two halves in between the square...
          if (this.latestHalfSelection === 'half-up-down') {
            for (let j = 0; j < selectedPartialItems.length; j++) {
              if ((selectedPartialItems[j].x - 1 === selectedItems[i].x && (selectedPartialItems[j].y === selectedItems[i].y || selectedPartialItems[j].y === selectedItems[i].y + 0.5)) ||
                (selectedPartialItems[j].x === selectedItems[i].x && selectedPartialItems[j].y + 0.5 === selectedItems[i].y) ||
                (selectedPartialItems[j].x + 1 === selectedItems[i].x && (selectedPartialItems[j].y === selectedItems[i].y || selectedPartialItems[j].y === selectedItems[i].y + 0.5)) ||
                (selectedPartialItems[j].x === selectedItems[i].x && selectedPartialItems[j].y - 1 === selectedItems[i].y)
              ) {
                isAdjacent = true
                break
              }
            }
          } else if (this.latestHalfSelection === 'half-left-right') {
            for (let j = 0; j < selectedPartialItems.length; j++) {
              if ((selectedPartialItems[j].x - 1 === selectedItems[i].x && selectedPartialItems[j].y === selectedItems[i].y) ||
                ((selectedPartialItems[j].x === selectedItems[i].x || selectedPartialItems[j].x - 0.5 === selectedItems[i].x) && selectedPartialItems[j].y + 1 === selectedItems[i].y) ||
                (selectedPartialItems[j].x + 0.5 === selectedItems[i].x && (selectedPartialItems[j].y === selectedItems[i].y || selectedPartialItems[j].y === selectedItems[i].y + 0.5)) ||
                ((selectedPartialItems[j].x === selectedItems[i].x || selectedPartialItems[j].x - 0.5 === selectedItems[i].x) && selectedPartialItems[j].y - 1 === selectedItems[i].y)
              ) {
                isAdjacent = true
                break
              }
            }
          }
          if (!isAdjacent) {
            this.allSquaresAdjacent = false
          }
        }

        for (let i = 0; i < selectedPartialItems.length; i++) {
          if (selectedPartialItems[i].x < minX) {
            minX = selectedPartialItems[i].x
          }
          if (selectedPartialItems[i].x + 0.5 > maxX) {
            maxX = selectedPartialItems[i].x + (this.latestHalfSelection === 'half-left-right' ? 0.5 : 1)
          }
          if (selectedPartialItems[i].y < minY) {
            minY = selectedPartialItems[i].y
          }
          if (selectedPartialItems[i].y + 0.5 > maxY) {
            maxY = selectedPartialItems[i].y + (this.latestHalfSelection === 'half-left-right' ? 1 : 0.5)
          }

          // brute force way of determining adjacency between all partial and whole selections
          let isAdjacent = selectedPartialItems.length === 1 && selectedItems.length === 0
          if (this.latestHalfSelection === 'half-up-down') {
            for (let j = 0; j < selectedItems.length; j++) {
              if ((selectedPartialItems[i].x - 1 === selectedItems[j].x && (selectedPartialItems[i].y === selectedItems[j].y || selectedPartialItems[i].y === selectedItems[j].y + 0.5)) ||
                (selectedPartialItems[i].x === selectedItems[j].x && selectedPartialItems[i].y + 0.5 === selectedItems[j].y) ||
                (selectedPartialItems[i].x + 1 === selectedItems[j].x && (selectedPartialItems[i].y === selectedItems[j].y || selectedPartialItems[i].y === selectedItems[j].y + 0.5)) ||
                (selectedPartialItems[i].x === selectedItems[j].x && selectedPartialItems[i].y - 1 === selectedItems[j].y)
              ) {
                isAdjacent = true
                break
              }
            }
            // for if a user decides to place two halves next to vertical each other in up down configuration
            for (let j = 0; j < selectedPartialItems.length; j++) {
              if (j === i) continue
              if ((selectedPartialItems[i].x - 1 === selectedPartialItems[j].x && selectedPartialItems[i].y === selectedPartialItems[j].y) ||
                (selectedPartialItems[i].x === selectedPartialItems[j].x && selectedPartialItems[i].y - 0.5 === selectedPartialItems[j].y) ||
                (selectedPartialItems[i].x + 1 === selectedPartialItems[j].x && selectedPartialItems[i].y === selectedPartialItems[j].y) ||
                (selectedPartialItems[i].x === selectedPartialItems[j].x && selectedPartialItems[i].y + 0.5 === selectedPartialItems[j].y)
              ) {
                isAdjacent = true
                break
              }
            }
          } else if (this.latestHalfSelection === 'half-left-right') {
            for (let j = 0; j < selectedItems.length; j++) {
              if ((selectedPartialItems[i].x - 1 === selectedItems[j].x && selectedPartialItems[i].y === selectedItems[j].y) ||
                ((selectedPartialItems[i].x === selectedItems[j].x || selectedPartialItems[i].x - 0.5 === selectedItems[j].x) && selectedPartialItems[i].y + 1 === selectedItems[j].y) ||
                (selectedPartialItems[i].x + 0.5 === selectedItems[j].x && (selectedPartialItems[i].y === selectedItems[j].y || selectedPartialItems[i].y === selectedItems[j].y + 0.5)) ||
                ((selectedPartialItems[i].x === selectedItems[j].x || selectedPartialItems[i].x - 0.5 === selectedItems[j].x) && selectedPartialItems[i].y - 1 === selectedItems[j].y)
              ) {
                isAdjacent = true
                break
              }
            }
            // for if a user decides to place two halves next to each other in left right configuration
            for (let j = 0; j < selectedPartialItems.length; j++) {
              if (j === i) continue
              if ((selectedPartialItems[i].x - 0.5 === selectedPartialItems[j].x && selectedPartialItems[i].y === selectedPartialItems[j].y) ||
                (selectedPartialItems[i].x === selectedPartialItems[j].x && selectedPartialItems[i].y + 1 === selectedPartialItems[j].y) ||
                (selectedPartialItems[i].x + 0.5 === selectedPartialItems[j].x && selectedPartialItems[i].y === selectedPartialItems[j].y) ||
                (selectedPartialItems[i].x === selectedPartialItems[j].x && selectedPartialItems[i].y - 1 === selectedPartialItems[j].y)
              ) {
                isAdjacent = true
                break
              }
            }
          }

          if (!isAdjacent) {
            this.allSquaresAdjacent = false
          }
        }
        const length = maxY - minY
        const width = maxX - minX
        if (width * length === selectedItems.length + selectedPartialItems.length / 2) {
          this.tempLocationObject = {
            x: minX,
            y: minY,
            width,
            length,
          }
          this.squareOrRectangle = true
        } else {
          this.squareOrRectangle = false
        }
      },

      calculateRackLocation (floorSelection) {
        const floorplan = ewcHolder.getFloorplan()
        return `${floorplan.dataCenterGrid.ylabels[floorSelection.gridY]}-${floorplan.dataCenterGrid.xlabels[floorSelection.gridX]}`
      },
      calculateBay (enclosureItem) {
        if (Object.keys(this.tempSelectedEnclosure).length === 0) return
        return enclosureItem.x + 1 + this.tempSelectedEnclosure.equipmentModel.modelColumns * enclosureItem.y
      },

      getDataCentersForUser () {
        if (ewcHolder.getDataCenters()) {
          this.sites = ewcHolder.getDataCenters()
          if (this.sites.length === 1) {
            this.selectedSite = this.sites[0]
            this.grids = this.selectedSite.siteGrids
          }
          return
        }
        this.loadingSites = true
        ewcApi.getDataCentersForUser()
          .then(response => {
            console.log(response)
            this.sites = response.data
            if (this.sites.length === 1) {
              this.stateSelectedSite = this.sites[0]
              this.grids = this.stateSelectedSite.siteGrids
            }
            this.loadingSites = false
          })
          .catch(error => {
            console.log(error)
            this.loadingSites = false
            this.localBus.$emit('showAlertMessage', this.alertMessageDataCenters)
            common.handleBadCall(error, this.$router)
          })
      },

      getEquipmentEwcForSite (gridId) {
        const floorplan = ewcHolder.getFloorplan()
        if (floorplan?.dataCenterGrid.id === parseInt(gridId)) {
          this.floorplan = floorplan
        } else {
          this.loadingFloorplan = true
          equipmentApi.getEquipmentEwcForSite(gridId)
            .then((response) => {
              console.log(response)
              this.loadingFloorplan = false
              this.$nextTick(() => { this.floorplan = response.data })
              ewcHolder.setFloorplan(response.data)
            })
            .catch((error) => {
              console.log(error)
              this.loadingFloorplan = false
              this.showAlert = true
              this.alertMessage = this.alertMessageFloorplan
              this.selectedFloor = ''
              common.handleBadCall(error, this.$router)
            })
        }
      },
      refreshEquipment () {
        this.loadingFloorplan = true
        if (!this.stateSelectedFloor?.id) return
        equipmentApi.getEquipmentEwcForSite(this.stateSelectedFloor.id)
          .then((response) => {
            console.log(response)
            this.loadingFloorplan = false
            this.resetSetLocationState()
            this.$nextTick(() => { this.floorplan = response.data })
            ewcHolder.setFloorplan(response.data)
          })
          .catch((error) => {
            console.log(error)
            this.showSnackBar = true
            this.snackBarType = 'error'
            this.snackbarMessage = this.alertMessageFloorplan
            this.loadingFloorplan = false
            common.handleBadCall(error, this.$router)
          })
      },
      delay (time) {
        return new Promise(resolve => setTimeout(resolve, time))
      },
      async setState () {
        // wait for models and sites to be loaded before trying to set state
        while (this.loadingSites || this.loadingTable || this.loadingFloorplan) {
          await this.delay(100)
        }

        // return if there is an error in any of the api calls
        if (this.sites.length === 0 || this.models.length === 0 || Object.keys(this.floorplan) === 0) return

        // set state for model, site and grid
        this.tableFieldName = this.initialStateObject.equipmentModel.name
        this.selectedTableItem = this.initialStateObject.equipmentModel
        this.selectedTableItems = [this.selectedTableItem]
        this.sites.forEach(site => {
          if (site.siteId === parseInt(this.$route.params.siteId)) {
            this.stateSelectedSite = site
            this.grids = site.siteGrids
          }
        })
        this.grids.forEach(grid => {
          if (grid.id === parseInt(this.$route.params.gridId)) {
            this.stateSelectedFloor = grid
          }
        })
        // check if the initial state includes location
        if (this.initialStateObject.location.uheight !== 0 || this.initialStateObject.location.uwidth !== 0) {
          this.stateEquipmentLocationQuestion = 'Yes'
          this.getEquipmentEwcForSite(this.$route.params.gridId)

          // populate location state for a floor item
          if (this.selectedTableItem.usize === 0) {
            this.setLocationObject = {
              x: this.initialStateObject.location.gridX,
              y: this.initialStateObject.location.gridY,
              width: this.initialStateObject.location.uwidth,
              length: this.initialStateObject.location.uheight,
            }
            this.rackLocation = this.initialStateObject.equipmentData.rackLocation

            let floorSelectionType = 'whole'
            const selectedPartialRectangles = []
            const selectedFloorRectangles = []
            let before = false
            let after = false

            if (this.initialStateObject.location.gridX % 1 !== 0 || this.initialStateObject.location.uwidth % 1 !== 0) {
              floorSelectionType = 'half-left-right'
              before = this.initialStateObject.location.gridX % 1 !== 0
              after = (before && this.initialStateObject.location.uwidth % 1 === 0) ||
                (!before && this.initialStateObject.location.uwidth % 1 !== 0)
            } else if (this.initialStateObject.location.gridY % 1 !== 0 || this.initialStateObject.location.uheight % 1 !== 0) {
              floorSelectionType = 'half-up-down'
              before = this.initialStateObject.location.gridY % 1 !== 0
              after = (before && this.initialStateObject.location.uheight % 1 === 0) ||
                (!before && this.initialStateObject.location.uheight % 1 !== 0)
            }

            if (floorSelectionType === 'half-left-right') {
              for (let i = 0; i < this.initialStateObject.location.uwidth + (after ? 0.5 : 0); i++) {
                for (let j = 0; j < this.initialStateObject.location.uheight; j++) {
                  if (i === 0 && before) {
                    selectedPartialRectangles.push({
                      x: this.initialStateObject.location.gridX,
                      y: this.initialStateObject.location.gridY + j,
                    })
                  } else if (i === Math.floor(this.initialStateObject.location.uwidth) && after) {
                    selectedPartialRectangles.push({
                      x: this.initialStateObject.location.gridX + this.initialStateObject.location.uwidth - 0.5,
                      y: this.initialStateObject.location.gridY + j,
                    })
                  } else {
                    selectedFloorRectangles.push({
                      x: this.initialStateObject.location.gridX + i - (before ? 0.5 : 0),
                      y: this.initialStateObject.location.gridY + j,
                    })
                  }
                }
              }
            } else if (floorSelectionType === 'half-up-down') {
              for (let i = 0; i < this.initialStateObject.location.uwidth; i++) {
                for (let j = 0; j < this.initialStateObject.location.uheight + (after ? 0.5 : 0); j++) {
                  if (j === 0 && before) {
                    selectedPartialRectangles.push({
                      x: this.initialStateObject.location.gridX + i,
                      y: this.initialStateObject.location.gridY,
                    })
                  } else if (j === Math.floor(this.initialStateObject.location.uheight) && after) {
                    selectedPartialRectangles.push({
                      x: this.initialStateObject.location.gridX + i,
                      y: this.initialStateObject.location.gridY + this.initialStateObject.location.uheight - 0.5,
                    })
                  } else {
                    selectedFloorRectangles.push({
                      x: this.initialStateObject.location.gridX + i,
                      y: this.initialStateObject.location.gridY + j - (before ? 0.5 : 0),
                    })
                  }
                }
              }
            } else {
              for (let i = 0; i < this.initialStateObject.location.uwidth; i++) {
                for (let j = 0; j < this.initialStateObject.location.uheight; j++) {
                  selectedFloorRectangles.push({
                    x: this.initialStateObject.location.gridX + i,
                    y: this.initialStateObject.location.gridY + j,
                  })
                }
              }
            }

            const convertedGridLocation = {
              y: this.floorplan.dataCenterGrid.ylabels.indexOf(this.initialStateObject.equipmentData.rackLocation.split('-')[0]),
              x: this.floorplan.dataCenterGrid.xlabels.indexOf(this.initialStateObject.equipmentData.rackLocation.split('-')[1]),
            }
            this.setCorrectRackSelectionOrder(selectedFloorRectangles, convertedGridLocation)
            if (floorSelectionType !== 'whole') this.setCorrectRackSelectionOrder(selectedPartialRectangles, convertedGridLocation)

            this.floorSelectionType = floorSelectionType
            this.initialFloorSelectionType = this.floorSelectionType
            this.selectedPartialRectangles = selectedPartialRectangles
            this.selectedFloorRectangles = selectedFloorRectangles
            this.localBus.$emit('setMinimapFloorLocation', this.selectedFloorRectangles, this.selectedPartialRectangles, this.floorSelectionType)
            // poplulate location state for a rack item
          } else if (this.initialStateObject.equipmentModel.rackMounted) {
            // find the correct rack by going through all equipment on the floor and matching the rack location of the floor equipment with that of the initial state object
            // concatenate active and pending equipment in case the rack item is in a pending rack
            this.floorplan.activeEquipmentEwc.concat(this.floorplan.pendingEquipmentEwc).forEach(potentialFloorItem => {
              if (potentialFloorItem.equipmentData.id === this.initialStateObject.location.parentEquipmentEwcId) {
                for (let i = 0; i < this.initialStateObject.location.uheight; i++) {
                  this.selectedRackRectangles.push(this.initialStateObject.location.gridY + i)
                }
                // set all the initial states for the variables keeping track of the minimap and set location dialog selections
                this.selectedFloorItem = potentialFloorItem
                this.miniMapRack = this.selectedFloorItem
                this.setLocationObject = { rack: this.selectedFloorItem, uPosition: this.initialStateObject.location.gridY, usize: this.selectedRackRectangles.length }
                this.$nextTick(() => this.localBus.$emit('setMinimapRackLocation', { selectedFloorItem: this.selectedFloorItem, selectedRackRectangles: this.selectedRackRectangles }))
              }
            })
            // populate location state for an enclosure item
          } else {
            // same as for a rack, look through all active and pending flooritems, but use parentEquipmentEwcId === null and rackLocation to make sure it is the correct floor item
            this.floorplan.activeEquipmentEwc.concat(this.floorplan.pendingEquipmentEwc).forEach(potentialFloorItem => {
              if (potentialFloorItem.equipmentData.rackLocation === this.initialStateObject.equipmentData.rackLocation && !potentialFloorItem.location.parentEquipmentEwcId) {
                this.selectedFloorItem = potentialFloorItem
                this.miniMapRack = this.selectedFloorItem
                // go through all the children of a rack and find the parent of the initial object
                // concatenate the pending equipment in case the enclosure item is in a pending enclosure
                potentialFloorItem.children.concat(this.floorplan.pendingEquipmentEwc).forEach(potentialFloorItemChild => {
                  if (potentialFloorItemChild.equipmentData.id === this.initialStateObject.location.parentEquipmentEwcId) {
                    // set all the initial states for the variables keeping track of the minimap and set location dialog selections
                    this.miniMapEnclosure = potentialFloorItemChild
                    this.selectedEnclosure = potentialFloorItemChild
                    this.tempSelectedEnclosure = potentialFloorItemChild
                    this.setLocationObject = {
                      enclosure: this.selectedEnclosure,
                      location: {
                        x: this.initialStateObject.location.gridX,
                        y: this.initialStateObject.location.gridY,
                        width: this.initialStateObject.location.uwidth,
                        length: this.initialStateObject.location.uheight,
                      },
                    }
                    // set rectangles as before but without all the partial calculations
                    for (let i = 0; i < this.initialStateObject.location.uwidth; i++) {
                      for (let j = 0; j < this.initialStateObject.location.uheight; j++) {
                        this.selectedEnclosureRectangles.push({ x: this.initialStateObject.location.gridX + i, y: this.initialStateObject.location.gridY + j })
                      }
                    }
                    this.$nextTick(() => this.localBus.$emit('setMinimapEnclosureLocation', { selectedFloorItem: this.selectedFloorItem, selectedEnclosure: this.selectedEnclosure, selectedRectangles: this.selectedEnclosureRectangles }))
                  }
                })
              }
            })
          }
        } else {
          this.stateEquipmentLocationQuestion = 'No'
        }
        // set the remaining states for the rest of the text fields
        this.stateStatus = this.initialStateObject.equipmentData.status
        this.stateAssetTag = this.initialStateObject.equipmentData.assetTag || ''
        this.stateSerial = this.initialStateObject.equipmentData.serial || ''
        this.stateTitle = this.initialStateObject.title
        this.stateLabel = this.initialStateObject.equipmentData.label || ''
        this.stateAssetId = this.initialStateObject.equipmentData.assetId || ''
        this.stateDepartmentNumber = this.initialStateObject.equipmentData.departmentNumber || ''
        this.stateProjectName = this.initialStateObject.equipmentData.projectName || ''
        this.stateProjectNumber = this.initialStateObject.equipmentData.projectNumber || ''
        this.stateDescription = this.initialStateObject.equipmentData.description || ''
        this.stateNotes = this.initialStateObject.equipmentData.notes || ''

        if (this.editEquipment) {
          this.acquisitionDate = this.initialStateObject.equipmentData.acquisitionDate
          this.decommissionDate = this.initialStateObject.equipmentData.decommissionDate
          this.disposalDate = this.initialStateObject.equipmentData.disposalDate
          this.localBus.$emit('initialEquipmentStateLoaded')
        }
      },
      clearLatestSelections () {
        this.stateTitle = ''
        if (this.selectedTableItem.usize === 0) {
          this.setLocationObject = {}
          this.tempSelectedItemsMinimap = []
          this.tempSelectedPartialItemsMinimap = []
          this.noSquaresSelected = true
          this.allSquaresAdjacent = false
          this.squareOrRectangle = false
          this.tempRackLocation = ''
          this.localBus.$emit('clearSelectedSquaresFloor')
        } else if (this.selectedTableItem.rackMounted) {
          if (this.noRectanglesSelected) {
            this.selectedFloorItem = {}
            this.localBus.$emit('clearSelectedFloorItem')
          } else {
            this.allRectanglesAdjacent = false
            this.noRectanglesSelected = true
            this.usizeSelected = false
            this.tempSelectedRackRectangles = []
            this.localBus.$emit('clearSelectedRectanglesRack')
          }
        } else {
          if (!this.noSquaresSelected) {
            this.noSquaresSelected = true
            this.allSquaresAdjacent = false
            this.squareOrRectangle = false
            this.usizeSelected = false
            this.tempSelectedItemsMinimap = []
            this.localBus.$emit('clearSelectedSquaresEnclosure')
          } else if (Object.keys(this.tempSelectedEnclosure).length > 0) {
            this.tempSelectedEnclosure = {}
            this.localBus.$emit('clearSelectedRackItem')
          } else {
            this.selectedFloorItem = {}
            this.localBus.$emit('clearSelectedFloorItem')
          }
        }
      },

      setCorrectRackSelectionOrder (selectedRectangles, gridLocation) {
        const first = selectedRectangles[0]
        for (let i = 0; i < selectedRectangles.length; i++) {
          if (Math.floor(selectedRectangles[i].x) === gridLocation.x && Math.floor(selectedRectangles[i].y) === gridLocation.y) {
            selectedRectangles[0] = selectedRectangles[i]
            selectedRectangles[i] = first
            this.tempSelectedItemsMinimap[0] = selectedRectangles[i]
            break
          }
        }
      },

      getSocketNotificationUrl () {
        socketApi.getSocketNotificationUrl()
          .then(response => {
            console.log(response)

            socketUtil.setupSockets(response.data, this.onRecieveSocketMessage)
          })
          .catch(error => {
            console.log(error)
            common.handleBadCall(error, this.$router)
          })
      },
      onRecieveSocketMessage (message) {
        console.log(message)
        const parsedMessage = JSON.parse(message.body)

        if (parsedMessage.gridId === this.stateSelectedFloor?.id && this.stateEquipmentLocationQuestion === 'Yes' && !this.addingEquipment) {
          this.showSnackBar = true
          this.snackBarType = 'info'
          this.snackBarMessage = this.snackBarMessageUpdateFloorplan
        }
      },
    },
  }
</script>
<style>
.wizard-equipment {
  width: 400px;
  margin-left: 48px;
}
tr.v-data-table__selected {
  background:  #e91e63 !important;
  opacity: 0.7;
  color: white !important;
}
tr.v-data-table__selected .v-icon {
  color: white !important;
}
.row-pointer>.v-data-table__wrapper>table>tbody>tr :hover {
cursor: pointer;
}
.row-pointer>.v-data-table__wrapper>table>tbody>.no-pointer :hover {
cursor: default;
}
.row-pointer>.v-data-table__wrapper>table>tbody>.no-pointer>td .v-input__slot :hover {
cursor: text;
}
</style>
