<template>
  <v-form
    id="form"
    ref="form"
    v-model="isFormValid"
    onsubmit="return false;"
    @submit="showConfirmDialog = true"
  >
    <v-sheet
      width="80%"
      style="margin-right: 10%; margin-left: 10%"
    >
      <v-expand-transition>
        <div
          class="my-6"
        >
          <div>
            Does the model stand alone on the floor?
          </div>
          <v-select
            id="ewcModelStandAloneDropdown"
            v-model="stateEquipmentStandAlone"
            :disabled="editNameOnly"
            :items="['Yes', 'No']"
            dense
            :rules="[rules.required]"
            @change="(selection) => {
              if (selection === 'Yes') {
                stateUSize = ''
                stateIsEnclosureMountable = ''
              }
            }"
          />
        </div>
      </v-expand-transition>
      <v-expand-transition>
        <div
          v-if="stateEquipmentStandAlone"
          class="my-6"
        >
          <div>
            Can this model contain other equipment?
          </div>
          <v-select
            id="ewcModelContainsDropdown"
            v-model="stateEquipmentContains"
            :disabled="editNameOnly"
            :items="['Yes', 'No']"
            dense
            :rules="[rules.required]"
            @change="selection => {
              if (selection === 'No') {
                stateModelRows = ''
                stateModelColumns = ''
              } else {
                stateIsEnclosureMountable = ''
              }
            }"
          />
        </div>
      </v-expand-transition>
      <v-expand-transition>
        <div
          v-if="stateEquipmentStandAlone === 'No' && stateEquipmentContains === 'No'"
          class="my-6"
        >
          <div>Is this model meant to be placed in something that is not a rack?</div>
          <div>For example: A blade server that is placed in a rack-mountable enclosure?</div>
          <v-select
            id="ewcModelRackMountedDropdown"
            v-model="stateIsEnclosureMountable"
            :disabled="editNameOnly"
            :items="['Yes', 'No']"
            dense
            :rules="[rules.required]"
          />
        </div>
      </v-expand-transition>
      <v-expand-transition>
        <div
          v-if="stateEquipmentContains === 'Yes'"
          class="my-6"
        >
          <div>
            How many rows for equipment does this model have?
          </div>
          <v-text-field
            v-model="stateModelRows"
            :disabled="editNameOnly"
            dense
            required
            type="number"
            :rules="[rules.wholeNumber, rules.positiveNumber]"
          />
        </div>
      </v-expand-transition>
      <v-expand-transition>
        <div
          v-if="stateEquipmentStandAlone === 'No' && stateEquipmentContains === 'Yes'"
          class="my-6"
        >
          <div>
            <div>How many columns for equipment does this model have?</div>
            <div>For example, how many 1 U pieces of equipment can fit in a row?</div>
          </div>
          <v-text-field
            v-model="stateModelColumns"
            :disabled="editNameOnly"
            dense
            required
            type="number"
            :rules="[rules.wholeNumber, rules.positiveNumber]"
          />
        </div>
      </v-expand-transition>
      <v-expand-transition>
        <div
          v-if="stateEquipmentStandAlone === 'No' && ((stateEquipmentContains === 'No' && stateIsEnclosureMountable) || stateEquipmentContains === 'Yes' && (stateModelRows && stateModelColumns))"
          class="my-6"
        >
          <div>
            What is the model's U size?
          </div>
          <v-text-field
            v-model="stateUSize"
            :disabled="editNameOnly"
            dense
            required
            type="number"
            :rules="[rules.wholeNumber, rules.positiveNumber]"
          />
        </div>
      </v-expand-transition>
      <v-expand-transition>
        <div
          v-if="pathComplete"
          class="my-6"
        >
          <div>
            What is the name for the equipment model?
          </div>
          <v-text-field
            id="ewcModelNameField"
            v-model="stateEquipmentName"
            dense
            type="text"
            :rules="[rules.required, rules.characterLimit, rules.uniqueModel]"
          />
        </div>
      </v-expand-transition>
      <div
        v-if="pathComplete"
        class="my-6"
      >
        <div class="d-flex align-center">
          <div>
            Model Image:
          </div>
          <v-file-input
            v-model="modelImageInput"
            class="pt-4 pl-6"
            type="file"
            :label="initialImage ? initialImage : 'Upload File'"
            :prepend-icon="mdiCamera"
            :rules="fileUploadRules"
            accept="image/*"
            @change="uploadImage"
          >
            <template
              v-if="initialImage && !modelImageInput"
              v-slot:append
            >
              <v-icon
                color="red"
                style="cursor: pointer"
                @click="removeSrcImage"
              >
                {{ mdiClose }}
              </v-icon>
            </template>
          </v-file-input>
        </div>
        <v-img
          v-if="modelImageSrc"
          :aspect-ratio="16/9"
          contain
          height="100"
          :src="modelImageSrc"
        />
        <div
          v-else
          class="d-flex justify-center"
        >
          <v-icon>
            {{ mdiImageOffOutline }}
          </v-icon>
        </div>
      </div>
    </v-sheet>
    <v-card-actions class="justify-center pb-5">
      <v-btn
        id="ewcModelBuildButton"
        :disabled="!isFormValid"
        :loading="loadingBuild"
        color="green darken-1"
        text
        class="font-weight-bold"
        width="100px"
        height="56px"
        style="font-size: 14px"
        @click="!initialStateObject ?
          buildModel() :
          editNameOnly ? editModelName() : editModel()
        "
      >
        {{ initialStateObject ? 'Edit' : 'Build' }}
      </v-btn>
      <v-btn
        id="ewcModelBuildButton"
        :disabled="loadingBuild"
        color="gray lighten-1"
        text
        class="font-weight-bold"
        width="100px"
        height="56px"
        style="font-size: 14px"
        @click="
          resetState()
          $emit('cancel-build-model')"
      >
        Cancel
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
  import equipmentApi from '@/api/equipment.js'
  import ewcApi from '@/api/ewc.js'
  import common from '@/api/common.js'
  import { mdiCamera, mdiClose, mdiImageOffOutline } from '@mdi/js'

  export default {
    name: 'EwcModelFlow',
    props: {
      localBus: Object,
      initialStateObject: Object,
      editNameOnly: Boolean,
    },
    data: () => ({
      isFormValid: false,
      stateEquipmentStandAlone: '',
      stateEquipmentContains: '',
      stateModelRows: '',
      stateModelColumns: '',
      stateIsEnclosureMountable: '',
      stateEquipmentName: '',
      stateUSize: '',
      loadingBuild: false,

      modelImageInput: null,
      modelImageSrc: '',
      mdiCamera: mdiCamera,
      mdiClose: mdiClose,
      mdiImageOffOutline: mdiImageOffOutline,
      initialImage: '',

      uniqueModelArray: [],
      alertMessageUniqueName: 'A model with that name already exists.',
      alertMessageGeneral: 'We are unable to process your request at this time. Please try again later.',
      alertMessageImageUploadFail: 'We were unable to upload your image at this time. Please try again later.',
      snackBarMessageAddModelSuccess: 'Successfully added model!',
      snackBarMessageEditModelSuccess: 'Equipment Model updated!',

      fileUploadRules: [
        value => !value || value.size < 2000000 || 'Image size should be less than 2 MB!',
        file => {
          const pattern = /\.(jpg|png)$/
          return (
            !file || pattern.test(file.name) || 'File type should be .png or .jpg'
          )
        },
      ],

    }),
    computed: {
      pathComplete () {
        if (
          (this.stateEquipmentStandAlone === 'Yes' && this.stateEquipmentContains === 'Yes' && this.stateModelRows) ||
          (this.stateEquipmentStandAlone === 'No' && this.stateEquipmentContains === 'Yes' && this.stateModelRows && this.stateModelColumns && this.stateUSize) ||
          (this.stateEquipmentStandAlone === 'Yes' && this.stateEquipmentContains === 'No') ||
          (this.stateEquipmentStandAlone === 'No' && this.stateEquipmentContains === 'No' && this.stateIsEnclosureMountable && this.stateUSize)
        ) {
          return true
        } else {
          return false
        }
      },

      rules () {
        const modelArray = this.uniqueModelArray

        const rules = {}
        rules.required = (value) => !!value || 'Required.'
        rules.wholeNumber = (value) => !value.includes('.') || 'Please enter a whole number'
        rules.positiveNumber = (value) => value > 0 || 'Please enter a positive number'
        rules.uniqueModel = (value) => !modelArray.includes(value) || 'Please enter a unique name'
        rules.characterLimit = (value) => value.length < 255 || 'Please enter a shorter value'
        return rules
      },
    },
    watch: {
      rules: function () {
        setTimeout(() => {
          this.$refs.form.validate()
        }, 10)
      },
    },
    created () {
      if (this.initialStateObject) {
        this.setState()
      }
    },
    methods: {
      buildModel: function () {
        this.loadingBuild = true
        this.localBus.$emit('removeModelAlertMessage')
        const imageRequest = this.modelImageInput ? {
          fileName: this.stateEquipmentName,
          fileType: this.modelImageInput.type.split('/')[1],
          base64ImageString: this.modelImageSrc.substr(this.modelImageSrc.indexOf(',') + 1),
        } : null

        equipmentApi.addEquipmentModel({
          name: this.stateEquipmentName,
          imagePath: null,
          uSize: this.stateUSize,
          capacity: !this.stateEquipmentContains ? 0 : this.stateModelRows && this.stateModelColumns ? this.stateModelRows * this.stateModelColumns : this.stateModelRows,
          isRackMounted: (this.stateEquipmentStandAlone === 'No' && this.stateEquipmentContains === 'Yes') || this.stateIsEnclosureMountable === 'No',
          modelRows: this.stateModelRows,
          modelColumns: this.stateModelColumns,
          imageRequest,
        })
          .then((response) => {
            console.log(response)
            this.resetState()
            this.loadingBuild = false
            this.localBus.$emit('addModelSuccess', this.snackBarMessageAddModelSuccess)
          })
          .catch((error) => {
            console.log(error)
            this.loadingBuild = false
            if (error.response?.status === 409) {
              this.alertMessage = this.alertMessageUniqueName
              this.uniqueModelArray.push(this.stateEquipmentName)
            } else if (error.response?.status === 417) {
              this.alertMessage = this.alertMessageImageUploadFail
            } else {
              this.alertMessage = this.alertMessageGeneral
            }
            this.localBus.$emit('showBuildModelAlertMessage', this.alertMessage)
            common.handleBadCall(error, this.$router)
          })
      },
      resetState () {
        this.stateEquipmentStandAlone = ''
        this.stateEquipmentContains = ''
        this.stateModelRows = ''
        this.stateIsEnclosureMountable = ''
        this.stateModelColumns = ''
        this.stateEquipmentName = ''
        this.stateUSize = ''
        this.modelImageSrc = ''
        this.modelImageInput = null
        this.initialImage = ''
      },
      setState () {
        this.stateEquipmentStandAlone = this.initialStateObject.usize === 0 ? 'Yes' : 'No'
        this.stateEquipmentContains = this.initialStateObject.capacity === 0 ? 'No' : 'Yes'
        this.stateModelRows = this.initialStateObject.modelRows ? this.initialStateObject.modelRows.toString() : ''
        this.stateModelColumns = this.initialStateObject.modelColumns ? this.initialStateObject.modelColumns.toString() : ''
        this.stateIsEnclosureMountable = this.initialStateObject.rackMounted ? 'No' : 'Yes'
        this.stateUSize = this.initialStateObject.usize ? this.initialStateObject.usize.toString() : ''
        this.stateEquipmentName = this.initialStateObject.name
        this.modelImageSrc = this.initialStateObject.imagePath
        if (this.modelImageSrc) this.initialImage = this.initialStateObject.name
      },
      editModel () {
        this.loadingBuild = true
        this.localBus.$emit('removeModelAlertMessage')
        const imageRequest = this.modelImageInput ? {
          fileName: this.stateEquipmentName,
          fileType: this.modelImageInput.type.split('/')[1],
          modelId: this.initialStateObject.id,
          base64ImageString: this.modelImageSrc.substr(this.modelImageSrc.indexOf(',') + 1),
        } : null

        ewcApi.editEquipmentModel(this.initialStateObject.id, {
          name: this.stateEquipmentName,
          imagePath: this.initialImage ? this.initialStateObject.imagePath : '',
          uSize: this.stateUSize,
          capacity: !this.stateEquipmentContains ? 0 : this.stateModelRows && this.stateModelColumns ? this.stateModelRows * this.stateModelColumns : this.stateModelRows,
          isRackMounted: (this.stateEquipmentStandAlone === 'No' && this.stateEquipmentContains === 'Yes') || this.stateIsEnclosureMountable === 'No',
          modelRows: this.stateModelRows,
          modelColumns: this.stateModelColumns,
          imageRequest,
        })
          .then((response) => {
            console.log(response)
            this.resetState()
            this.loadingBuild = false
            this.localBus.$emit('editModelSuccess', this.snackBarMessageEditModelSuccess)
          })
          .catch((error) => {
            console.log(error)
            this.loadingBuild = false
            if (error.response?.status === 409) {
              this.alertMessage = this.alertMessageUniqueName
              this.uniqueModelArray.push(this.stateEquipmentName)
            } else if (error.response?.status === 417) {
              this.alertMessage = this.alertMessageImageUploadFail
            } else {
              this.alertMessage = this.alertMessageGeneral
            }
            this.localBus.$emit('showEditModelAlertMessage', this.alertMessage)
            common.handleBadCall(error, this.$router)
          })
      },
      editModelName () {
        this.loadingBuild = true
        this.localBus.$emit('removeModelAlertMessage')
        const imageRequest = this.modelImageInput ? {
          fileName: this.stateEquipmentName,
          fileType: this.modelImageInput.type.split('/')[1],
          modelId: this.initialStateObject.id,
          base64ImageString: this.modelImageSrc.substr(this.modelImageSrc.indexOf(',') + 1),
        } : null

        ewcApi.editEquipmentModelName(this.initialStateObject.id, this.stateEquipmentName, imageRequest)
          .then((response) => {
            console.log(response)
            this.resetState()
            this.loadingBuild = false
            this.localBus.$emit('editModelSuccess', this.snackBarMessageEditModelSuccess)
          })
          .catch((error) => {
            console.log(error)
            this.loadingBuild = false
            if (error.response?.status === 409) {
              this.alertMessage = this.alertMessageUniqueName
              this.uniqueModelArray.push(this.stateEquipmentName)
            } else if (error.response?.status === 417) {
              this.alertMessage = this.alertMessageImageUploadFail
            } else {
              this.alertMessage = this.alertMessageGeneral
            }
            this.localBus.$emit('showEditModelAlertMessage', this.alertMessage)
            common.handleBadCall(error, this.$router)
          })
      },

      async uploadImage (event) {
        if (!event || event.length === 0) {
          this.removeSrcImage()
        } else {
          await this.toBase64(event)
            .then(result => {
              this.modelImageSrc = result
              this.initialImage = ''
            })
            .catch(error => {
              console.log(error)
              this.alertMessage = this.alertMessageImageUploadFail
              this.localBus.$emit('showEditModelAlertMessage', this.alertMessage)
            })
        }
      },

      toBase64 (file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = () => resolve(reader.result)
          reader.onerror = error => reject(error)
        })
      },

      removeSrcImage (event) {
        this.modelImageSrc = ''
        this.modelImageInput = null
        this.initialImage = ''
      },

    },
  }
</script>
