export const convertToCSV = (data) => {
   //  incoming format --  data[{name, equipmentName, dataSet {'x': "date string", "y": "number"}, axislabels} etc..]

   let result = ''

   // add the top columns to the file
   result += 'Equipment,Time,Value\n'

   // loop through different equipment readings
   for (const item of data) {
    for (const dataPoint of item.data) {
        result += item.label + ',' + dataPoint.x + ',' + dataPoint.y + '\n'
    }
   }
   return result
}

export const styleEquipmentName = (name) => {
    return name.replaceAll('_', ' ')
}

export const convertAlarmsToCSV = (data) => {
    let result = ''

    result += 'Time,Severity,Equipment Name, Alarm Description, Data Value, Alarm Status\n'

    for (const item of data) {
            result += item.time + ',' + item.severity + ',' + item.equipmentName + ',' + item.alarmDescription + ',' + item.dataValue + ',' + item.alarmStatus + '\n'
    }
    return result
}

export const convertEwcEquipmentToCSV = (data) => {
    let result = ''

    result += 'Label,Status,Serial,Asset Tag,Model,Rack Location,Unit Location,Bay,Notes,Asset Id,Project Number,Project Name,Department,Acquisition Date,Decommission date,Disposal Date,Description\n'

    for (const item of data) {
            result +=
            '"' + (item.label ? item.label : '') + '"' + ',' +
            '"' + (item.status ? item.status : '') + '"' + ',' +
            '"' + (item.serial ? item.serial : '') + '"' + ',' +
            '"' + (item.assetTag ? item.assetTag : '') + '"' + ',' +
            '"' + (item.model ? item.model : '') + '"' + ',' +
            '"' + (item.rackLocation ? item.rackLocation : '') + '"' + ',' +
            '"' + (item.unitLocation ? item.unitLocation : '') + '"' + ',' +
            '"' + (item.bay ? item.bay : '') + '"' + ',' +
            '"' + (item.notes ? item.notes : '') + '"' + ',' +
            '"' + (item.assetId ? item.assetId : '') + '"' + ',' +
            '"' + (item.projectNumber ? item.projectNumber : '') + '"' + ',' +
            '"' + (item.projectName ? item.projectName : '') + '"' + ',' +
            '"' + (item.departmentNumber ? item.departmentNumber : '') + '"' + ',' +
            '"' + (item.acquisitionDate ? item.acquisitionDate.split('T')[0] : '') + '"' + ',' +
            '"' + (item.decommissionDate ? item.decommissionDate.split('T')[0] : '') + '"' + ',' +
            '"' + (item.disposalDate ? item.disposalDate.split('T')[0] : '') + '"' + ',' +
            '"' + (item.description ? item.description : '') + '"' + '\n'
    }
    return result
}

export const convertEwcEquipmentToCSVAllSites = (data) => {
    let result = ''

    result += 'Label,Status,Serial,Asset Tag,Model,Site,Floorplan,Rack Location,Unit Location,Bay,Notes,Asset Id,Project Number,Project Name,Department,Acquisition Date,Decommission date,Disposal Date,Description\n'

    for (const item of data) {
            result +=
            '"' + (item.label ? item.label : '') + '"' + ',' +
            '"' + (item.status ? item.status : '') + '"' + ',' +
            '"' + (item.serial ? item.serial : '') + '"' + ',' +
            '"' + (item.assetTag ? item.assetTag : '') + '"' + ',' +
            '"' + (item.modelName ? item.modelName : '') + '"' + ',' +
            '"' + (item.siteName ? item.siteName : '') + '"' + ',' +
            '"' + (item.gridName ? item.gridName : '') + '"' + ',' +
            '"' + (item.rackLocation ? item.rackLocation : '') + '"' + ',' +
            '"' + (item.unitLocation ? item.unitLocation : '') + '"' + ',' +
            '"' + (item.bay ? item.bay : '') + '"' + ',' +
            '"' + (item.notes ? item.notes : '') + '"' + ',' +
            '"' + (item.assetId ? item.assetId : '') + '"' + ',' +
            '"' + (item.projectNumber ? item.projectNumber : '') + '"' + ',' +
            '"' + (item.projectName ? item.projectName : '') + '"' + ',' +
            '"' + (item.departmentNumber ? item.departmentNumber : '') + '"' + ',' +
            '"' + (item.acquisitionDate ? item.acquisitionDate.split('T')[0] : '') + '"' + ',' +
            '"' + (item.decommissionDate ? item.decommissionDate.split('T')[0] : '') + '"' + ',' +
            '"' + (item.disposalDate ? item.disposalDate.split('T')[0] : '') + '"' + ',' +
            '"' + (item.description ? item.description : '') + '"' + '\n'
    }
    return result
}

export const convertEwcEquipmentToCSVAllFloorplans = (data) => {
    let result = ''

    result += 'Label,Status,Serial,Asset Tag,Model,Floorplan,Rack Location,Unit Location,Bay,Notes,Asset Id,Project Number,Project Name,Department,Acquisition Date,Decommission date,Disposal Date,Description\n'

    for (const item of data) {
            result +=
            '"' + (item.label ? item.label : '') + '"' + ',' +
            '"' + (item.status ? item.status : '') + '"' + ',' +
            '"' + (item.serial ? item.serial : '') + '"' + ',' +
            '"' + (item.assetTag ? item.assetTag : '') + '"' + ',' +
            '"' + (item.modelName ? item.modelName : '') + '"' + ',' +
            '"' + (item.gridName ? item.gridName : '') + '"' + ',' +
            '"' + (item.rackLocation ? item.rackLocation : '') + '"' + ',' +
            '"' + (item.unitLocation ? item.unitLocation : '') + '"' + ',' +
            '"' + (item.bay ? item.bay : '') + '"' + ',' +
            '"' + (item.notes ? item.notes : '') + '"' + ',' +
            '"' + (item.assetId ? item.assetId : '') + '"' + ',' +
            '"' + (item.projectNumber ? item.projectNumber : '') + '"' + ',' +
            '"' + (item.projectName ? item.projectName : '') + '"' + ',' +
            '"' + (item.departmentNumber ? item.departmentNumber : '') + '"' + ',' +
            '"' + (item.acquisitionDate ? item.acquisitionDate.split('T')[0] : '') + '"' + ',' +
            '"' + (item.decommissionDate ? item.decommissionDate.split('T')[0] : '') + '"' + ',' +
            '"' + (item.disposalDate ? item.disposalDate.split('T')[0] : '') + '"' + ',' +
            '"' + (item.description ? item.description : '') + '"' + '\n'
    }
    return result
}

export const convertUserDataToCsv = (users) => {
    let result = ''

    result += 'Email,Name,Role\n'

    for (const user of users) {
        result +=
        '"' + user.email + '"' + ',' +
        '"' + user.name + '"' + ',' +
        '"' + user.userRole + '"' + ',' + '\n'
    }
    return result
}

export const convertAuditTablesToCsv = (missingTable, newTable) => {
    let result = ''

    result += 'Table,Label,Serial,Asset Tag,Model,Site,Floorplan,Rack Location,Unit Location,Bay,Notes\n'

    for (const item of missingTable) {
        result +=
        '"' + 'MISSING' + '"' + ',' +
        '"' + (item.label ? item.label : '') + '"' + ',' +
        '"' + (item.serial ? item.serial : '') + '"' + ',' +
        '"' + (item.assetTag ? item.assetTag : '') + '"' + ',' +
        '"' + (item.modelName ? item.modelName : '') + '"' + ',' +
        '"' + (item.gridName ? item.gridName : '') + '"' + ',' +
        '"' + (item.rackLocation ? item.rackLocation : '') + '"' + ',' +
        '"' + (item.unitLocation ? item.unitLocation : '') + '"' + ',' +
        '"' + (item.bay ? item.bay : '') + '"' + ',' +
        '"' + (item.notes ? item.notes : '') + '"' + '\n'
    }
    for (const item of newTable) {
        result +=
        '"' + 'NEW' + '"' + ',' +
        '"' + (item.label ? item.label : '') + '"' + ',' +
        '"' + (item.serial ? item.serial : '') + '"' + ',' +
        '"' + (item.assetTag ? item.assetTag : '') + '"' + ',' +
        '"' + (item.modelName ? item.modelName : '') + '"' + ',' +
        '"' + (item.gridName ? item.gridName : '') + '"' + ',' +
        '"' + (item.rackLocation ? item.rackLocation : '') + '"' + ',' +
        '"' + (item.unitLocation ? item.unitLocation : '') + '"' + ',' +
        '"' + (item.bay ? item.bay : '') + '"' + ',' +
        '"' + (item.notes ? item.notes : '') + '"' + '\n'
    }

    return result
}
