<template>
  <v-container
    fluid
    style="width: 100%; height: 800px;"
  >
    <v-card
      v-if="loading"
      class="d-flex justify-center align-center flex-wrap"
      style="width: 100%; height: 100%; "
    >
      <v-progress-circular

        indeterminate
        color="grey"
      />
    </v-card>
    <v-card
      v-else-if="selectedNetworkEquipment"
      class="d-flex justify-center flex-wrap"
      style="width: 100%; height: 100%; "
    >
      <v-toolbar
        class="pt-3 mt-3"
        width="100%"
        height="15%"
        flat
      >
        <v-card-title
          class="text-h4 font-weight-bold grey--text"
        >
          Equipment Network Information
        </v-card-title>
      </v-toolbar>
      <v-card
        class="mx-6 mt-3 mb-1 d-flex flex-wrap"
        color="rgb(219, 195, 171)"
        width="80%"
        height="415"
        raised
      >
        <v-card-text
          class="ml-6 pb-0 text-h5 font-weight-bold black--text"
        >
          <div>{{ selectedNetworkEquipment.label || selectedNetworkEquipment.equipmentModelNetworkInformation.model }} </div>
          <v-img
            v-if="selectedNetworkEquipment.equipmentModelNetworkInformation.imagePath"
            :aspect-ratio="16/9"
            height="200"
            width="200"
            contain
            :src="selectedNetworkEquipment.equipmentModelNetworkInformation.imagePath"
          />
          <div
            v-else
            style="height: 200px; width: 200px;"
            class="d-flex justify-center align-center"
          >
            <v-icon x-large>
              {{ mdiImageOffOutline }}
            </v-icon>
          </div>

          <div class="mb-2">
            Rack Location: {{ selectedNetworkEquipment.location }}
          </div>
          <div class="mb-2">
            Connection Type: {{ selectedNetworkEquipment.connectionProtocol }}
          </div>
          <div class="mb-2">
            Connection Speed: {{ selectedNetworkEquipment.equipmentModelNetworkInformation.speed ? selectedNetworkEquipment.equipmentModelNetworkInformation.speed + ' mbps' : 'n/a' }}
          </div>
          <div class="mb-2">
            Total Ports: {{ selectedNetworkEquipment.equipmentModelNetworkInformation.ports || 'n/a' }}
          </div>
          <div class="mb-2">
            Active Connections: {{ selectedNetworkEquipment.activeConnections + (selectedNetworkEquipment.connections ? selectedNetworkEquipment.connections.length : 0) }}
          </div>
        </v-card-text>
      </v-card>
      <div
        class="d-flex justify-center"
        style="width:100%;"
      >
        <v-btn
          color="green"
          dark
          class="font-weight-bold"
          @click="localBus.$emit('open-update-network-model', selectedNetworkEquipment.equipmentModelNetworkInformation)"
        >
          Update Network Model Information
        </v-btn>
      </div>
      <div
        class="d-flex justify-center"
        style="width:100%;"
      >
        <v-btn
          color="green"
          dark
          class="font-weight-bold"
          @click="$emit('open-network-model-setup')"
        >
          Update Connection Type
        </v-btn>
      </div>
      <div
        class="d-flex justify-center"
        style="width:100%;"
      >
        <v-btn
          dark
          color="red darken-1"
          class="font-weight-bold"
          @click="showRemoveNetworkEquipmentDialog = true"
        >
          Remove Equipment
        </v-btn>
      </div>
      <div
        class="d-flex justify-center"
        style="width:100%;"
      >
        <v-btn
          :disabled="selectedNetworkEquipment.layerType !== 'ACCESS'"
          color="blue darken-1"
          dark
          class="font-weight-bold"
          @click="openNetworkRackConnections = true"
        >
          View Rack Equipment Connections
        </v-btn>
      </div>
    </v-card>
    <v-card
      v-else
      style="width: 100%; height: 100%; "
      :class="`d-flex align-center justify-start  flex-wrap`"
    >
      <v-card-text
        class="text-h4 text-center font-weight-bold mx-16"
      >
        Click on a piece of network equipment to see more information about it
      </v-card-text>
    </v-card>
    <v-dialog
      v-if="selectedNetworkEquipment && selectedNetworkEquipment.layerType === 'ACCESS'"
      v-model="openNetworkRackConnections"
      width="800"
    >
      <ewc-network-rack-connections
        :key="networkRackConnectionsKey"
        :local-bus="localBus"
        :network-equipment="selectedNetworkEquipment"
      />
    </v-dialog>
    <v-dialog
      v-if="selectedNetworkEquipment"
      v-model="showRemoveNetworkEquipmentDialog"
      :persistent="removingNetworkEquipment"
      width="660"
    >
      <v-card class="pa-4 px-6">
        <v-card-title class="text-h5 pl-0 grey--text">
          Remove Network Equipment
        </v-card-title>
        <v-card-text class="black--text font-weight-medium">
          <div>Please confirm removing {{ selectedNetworkEquipment.label || selectedNetworkEquipment.equipmentModelNetworkInformation.model }} from the network map.</div>
          <div>
            Warning: any equipment connections will be removed along with this piece of equipment
          </div>
          <div class="d-flex justify-center">
            <v-btn
              :loading="removingNetworkEquipment"
              color="red darken-1 white--text"
              class="font-weight-bold ma-4"
              @click="removeEquipmentFromNetworkMapEwc"
            >
              Remove
            </v-btn>
            <v-btn
              color="grey"
              class="font-weight-bold white--text ma-4"
              @click="showRemoveNetworkEquipmentDialog = false"
            >
              Cancel
            </v-btn>
          </div>
        </v-card-text>
        <v-alert
          v-if="showRemoveNetworkEquipmentAlert"
          type="error"
          class="mt-2 mb-0"
        >
          {{ removeNetworkEquipmentAlertMessage }}
        </v-alert>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { mdiImageOffOutline } from '@mdi/js'
  import networkApi from '@/api/network_ewc.js'
  import common from '@/api/common.js'

  export default {
    name: 'EwcNetworkEquipmentInformation',
    props: {
      localBus: {
        type: Object,
      },
      loading: Boolean,
    },
    data: () => ({
      selectedNetworkEquipment: null,
      mdiImageOffOutline: mdiImageOffOutline,
      openNetworkRackConnections: false,
      networkRackConnections: [],

      showRemoveNetworkEquipmentDialog: false,
      removingNetworkEquipment: false,
      showRemoveNetworkEquipmentAlert: false,
      removeNetworkEquipmentAlertMessage: '',
      alertMessageGeneral: 'We were unable to complete your request at this time. Please, try again later.',
    }),
    computed: {
      networkRackConnectionsKey () {
        if (!this.selectedNetworkEquipment || !this.selectedNetworkEquipment.connections) return -1
        return this.selectedNetworkEquipment.connections.length
      },
    },
    created () {
      this.localBus.$on('clearSelectedNetworkEquipment', () => {
        this.selectedNetworkEquipment = null
      })
      this.localBus.$on('selectNetworkEquipment', equipment => {
        this.selectedNetworkEquipment = equipment
      })
      this.localBus.$on('close-network-rack-connections', () => {
        this.openNetworkRackConnections = false
      })
      this.localBus.$on('update-network-model-success', () => {
        this.selectedNetworkEquipment = null
      })
    },

    methods: {
      removeEquipmentFromNetworkMapEwc () {
        this.removingNetworkEquipment = true
        this.showRemoveNetworkEquipmentAlert = false
        networkApi.removeEquipmentFromNetworkMapEwc(this.selectedNetworkEquipment.equipmentId)
          .then(response => {
            console.log(response)
            this.localBus.$emit('remove-network-equipment-success')
            this.selectedNetworkEquipment = null
          })
          .catch(async error => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              this.removeEquipmentFromNetworkMapEwc()
            } else {
              this.showRemoveNetworkEquipmentAlert = true
              this.removeNetworkEquipmentAlertMessage = this.alertMessageGeneral
            }
          })
          .finally(() => { this.removingNetworkEquipment = false })
      },
    },
  }
</script>
