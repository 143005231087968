import axios from 'axios'
import common from '@/api/common.js'

const api = axios.create()

export default {
    getNetworkEquipment: function (gridId) {
    console.log('getNetworkEquipment()', gridId)

    const headers = common.createHeaders()

    return api({
      method: 'get',
      headers: headers,
      url: `${common.getUrlPrefix()}/ewc/network/get/${gridId}`,
    })
  },

  addNetworkEquipment (addNetworkEquipmentRequest) {
    console.log('addNetworkEquipment()', addNetworkEquipmentRequest)

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/ewc/network/add/network-equipment`,
      data: { ...addNetworkEquipmentRequest },
    })
  },

  updateNetworkModel (modelNetworkUpdateRequest) {
    console.log('updateNetworkModel()', modelNetworkUpdateRequest)

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/ewc/network/update/model`,
      data: { ...modelNetworkUpdateRequest },
    })
  },

  removeEquipmentFromNetworkMapEwc (equipmentId) {
    console.log('removeEquipmentFromNetworkMapEwc()', equipmentId)

    const headers = common.createHeaders()

    return api({
      method: 'delete',
      headers: headers,
      url: `${common.getUrlPrefix()}/ewc/network/remove/equipment/${equipmentId}`,
    })
  },

  upsertNetworkConnection (networkConnectionUpdateRequest) {
    console.log('upsertNetworkConnection()', networkConnectionUpdateRequest)

    const headers = common.createHeaders()

    return api({
      method: 'put',
      headers: headers,
      url: `${common.getUrlPrefix()}/ewc/network/update/connection`,
      data: { ...networkConnectionUpdateRequest },
    })
  },

  removeNetworkConnection (rackEquipmentId, networkEquipmentId) {
    console.log('removeNetworkConnection()', rackEquipmentId, networkEquipmentId)

    const headers = common.createHeaders()

    return api({
      method: 'delete',
      headers: headers,
      url: `${common.getUrlPrefix()}/ewc/network/remove/connection/${rackEquipmentId}/${networkEquipmentId}`,
    })
  },

  batchInsertNetworkToRackEquipmentConnections (connectionSelections) {
    console.log('batchInsertNetworkToRackEquipmentConnections()', connectionSelections)

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/ewc/network/batch/add/connections`,
      data: { ...connectionSelections },
    })
  },
}
