import axios from 'axios'
import common from '@/api/common.js'

const api = axios.create()

export default {
  getFloor: function () {
    console.log('getFloor()')

    const headers = common.createHeaders()

    return api({
      method: 'get',
      headers: headers,
      url: `${common.getUrlPrefix()}/ewc/getFloor`,
    })
  },

  getDataCentersForUser: function () {
    console.log('getDataCentersForUser()')

    const headers = common.createHeaders()

    return api({
      method: 'get',
      headers: headers,
      url: `${common.getUrlPrefix()}/dc/grid/get`,
    })
  },

  equipmentAddRequest (equipment) {
    console.log('equipmentAddRequest()', equipment)

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/equipment/ewc/add/request`,
      data: { ...equipment },
    })
  },

  updateAddRequest (requestId, requestData) {
    console.log('updateAddRequest', requestId, requestData)

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/equipment/ewc/add/request/update/${requestId}`,
      data: { ...requestData },
    })
  },

  acceptEquipmentEwcRequest (requestId) {
    console.log('acceptEquipmentEwcRequest', requestId)

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/equipment/ewc/accept/request/${requestId}`,
    })
  },

  editEquipmentRequestEwc (requestData) {
    console.log('editEquipmentRequestEwc', requestData)

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/equipment/ewc/edit/request`,
      data: { ...requestData },
    })
  },

  updateEditRequest (requestData, requestId) {
    console.log('updateEditRequest', requestData, requestId)

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/equipment/ewc/edit/request/update/${requestId}`,
      data: { ...requestData },
    })
  },

  deleteEquipmentEwcRequest (requestId) {
    console.log('deleteEquipmentEwcRequest', requestId)

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/equipment/ewc/request/delete/${requestId}`,
    })
  },

  getEquipmentRequestDescription (requestId) {
    console.log('getEquipmentRequestDescription()', requestId)

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/equipment/ewc/request/description/${requestId}`,
    })
  },
  canEditEquipment (equipmentId) {
    console.log('canEditEquipment()', equipmentId)

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/equipment/ewc/request/edit/unlocked/${equipmentId}`,
    })
  },

  getCompletedRequestForSite (gridId, skip, take) {
    console.log('getCompletedRequestForSite()', gridId, skip, take)

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/equipment/ewc/request/complete/${gridId}`,
      data: { skip, take },
    })
  },

  deleteEquipmentModel (modelId) {
    console.log('deleteEquipmentModel()', modelId)

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/equipment/ewc/delete/model/${modelId}`,
    })
  },

  canEditEquipmentModel (modelId) {
    console.log('canEditEquipmentModel()', modelId)

    const headers = common.createHeaders()

    return api({
      method: 'get',
      headers: headers,
      url: `${common.getUrlPrefix()}/equipment/ewc/able/edit/model/${modelId}`,
    })
  },

  editEquipmentModel (id, model) {
    console.log('editEquipmentModel()', id, model)

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/equipment/ewc/edit/model/${id}`,
      data: { ...model },
    })
  },

  editEquipmentModelName (id, newName, imageRequest) {
    console.log('editEquipmentModelName()', id, newName, imageRequest)

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/equipment/ewc/edit/model/${id}/name`,
      data: {
        modelId: id,
        newEquipmentModelName: newName,
        imageRequest,
      },
    })
  },

  powerMapTest () {
    console.log('powerMapTest()')

    const headers = common.createHeaders()

    return api({
      method: 'get',
      headers: headers,
      url: `${common.getUrlPrefix()}/power/ewc/test/table/powermap/1`,
    })
  },

  uploadBase64Image (fileName, modelFileType, modelId, base64ImageString) {
    console.log('uploadBase64Image()', fileName, modelFileType, modelId, base64ImageString)

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/image/ewc/upload`,
      data: {
        fileName,
        base64ImageString,
        modelFileType,
        modelId,
      },
    })
  },

  getEquipmentBySerialOrAssetTag (serial, assetTag) {
    console.log('getEquipmentBySerialOrAssetTag()', serial, assetTag)

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/equipment/ewc/search/all`,
      data: {
        serial,
        assetTag,
      },
    })
  },

  getAllEquipmentEwcForTenant () {
    console.log('getAllEquipmentEwcForTenant()')

    const headers = common.createHeaders()

    return api({
      method: 'get',
      headers: headers,
      url: `${common.getUrlPrefix()}/equipment/ewc/get/csv/all`,
    })
  },

  getAllEquipmentEwcForSite (siteId) {
    console.log('getAllEquipmentEwcForSite()', siteId)

    const headers = common.createHeaders()

    return api({
      method: 'get',
      headers: headers,
      url: `${common.getUrlPrefix()}/equipment/ewc/get/csv/site/${siteId}`,
    })
  },

  getAllEquipmentEwcForFloorplan (gridId) {
    console.log('getAllEquipmentEwcForFloorplan()', gridId)

    const headers = common.createHeaders()

    return api({
      method: 'get',
      headers: headers,
      url: `${common.getUrlPrefix()}/equipment/ewc/get/csv/floorplan/${gridId}`,
    })
  },

  updateEwcWalls (gridId, gridJson) {
    console.log('updateEwcWalls()', gridId, gridJson)

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/equipment/ewc/update/walls`,
      data: {
        gridId,
        gridJson,
      },
    })
  },

  addPowerStripEwc (powerStripData) {
    console.log('addPowerStripEwc()', powerStripData)

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/power/ewc/add/power-strip`,
      data: {
        ...powerStripData,
      },
    })
  },

  editPowerStripEwc (powerStripData) {
    console.log('editPowerStripEwc()', powerStripData)

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/power/ewc/edit/power-strip`,
      data: {
        ...powerStripData,
      },
    })
  },

  deletePowerStripEwc (powerStripId) {
    console.log('deletePowerStripEwc()', powerStripId)

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/power/ewc/delete/power-strip/${powerStripId}`,
    })
  },

  updateModelPowerInformationEwc (modelPowerInformation) {
    console.log('updateModelPowerInformationEwc()', modelPowerInformation)

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/power/ewc/update/model-power`,
      data: { ...modelPowerInformation },
    })
  },
  getModelPowerInformation () {
    console.log('getModelPowerInformation()')

    const headers = common.createHeaders()

    return api({
      method: 'get',
      headers: headers,
      url: `${common.getUrlPrefix()}/power/ewc/get/model-power-information`,
    })
  },

  updatePowerStripToRackItemEwc (equipmentId, gridId, powerStripConnections) {
    console.log('updatePowerStripToRackItemEwc()', equipmentId, gridId, powerStripConnections)

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/power/ewc/update/rack-item/power-connections/${gridId}/${equipmentId}`,
      data: JSON.stringify(powerStripConnections),
    })
  },

  insertAuditPotentialRemoved (equipmentEwcId) {
    console.log('insertAuditPotentialRemoved()', equipmentEwcId)

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/ewc/audit/add/missing`,
      data: JSON.stringify(equipmentEwcId),
    })
  },

  getAllAuditMissingEquipment () {
    console.log('getAllAuditMissingEquipment()')

    const headers = common.createHeaders()

    return api({
      method: 'get',
      headers: headers,
      url: `${common.getUrlPrefix()}/ewc/audit/get/missing`,
    })
  },

  deleteAuditPotentialRemoved (equipmentId) {
    console.log('deleteAuditPotentialRemoved()', equipmentId)

    const headers = common.createHeaders()

    return api({
      method: 'delete',
      headers: headers,
      url: `${common.getUrlPrefix()}/ewc/audit/remove/missing/${equipmentId}`,
    })
  },

  insertAuditPotentialRemovedUsingSerialOrAssetTag (serial, assetTag) {
    console.log('insertAuditPotentialRemovedUsingSerialOrAssetTag()', serial, assetTag)

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/ewc/audit/add/missing/using-serial-or-asset-tag`,
      data: {
        serial,
        assetTag,
      },
    })
  },

  insertAuditPotentialAdded (potentialAddedRequest) {
    console.log('insertAuditPotentialAdded()', potentialAddedRequest)

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/ewc/audit/add/new`,
      data: { ...potentialAddedRequest },
    })
  },
  updateAuditPotentialAdded (potentialAddedRequest) {
    console.log('updateAuditPotentialAdded()', potentialAddedRequest)

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/ewc/audit/update/new`,
      data: { ...potentialAddedRequest },
    })
  },

  getAllAuditNewEquipment () {
    console.log('getAllAuditNewEquipment()')

    const headers = common.createHeaders()

    return api({
      method: 'get',
      headers: headers,
      url: `${common.getUrlPrefix()}/ewc/audit/get/new`,
    })
  },

  deleteAuditPotentialAdded (potentialAddedId) {
    console.log('deleteAuditPotentialAdded()', potentialAddedId)

    const headers = common.createHeaders()

    return api({
      method: 'delete',
      headers: headers,
      url: `${common.getUrlPrefix()}/ewc/audit/remove/new/${potentialAddedId}`,
    })
  },

  insertAuditVerified (equipmentEwcId) {
    console.log('insertAuditVerified()', equipmentEwcId)

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/ewc/audit/add/verified`,
      data: JSON.stringify(equipmentEwcId),
    })
  },
  deleteAuditVerified (equipmentId) {
    console.log('deleteAuditVerified()', equipmentId)

    const headers = common.createHeaders()

    return api({
      method: 'delete',
      headers: headers,
      url: `${common.getUrlPrefix()}/ewc/audit/remove/verified/${equipmentId}`,
    })
  },
  getAllAuditVerified (gridId) {
    console.log('getAllAuditVerified()', gridId)

    const headers = common.createHeaders()

    return api({
      method: 'get',
      headers: headers,
      url: `${common.getUrlPrefix()}/ewc/audit/get/verified/${gridId}`,
    })
  },
  getEquipmentEwcForSitePlusAuditNew (gridId) {
    console.log('getEquipmentEwcForSitePlusAudit()', gridId)

    const headers = common.createHeaders()

    return api({
      method: 'get',
      headers: headers,
      url: `${common.getUrlPrefix()}/ewc/audit/get/${gridId}`,
    })
  },
  convertAllPotentiallyNewAuditEquipmentToEquipmentTable () {
    console.log('convertAllPotentiallyNewAuditEquipmentToEquipmentTable()')

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/ewc/audit/sync/equipment`,
    })
  },

}
