<template>
  <v-container
    fluid
    style="width: 100%; height: 100%"
  >
    <v-skeleton-loader
      v-if="loadingFloorplan"
      width="100%"
      height="100%"
      type="image"
    />
    <div
      v-else
      id="svgContainer"
      ref="svgContainer"
      style="width: 100%; height: 100%;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0);
        border-radius: 6px;"
    >
      <svg
        id="svgImage"
        ref="svgImage"
        height="100%"
        width="100%"
        style="background: white;"
      >
        <svg
          id="miniGrid"
          height="100%"
          width="100%"
          style="background: #cfcfcf;"
        >
          <template
            v-for="(position) in gridArray"
          >
            <grid-square
              :key="`x:${position.x}y:${position.y}`"
              :position="position"
              :square-pixel-width="50"
              :square-pixel-height="50"
              :selectedItems="selectedItems"
              :local-bus="localBus"
              :selectable="false"
            />
          </template>
        </svg>
        <svg
          id="gridEquipmentItems"
          :key="selectedItemId"
          height="100%"
          width="100%"
        >
          <template v-for="floorItem in floorData">
            <grid-floor-item
              :key="floorItem.name"
              :floorItemData="floorItem"
              :selectedItemId="selectedItemId"
              :local-bus="localBus"
              :selectable="false"
            />
          </template>
          <template v-for="pendingItem in pendingData">
            <grid-floor-item
              :key="pendingItem.equipmentData.id"
              :floorItemData="pendingItem"
              :selectedItemId="selectedItemId"
              :local-bus="localBus"
              :selectable="false"
            />
          </template>
          <template v-for="auditItem in auditData">
            <grid-floor-item
              :key="auditItem.equipmentData.id"
              :floorItemData="auditItem"
              :selectedItemId="selectedItemId"
              :local-bus="localBus"
              :selectable="false"
            />
          </template>
        </svg>

        <template v-for="(xLabel, i) in xLabelsArray">
          <text
            :key="`xLabel${xLabel}`"
            :y="-8"
            :x="i*50 + 25 - xLabel.length * 4"
            style="font-size: 20px; font-weight: bold;"
          >
            {{ xLabel }}
          </text>
        </template>
        <template v-for="(yLabel, i) in yLabelsArray">
          <text
            :key="`yLabel${yLabel}`"
            :y="i*50 + 30"
            :x="-2/3 * gridXSize - 10 * yLabel.length"
            style="font-size: 20px; font-weight: bold;"
          >
            {{ yLabel }}
          </text>
        </template>
        <template
          v-for="(position) in selectedPartialItems"
        >
          <grid-square
            :key="`grid-half-square-x:${position.x}y:${position.y}`"
            :position="position"
            :half="floorSelectionType === 'half-left-right' ? 'horizontal' : 'vertical'"
            :square-pixel-width="floorSelectionType === 'half-left-right' ? 25 : 50"
            :square-pixel-height="floorSelectionType === 'half-left-right' ? 50 : 25"
            :selectedItems="selectedPartialItems"
            :local-bus="localBus"
          />
        </template>
        <svg
          id="gridWalls"
          height="100%"
          width="100%"
        >
          <template v-for="wallPiece in ewcWallData">
            <rect
              :key="`${wallPiece.x}-${wallPiece.y}`"
              :x="wallPiece.x * 50"
              :y="wallPiece.y * 50"
              :style="wallFillStyle"
              width="50"
              height="50"
              @mouseover="(e) => localBus.$emit('display-tooltip', ({ e: e, label: 'Wall'}))"
            />
          </template>
        </svg>
      </svg>
    </div>
  </v-container>
</template>

<script>
  import fillStyles from './fillStyles'

  export default {
    name: 'GridWrapperMinimap',
    props: {
      localBus: {
        type: Object,
      },
      floorplan: Object,
      loadingFloorplan: Boolean,
      initialStateObject: Object,
      pendingEquipmentEwc: Array,
      auditEquipmentEwc: Array,
    },
    data: () => ({
      gridXSize: 0,
      gridYSize: 0,
      floorData: [],

      viewBoxX: 0,
      viewBoxY: -45,
      viewBoxW: 0,
      viewBoxH: 0,
      svgSizeW: 0,
      svgSizeH: 0,
      isPanning: false,
      startPointX: 0,
      startPointY: 0,
      endPointX: 0,
      endpointY: 0,
      scale: 1,
      gridArray: [],
      xLabelsArray: [],
      yLabelsArray: [],

      selectedItems: [],
      selectedItemId: -1,
      viewboxIsSetup: false,

      selectedPartialItems: [],
      floorSelectionType: 'whole',

      ewcWallData: [],
      wallFillStyle: fillStyles.wallFillStyle,

    }),
    computed: {
      pendingData () {
        if (!this.pendingEquipmentEwc) return []

        return this.pendingEquipmentEwc.filter(
          pendingItem => pendingItem.equipmentData.id !== this.initialStateObject?.equipmentData.id &&
            !pendingItem.location.parentEquipmentEwcId,
        )
      },
      auditData () {
        if (!this.auditEquipmentEwc) return []

        return this.auditEquipmentEwc.filter(
          auditItem => auditItem.equipmentData.id !== this.initialStateObject?.equipmentData.id &&
            !auditItem.location.parentEquipmentEwcId && !auditItem.location.parentAuditEquipmentId,
        )
      },
    },
    watch: {
      floorplan (newFloorplan, oldFloorplan) {
        if (Object.keys(newFloorplan).length > 0) {
          this.setGrid(newFloorplan)
        }
      },
    },
    created () {
      this.localBus.$on('setMinimapFloorLocation', (selectedItems, selectedPartialItems, floorSelectionType) => {
        this.selectedItems = [...selectedItems]
        if (selectedPartialItems) {
          this.selectedPartialItems = [...selectedPartialItems]
          this.floorSelectionType = floorSelectionType
        }
        this.setGrid(this.floorplan)
      })
      this.localBus.$on('setMinimapRackLocation', selectedLocation => {
        this.selectedItemId = selectedLocation.selectedFloorItem.equipmentData.id
        this.setGrid(this.floorplan)
      })
      this.localBus.$on('setMinimapEnclosureLocation', selectedLocation => {
        this.selectedItemId = selectedLocation.selectedFloorItem.equipmentData.id
        this.setGrid(this.floorplan)
      })
      this.localBus.$on('clearSelectedItems', () => {
        this.selectedItems = []
        this.selectedPartialItems = []
        this.selectedItemId = -1
      })
    },

    methods: {
      setGrid: function (gridData) {
        if (!this.$refs.svgImage || Object.keys(this.floorplan).length === 0) return
        this.gridXSize = gridData.dataCenterGrid.xsize
        this.gridYSize = gridData.dataCenterGrid.ysize
        this.floorData = gridData.activeEquipmentEwc.filter(equipment => equipment.equipmentData.id !== this.initialStateObject?.equipmentData.id && equipment.equipmentData.id !== this.initialStateObject?.equipmentToEditId &&
          !equipment.equipmentData.unitLocation && !equipment.equipmentData.bay)
        this.xLabelsArray = gridData.dataCenterGrid.xlabels
        this.yLabelsArray = gridData.dataCenterGrid.ylabels

        this.ewcWallData = JSON.parse(this.floorplan.ewcWall.wallArray).map(wallSquareString => {
          const splitWallSquareStringArray = wallSquareString.split('-')
          return {
            x: parseInt(splitWallSquareStringArray[0]),
            y: parseInt(splitWallSquareStringArray[1]),
          }
        })

        let x = 0
        let y = 0
        this.gridArray = []
        for (let i = 0; i < this.gridXSize * this.gridYSize; i++) {
          this.gridArray.push({ x, y })
          if (x < this.gridXSize - 1) {
            x++
          } else {
            x = 0
            y++
          }
        }
        this.viewBoxH = this.gridYSize * 50 + 150
        this.viewBoxW = this.gridXSize * 50 + 150
        this.viewBoxX = -(this.viewBoxW / 2 - this.gridXSize * 50 / 2)
        this.viewBoxY = -(this.viewBoxH / 2 - this.gridYSize * 50 / 2)

        this.$refs.svgImage.setAttribute('viewBox', `${this.viewBoxX} ${this.viewBoxY} ${this.viewBoxW} ${this.viewBoxH}`)
      },
    },
  }
</script>
