<template>
  <v-container>
    <v-row>
      <material-card
        v-bind="$attrs"
        class="v-card--material-chartjs"
        full-header
        style="width: 100%"
      >
        <template #heading>
          <div
            class="pa-4"
            :style="extendHeight ? 'height: 500px' : ''"
          >
            <v-progress-circular
              v-if="showChartLoader"
              indeterminate
            />
            <div
              v-if="showChartError"
              class="font-weight-light text--secondary"
            >
              <div v-html="chartErrorMessage" />
            </div>
            <canvas
              v-show="!showChartLoader"
              :id="'chart' + chartInitialData.id + isDialog"
            />
          </div>
        </template>

        <template #subtitle>
          <div
            class="font-weight-light text--secondary"
          >
            <div v-html="chartInitialData.title" />
          </div>
        </template>

        <template
          #actions
        >
          <v-icon
            class="mr-1"
            small
          >
            mdi-clock-outline
          </v-icon>

          <span
            class="text-caption grey--text font-weight-light"
            v-text="lastChartUpdate"
          />
        </template>
      </material-card>
    </v-row>
    <v-row>
      <v-dialog
        v-model="showChartTitleDialog"
        persistent
        max-width="400"
      >
        <v-card class="pa-4 px-6">
          <v-card-title class="text-h5 pl-0">
            New chart name?
          </v-card-title>
          <v-text-field
            v-model="chartInitialData.title"
            label="Title"
            :rules="[rules.required]"
          />
          <v-card-actions>
            <v-spacer />
            <v-btn
              id="equipmentSaveChartConfirmationButton"
              color="green darken-1"
              text
              @click="saveChart()"
            >
              Save
            </v-btn>
            <v-btn
              color="grey darken-1"
              text
              @click="cancelSave"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="showErrorDialog"
        persistent
        max-width="290"
      >
        <v-card>
          <v-card-title class="text-h5">
            Error
          </v-card-title>
          <v-card-text>
            {{ errorDialogText }}
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="green darken-1"
              text
              @click="showErrorDialog = false"
            >
              Okay
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="chartDeleteConfirmation"
        persistent
        max-width="450"
      >
        <v-card class="pa-4 px-4">
          <v-card-title class="text-h5 mb-3 justify-center">
            Are you sure you want to delete: &nbsp; <span>{{ chartInitialData.title }}</span>
          </v-card-title>
          <v-card-actions class="d-flex justify-center">
            <v-btn
              id="deleteChartConfirmationButton"
              color="red darken-1"
              text
              :loading="chartDeleteLoader"
              @click="deleteChart"
            >
              Delete
            </v-btn>
            <v-btn
              color="grey darken-1"
              text
              @click="chartDeleteConfirmation = false"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
  import Chart from 'chart.js/auto'
  import 'chartjs-adapter-moment'
  import zoomPlugin from 'chartjs-plugin-zoom'
  import equipmentApi from '@/api/equipment.js'
  import dashboardApi from '@/api/dashboard.js'
  import common from '@/api/common.js'
  import { convertToCSV } from '@/util/converters'
  import { bus } from '@/main'

  export default {
    name: 'MaterialChartJsCard',

    props: {
      chartInitialData: {
        type: Object,
        default: () => ({}),
      },
      isDialog: {
        type: String,
        default: '',
      },
      localBus: {
        type: Object,
      },
      isHistoric: {
        type: Boolean,
        default: false,
      },
      isDashboard: {
        type: Boolean,
        default: false,
      },
      extendHeight: Boolean,
    },

    data: () => ({
      lineChart: null,
      chartDisplayData: {
        chartTitle: 'Chart Preview',
        datasets: [],
        options: {
          parsing: false,
        },
      },
      shouldRemoveLabel: false,
      lastChartUpdate: '',
      showChartTitleDialog: false,
      showSnackbar: false,
      color: 'info',
      showChartLoader: true,
      showChartError: false,
      chartErrorMessage: 'There was a problem loading this chart. Please try again later.',

      showErrorDialog: false,
      errorDialogText: '',
      chartDeleteConfirmation: false,
      chartDeleteLoader: false,

      dates: [],

      pennyPoller: null,

      colorPalette: ['#d73027', '#f46d43', '#fdae61', '#fee090', '#ffffbf', '#e0f3f8', '#abd9e9', '#74add1', '#4575b4'],
      dict: [],
      rules: {
        required: value => !!value || 'Required.',
      },
    }),
    created () {
      if (this.localBus) {
        this.localBus.$on('downloadCSV', (filename) => {
          this.downloadCSV(filename)
        })
        this.localBus.$on('downloadImage', (filename) => {
          this.downloadChartImage(filename)
        })
        this.localBus.$on('deleteChart', () => {
          this.chartDeleteConfirmation = true
        })
        this.localBus.$on('saveChart', () => {
          this.showSaveChartDialog()
        })
        this.localBus.$on('changeColors', (colors) => {
          this.updateColors(colors)
        })
        this.localBus.$on('setChartHistoryData', (dates) => {
          this.setChartHistoryData(dates)
        })
        this.localBus.$on('resetZoom', () => {
          this.lineChart.resetZoom()
        })
      }
    },
    mounted () {
      this.createChart()

      if (this.isHistoric) {
        if (this.localBus) this.localBus.$emit('chartLoaded')
      } else {
        this.getFirst60Minutes()
      }
    },

    beforeDestroy () {
      console.log('closing poller ' + this.isDialog)
      clearInterval(this.pennyPoller)
    },

    methods: {
      createChart: function () {
        Chart.register({
          id: 'background-fill',
          beforeDraw: (chart) => {
            const ctx = chart.canvas.getContext('2d')
            ctx.save()
            ctx.globalCompositeOperation = 'destination-over'
            ctx.fillStyle = 'white'
            ctx.fillRect(0, 0, chart.width, chart.height)
            ctx.restore()
          },
        })
        Chart.register(zoomPlugin)
        this.lineChart = new Chart(document.getElementById('chart' + this.chartInitialData.id + this.isDialog), {
          type: 'line',
          data: this.chartDisplayData,
          options: {
            maintainAspectRatio: !this.extendHeight,
            resizeDelay: 50,
            title: {
              display: true,
              text: this.chartInitialData.title,
            },
            scales: {
              x: {
                type: 'time',
                time: {
                  parser: 'YYYY-MM-DDTHH:mm:ss',
                  displayFormats: {
                    second: this.isHistoric ? 'MMMM DD HH:mm' : 'HH:mm',
                    minute: this.isHistoric ? 'MMMM DD HH:mm' : 'HH:mm',
                    hour: this.isHistoric ? 'MMMM DD HH:mm' : 'HH:mm',
                  },
                },
              },
            },
            plugins: {
              zoom: {
                pan: {
                  enabled: true,
                  modifierKey: 'ctrl',
                  mode: 'x',
                },
                limits: {
                  x: null,
                },
              },
            },
          },

        })

        // some kind of hacky stuff to add hover to interactable chart features and selectively emit opening chart dialog
        // hopefully chartjs 3 updates event propagation to go from sub elements to parent ones
        document.getElementById('chart' + this.chartInitialData.id + this.isDialog).addEventListener('click', (e) => {
          const { left, right, top, bottom } = this.lineChart.chartArea
          if (
            event.offsetX > left &&
            event.offsetX < right &&
            event.offsetY > top &&
            event.offsetY < bottom
          ) {
            bus.$emit('openDashboard', this.chartInitialData)
          }
        })
        document.getElementById('chart' + this.chartInitialData.id + this.isDialog).addEventListener('mousemove', (e) => {
          let cursor = false
          const chartArea = this.lineChart.chartArea
          const legendHitBoxes = this.lineChart.legend.legendHitBoxes
          for (const box of legendHitBoxes) {
            if (event.offsetX > box.left &&
              event.offsetX < box.left + box.width &&
              event.offsetY > box.top &&
              event.offsetY < box.top + box.height) {
              cursor = true
            }
          }
          if (
            this.isDashboard === true &&
            event.offsetX > chartArea.left &&
            event.offsetX < chartArea.right &&
            event.offsetY > chartArea.top &&
            event.offsetY < chartArea.bottom) {
            cursor = true
          }
          if (cursor === true) {
            document.getElementById('chart' + this.chartInitialData.id + this.isDialog).style.cursor = 'pointer'
          } else {
            document.getElementById('chart' + this.chartInitialData.id + this.isDialog).style.cursor = 'default'
          }
        })
      },

      getFirst60Minutes: function () {
        equipmentApi.getInitialChartData(this.chartInitialData.datasets)
          .then(response => {
            console.log('getFirst60Minutes()')
            console.log(response)
            var whatColorAreWeOn = 0
            var largestSet = 0
            const colorList = this.chartInitialData.colorList[0] !== ''
              ? this.chartInitialData.colorList
              : this.colorPalette

            response.data.forEach(chartItem => {
              const label = chartItem.equipmentName + '-' + chartItem.name
              const dataSetLength = chartItem.dataSet.length
              // Future note: for some reason, the A in Axis is lowercase, but the variable in the backend is read
              // xAxisLabels. Curious and a possible issue in the future.
              if (dataSetLength > largestSet) {
                this.chartDisplayData.datasets.unshift(
                  {
                    data: chartItem.dataSet.map(dataPoint => { return { ...dataPoint, x: new Date(dataPoint.x) } }),
                    label: label, // this is for the name of the line for the legend
                    borderColor: colorList[whatColorAreWeOn],
                    backgroundColor: colorList[whatColorAreWeOn],
                  },
                )
                largestSet = dataSetLength
              } else {
                this.chartDisplayData.datasets.push(
                  {
                    data: chartItem.dataSet.map(dataPoint => { return { ...dataPoint, x: new Date(dataPoint.x) } }),
                    label: label, // this is for the name of the line for the legend
                    borderColor: colorList[whatColorAreWeOn],
                    backgroundColor: colorList[whatColorAreWeOn],
                  },
                )
              }

              if (whatColorAreWeOn === this.colorPalette.length - 1) {
                whatColorAreWeOn = 0
              } else {
                whatColorAreWeOn++
              }
            })

            this.lineChart.options.plugins.zoom.pan = {
              enabled: false,
            }

            this.updateChart()
            this.pennyPoller = setInterval(function () {
              this.startDataTrend()
            }.bind(this), 60000) // 1 minute = 60 seconds * 1000 miliseconds
          })
          .catch(error => {
            console.log(error)
            common.handleBadCall(error, this.$router)
            this.showChartError = true
          })
      },

      startDataTrend: function () {
        equipmentApi.getLastMinuteOfChartData(this.chartInitialData.datasets)
          .then(response => {
            console.log(response.data)
            // get the new data points from response
            // for each, figure out where they belong
            this.shouldRemoveLabel = false
            response.data.forEach(chartItem => {
              // dataset label, not the minutes label on x-axis
              const label = chartItem.equipmentName + '-' + chartItem.name
              for (var i = 0; i < this.chartDisplayData.datasets.length; i++) {
                // item is found with combined equipName and dataPoint and we update that array
                if (this.chartDisplayData.datasets[i].label === label) {
                  this.chartDisplayData.datasets[i].data =
                    this.chartDisplayData.datasets[i].data.concat(chartItem.dataSet.map(dataPoint => { return { ...dataPoint, x: new Date(dataPoint.x) } })) // put the new one in front

                  if (this.chartDisplayData.datasets[i].data.length >= 60) {
                    this.chartDisplayData.datasets[i].data.shift() // remove the oldest point
                    // can't remove label here, that could happen multiple times if multiple data sets have 60+ points
                    this.shouldRemoveLabel = true
                  }
                }
              }
            })

            if (this.shouldRemoveLabel) {
              this.chartDisplayData.labels.shift()
            }

            console.log(this.chartDisplayData)
            this.updateChart()
          })
          .catch(error => {
            console.log(error)
            common.handleBadCall(error, this.$router)
            this.showChartError = true
          })
      },

      updateChart: function () {
        if (this.lineChart) {
          console.log('updating chart' + 'chart' + this.chartInitialData.title)
          this.chartDisplayData.datasets.forEach(item => {

          })
          console.log(this.chartDisplayData.labels.length)

          this.lastChartUpdate = new Date()
          this.lineChart.update()
          this.showChartLoader = false
          if (this.localBus) this.localBus.$emit('chartLoaded')
        }
      },

      showSaveChartDialog: function () {
        this.showChartTitleDialog = true
      },

      saveChart: function () {
        this.showChartTitleDialog = false
        dashboardApi.saveChartForDashboard(this.chartInitialData.title, this.chartInitialData.datasets, this.colorPalette, this.$route.params.rootSiteId)
          .then(response => {
            console.log(response)
            bus.$emit('chartSaved')
          })
          .catch(error => {
            console.log(error)
            common.handleBadCall(error, this.$router)
            this.localBus.$emit('saveError')
            this.errorDialogText = 'Unable to save chart at this time. Please try again later.'
            this.showErrorDialog = true
          })
      },

      cancelSave: function () {
        this.showChartTitleDialog = false
        this.localBus.$emit('saveError')
      },

      downloadCSV: function (filename) {
        const csv = convertToCSV(this.chartDisplayData.datasets)
        const encodedData = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
        const link = document.createElement('a')

        link.setAttribute('href', encodedData)
        link.setAttribute('download', `${filename}`)
        link.click()
      },

      downloadChartImage: function (filename) {
        const link = document.createElement('a')
        link.setAttribute('href', this.lineChart.toBase64Image())
        link.setAttribute('download', `${filename}`)
        link.click()
      },

      deleteChart: function () {
        this.chartDeleteLoader = true
        dashboardApi.removeChart(this.chartInitialData.id)
          .then(response => {
            console.log(response)
            bus.$emit('chartDelete', this.chartInitialData.id)
            bus.$emit('close')
            this.chartDeleteLoader = false
          }).catch(error => {
            console.log(error)
            this.errorDialogText = 'There was a problem removing this chart, please try again later.'
            this.showErrorDialog = true
            this.chartDeleteLoader = false
            common.handleBadCall(error, this.$router)
          })
      },

      updateColors: function (colors) {
        this.colorPalette = colors
        let whatColorAreWeOn = 0

        this.chartDisplayData.datasets.forEach((dataset, i) => {
          this.chartDisplayData.datasets[i] = {
            data: dataset.data,
            label: dataset.label,
            borderColor: this.colorPalette[whatColorAreWeOn],
            backgroundColor: this.colorPalette[whatColorAreWeOn],
          }
          whatColorAreWeOn++
          if (whatColorAreWeOn >= this.colorPalette.length) whatColorAreWeOn = 0
        })

        this.updateChart()
      },

      setChartHistoryData: function (dates) {
        let dateObject = {}

        // TODO add functionality for more specific time instead of hardcoding 0's
        if (dates.length === 1 || dates[0] === dates[1]) {
          dateObject = {
            startDate: dates[0] + 'T00:00:00.000Z',
            endDate: null,
            chartDataList: this.chartInitialData.datasets,
          }
        } else {
          if (dates[0].split('-').join() > dates[1].split('-').join()) {
            dateObject = {
              startDate: dates[1] + 'T00:00:00.000Z',
              endDate: dates[0] + 'T00:00:00.000Z',
              chartDataList: this.chartInitialData.datasets,
            }
          } else {
            dateObject = {
              startDate: dates[0] + 'T00:00:00.000Z',
              endDate: dates[1] + 'T00:00:00.000Z',
              chartDataList: this.chartInitialData.datasets,
            }
          }
        }

        equipmentApi.getByDateRange(dateObject)
          .then(response => {
            console.log(response.data)
            this.chartDisplayData.datasets = []
            var whatColorAreWeOn = 0
            var largestSet = 0
            const colorList = this.chartInitialData.colorList[0] !== ''
              ? this.chartInitialData.colorList
              : this.colorPalette
            const emptyDatasets = []

            response.data.forEach(chartItem => {
              const label = chartItem.equipmentName + '-' + chartItem.name
              const dataSetLength = chartItem.dataSet.length

              if (dataSetLength === 0) {
                emptyDatasets.push(label)
              }

              if (dataSetLength > largestSet) {
                this.chartDisplayData.datasets.unshift(
                  {
                    data: chartItem.dataSet,
                    label: label,
                    borderColor: colorList[whatColorAreWeOn],
                    backgroundColor: colorList[whatColorAreWeOn],
                  },
                )
                largestSet = dataSetLength
              } else {
                this.chartDisplayData.datasets.push(
                  {
                    data: chartItem.dataSet,
                    label: label, // this is for the name of the line for the legend
                    borderColor: colorList[whatColorAreWeOn],
                    backgroundColor: colorList[whatColorAreWeOn],
                  },
                )
              }

              if (whatColorAreWeOn === this.colorPalette.length - 1) {
                whatColorAreWeOn = 0
              } else {
                whatColorAreWeOn++
              }
            })

            this.lineChart.options.plugins.zoom.zoom = {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: 'x',
              drag: {
                enabled: true,
              },
            }

            // easy way to limit zoom and pan - update the chart with new data, then get limits from new chart then update the chart with the limits
            this.updateChart()
            this.lineChart.resetZoom()
            this.lineChart.options.plugins.zoom.limits.x = { min: this.lineChart.scales.x.min, max: this.lineChart.scales.x.max, minRange: 1000000 }
            this.updateChart()

            if (emptyDatasets.length === 0) {
              this.localBus.$emit('getByDateRangeSuccess')
            } else {
              this.localBus.$emit('getByDateRangePartialSuccess', emptyDatasets, this.chartDisplayData.datasets.length)
            }
          })
          .catch(error => {
            console.log(error)
            if (error.response.status === 404) {
              this.localBus.$emit('getByDateRangeError', 404)
            } else {
              this.localBus.$emit('getByDateRangeError')
            }
            common.handleBadCall(error, this.$router)
            this.showChartError = true
          })
      },
    },

  }
</script>
