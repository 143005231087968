import axios from 'axios'
import common from '@/api/common.js'
import cookie from '@/api/cookie.js'

export default {
  login: function (username, password, tenantId) {
    const api = axios.create()
    console.log('login()')
    /*
    api.interceptors.request.use(request => {
      console.log('Starting Request', JSON.stringify(request, null, 2))
      return request
    })
    */

    return api({
      method: 'post',
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
      url: `${common.getUrlPrefix()}/auth/login`,
      data: {
        username: username,
        password: password,
        tenantId: tenantId,
      },
    })
  },

  tenantId: function () {
    const api = axios.create()
    console.log('tenantId()')
    /*
    api.interceptors.request.use(request => {
      console.log('Starting Request', JSON.stringify(request, null, 2))
      return request
    })
    */

    const headers = common.createHeaders()

    return api({
      method: 'get',
      headers: headers,
      url: `${common.getUrlPrefix()}/auth/tenant`,
    })
  },

  refreshToken () {
    const api = axios.create()

    const headers = {
      'Content-Type': 'text/plain',
      Authorization: 'Bearer ' + cookie.getCookie('jwt'),
      'Access-Control-Allow-Origin': '*',
    }
    console.log('refreshToken()')

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/auth/refresh`,
      data: cookie.getCookie('refreshToken'),
      })
  },
}
