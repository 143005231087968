<template>
  <v-container
    id="chart-dialog"
    fluid
  >
    <v-dialog
      id="dialog"
      v-model="dialog"
      fullscreen
      style="position: relative"
    >
      <v-sheet
        style="min-height: 100vh; overflow: hidden; background: #EEEEEE"
      >
        <v-btn
          color="grey darken-1"
          class="ma-6"
          text
          style="position: absolute; z-index: 2;"
          @click="closeDialog()"
        >
          <v-icon>
            mdi-close-thick
          </v-icon>
        </v-btn>

        <v-row
          no-gutters
          justify="center"
          :class="`${$vuetify.breakpoint.lgAndUp ? '' : 'mt-14'}`"
        >
          <v-col
            cols="12"
            lg="9"
          >
            <chart-tools
              :chart-initial-data="chartInitialData"
              :local-bus="localBus"
              :is-preview="isPreview"
              class="mt-4 mb-1"
            />
          </v-col>
          <v-col
            cols="12"
            lg="9"
          >
            <material-chart-js-card
              is-dialog="true"
              :local-bus="localBus"
              :chart-initial-data="chartInitialData"
            />
          </v-col>
        </v-row>
      </v-sheet>
    </v-dialog>
  </v-container>
</template>

<script>
  import Vue from 'vue'

  export default {
    name: 'ChartDialog',

    props: {
      chartInitialData: {
        type: Object,
        default: () => ({}),
      },
      isPreview: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      dialog: true,
      chartData: [],
      modalChartIndex: -1,
      showChartDialog: false,
      chartDialogData: null,

      localBus: new Vue(),

    }),

    methods: {
      closeDialog: function () {
        this.$emit('close-dialog')
      },
    },
  }
</script>
<style scoped>
.dialog {
  background: white;
}
</style>
