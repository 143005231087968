<template>
  <v-container
    fluid
    style="width: 100%; height: 100%;"
  >
    <v-card
      v-if="Object.keys(selectedItem).length > 0"
      class="d-flex justify-center align-center flex-wrap"
      style="width: 100%; height: 100%; "
    >
      <v-toolbar
        class="mt-2 pb-2"
        width="100%"
        height="15%"
        flat
      >
        <v-card-title
          class="text-h4 font-weight-bold grey--text"
        >
          {{ selectedItem.equipmentModel.name }}
        </v-card-title>
        <div
          v-if="superAdmin"
          class="text-h4 font-weight-medium green--text"
        >
          Audit Verified
        </div>
        <v-checkbox
          v-if="superAdmin && !insertingAuditVerified && !deletingAuditVerified"
          v-model="verifiedCheckbox"
          color="green"
          class="mt-5 ml-3"
          @change="event => {
            if(event){
              insertAuditVerified()
            }else{
              deleteAuditVerified()
            }
          }"
        />
        <v-progress-circular
          v-if="(superAdmin && insertingAuditVerified) || (superAdmin && deletingAuditVerified)"
          indeterminant
          class="ml-3"
        />
      </v-toolbar>
      <div
        v-if="showRackItem"
        :key="selectedItem.equipmentData.id"
        style=" width: 95%; height: 85%;"
        class="d-flex align-center justify-space-between"
      >
        <v-img
          v-if="powerLeft"
          src="@/assets/power_strip.png"
          height="85%"
          contain
          width="40"
          class="ml-6"
          :gradient="leftPowerStripSelected ? 'to top right, rgba(109, 224, 237, .3), rgba(109, 224, 237, .3)' : ''"
          style="cursor: pointer;"
          @click="selectPowerStrip('left')"
        />
        <ewc-rack
          :update-svg="updateSvg"
          :rack-data="selectedItem"
          :set-location-dialog="setLocationDialog"
          :set-rack-location="setRackLocation"
          :set-enclosure-location="setEnclosureLocation"
          :local-bus="localBus"
          :initial-state-object="initialStateObject"
          :pending-equipment-ewc="pendingEquipmentEwc"
          :audit-equipment-ewc="auditEquipmentEwc"
        />
        <v-img
          v-if="powerRight"
          src="@/assets/power_strip.png"
          height="85%"
          contain
          width="40"
          style="cursor: pointer;"
          class="mr-6"
          :gradient="rightPowerStripSelected ? 'to top right, rgba(109, 224, 237, .3), rgba(109, 224, 237, .3)' : ''"
          @click="selectPowerStrip('right')"
        />
      </div>
      <div
        v-else
        style=" width: 95%; height: 85%;"
        class="d-flex align-center justify-space-between"
      >
        <v-img
          v-if="powerLeft"
          src="@/assets/power_strip.png"
          height="85%"
          contain
          width="40"
          class="ml-6"
          :gradient="leftPowerStripSelected ? 'to top right, rgba(109, 224, 237, .3), rgba(109, 224, 237, .3)' : ''"
          style="cursor: pointer;"
          @click="selectPowerStrip('left')"
        />
        <v-img
          v-if="selectedItem.equipmentModel.imagePath"
          :src="selectedItem.equipmentModel.imagePath"
          max-height="100%"
          contain
        />
        <div
          v-else
          class="d-flex justify-center"
          style="width: 100%;"
        >
          <v-icon>
            {{ mdiImage }}
          </v-icon>
          <span> Image not available </span>
        </div>
        <v-img
          v-if="powerRight"
          src="@/assets/power_strip.png"
          height="85%"
          contain
          width="40"
          style="cursor: pointer;"
          class="mr-6"
          :gradient="rightPowerStripSelected ? 'to top right, rgba(109, 224, 237, .3), rgba(109, 224, 237, .3)' : ''"
          @click="selectPowerStrip('right')"
        />
      </div>
      <div
        class="pb-2"
        style="margin-top: -12px;"
      >
        Rack Location: {{ selectedItem.equipmentData.rackLocation }}
      </div>
    </v-card>
    <v-card
      v-else
      style="width: 100%; height: 100%; "
      :class="`d-flex ${gridlessItem ? 'align-start' : 'align-center'} justify-start  flex-wrap`"
    >
      <v-card-title
        v-if="gridlessItem"
        class="text-h4 font-weight-bold grey--text pt-3 mt-1 ml-2"
      >
        {{ gridlessItem.model }}
      </v-card-title>
      <v-card-text
        class="text-h4 text-center font-weight-bold mx-16"
      >
        {{ emptyCardMessage }}
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import { mdiImage } from '@mdi/js'
  import store from '@/store'
  import ewcApi from '@/api/ewc.js'
  import common from '@/api/common.js'

  export default {
    name: 'EwcInformationCard',
    props: {
      localBus: {
        type: Object,
      },
      setLocationDialog: Boolean,
      setRackLocation: Boolean,
      setEnclosureLocation: Boolean,
      gridlessItem: Object,
      updateSvg: Number,
      initialStateObject: Object,
      pendingEquipmentEwc: Array,
      auditEquipmentEwc: Array,
      powerLeft: Object,
      powerRight: Object,
    },
    data: () => ({
      mdiImage: mdiImage,

      selectedItem: {},
      leftPowerStripSelected: false,
      rightPowerStripSelected: false,
      selectedPowerEquipment: [],

      insertingAuditVerified: false,
      deletingAuditVerified: false,
      verifiedCheckbox: false,
      auditVerifiedRacks: [],
    }),
    computed: {
      emptyCardMessage () {
        if (this.gridlessItem) {
          return 'This equipment does not have a specified location in the data center.'
        } else {
          return this.setLocationDialog ? 'Select a rack from the floor' : 'Click an item on the map to see more information about it'
        }
      },
      showRackItem () {
        return this.selectedItem.equipmentModel.capacity > 0
      },
      superAdmin: function () {
        return store.get('user/role') === 3
      },
    },
    created () {
      this.localBus.$on('onSelectFloorItem', (floorItem) => {
        this.selectedItem = floorItem
        if (store.get('user/role') === 3 && Object.keys(this.selectedItem).length > 0) {
          this.verifiedCheckbox = false
          this.auditVerifiedRacks.forEach(rack => {
            if (rack.equipmentEwcId === this.selectedItem.equipmentData.id) {
              this.verifiedCheckbox = true
            }
          })
        }
      })

      this.localBus.$on('openRackSetLocationDialog', (rectangles, selectedItem) => {
        this.selectedItem = selectedItem
        this.$nextTick(() => this.localBus.$emit('openRackSetLocationDialogInitialRectangles', rectangles))
      })

      this.localBus.$on('clearSelectedItems', () => {
        this.selectedItem = {}
      })
      this.localBus.$on('clearSelectedFloorItem', () => {
        this.selectedItem = {}
      })

      this.localBus.$on('clearSelectedPowerStrip', () => {
        this.leftPowerStripSelected = false
        this.rightPowerStripSelected = false
      })

      this.localBus.$on('audit-verified-racks', racks => {
        this.auditVerifiedRacks = racks
      })
    },
    methods: {
      selectPowerStrip (powerStripSide) {
        if (powerStripSide === 'left') {
          this.rightPowerStripSelected = false
          this.leftPowerStripSelected = !this.leftPowerStripSelected
          if (this.leftPowerStripSelected) {
            this.localBus.$emit('selectPowerStrip', this.powerLeft)
            // TODO add rack items when power strip is selected
            // this.selectedPowerEquipment =
          } else {
            this.localBus.$emit('deselectPowerStrip')
          }
        } else {
          this.leftPowerStripSelected = false
          this.rightPowerStripSelected = !this.rightPowerStripSelected
          this.rightPowerStripSelected ? this.localBus.$emit('selectPowerStrip', this.powerRight) : this.localBus.$emit('deselectPowerStrip')
        }
      },
      insertAuditVerified () {
        this.insertingAuditVerified = true
        ewcApi.insertAuditVerified(this.selectedItem.equipmentData.id)
          .then(response => {
            console.log(response)
            this.localBus.$emit('change-verified-success')
          })
          .catch(async error => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              this.insertAuditVerified()
            } else {
              this.localBus.$emit('audit-verify-error', 'There was an error saving verification for this rack')
            }
          })
          .finally(() => {
            this.insertingAuditVerified = false
          })
      },
      deleteAuditVerified () {
        this.deletingAuditVerified = true
        this.showAlert = false
        ewcApi.deleteAuditVerified(this.selectedItem.equipmentData.id)
          .then(result => {
            console.log(result)
            this.localBus.$emit('change-verified-success')
          })
          .catch(async error => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              this.deleteAuditVerified()
            } else {
              this.localBus.$emit('audit-verification-error', 'There was an error removing verification for this rack')
            }
          })
          .finally(() => { this.deletingAuditVerified = false })
      },

    },
  }
</script>
