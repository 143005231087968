// Pathify
import { make } from 'vuex-pathify'
import { BreadCrumbInitiators, BreadCrumbEwcBases } from '@/util/enums'
import { mdiFloorPlan, mdiMap, mdiViewDashboard } from '@mdi/js'

// Data
const state = {
  drawer: null,
  drawerImage: true,
  mini: false,
  items: [
    {
      title: 'Dashboard',
      icon: mdiViewDashboard,
      to: '/dashboard',
    },
    {
      title: 'Site',
      icon: mdiMap,
      to: '/site',
    },
    {
      title: 'EWC',
      icon: mdiFloorPlan,
      to: '/ewc/',
    },
  ],
  breadCrumbs: [],
  historicData: {},
  pendingRequests: 0,
  disableEwc: false,

}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),
  init: async ({ dispatch }) => {
    //
  },
  // resets items to original state
  setClearItems: ({ commit }) => {
    commit('items', [...state.items])
  },
  setAddItem: ({ commit, state }, newItem) => {
    // check if Admin has already been added
    for (const item of state.items) {
      if (item.title === newItem.title) return
    }

    commit('items', [...state.items, newItem])
  },
  setRemoveAdmin: (context) => {
    context.commit('items', [...state.items])
  },
  setAddBreadCrumb: ({ commit, state }, crumb) => {
    // check if url is directly from the dashboard
    if (BreadCrumbInitiators.includes(crumb.title)) {
     return commit('breadCrumbs', [{ title: crumb.title, to: crumb.to }])
    }

    // check if url is already part of the crumb trail
    for (let i = 0; i < state.breadCrumbs.length; i++) {
        console.log(state.breadCrumbs[i], crumb.title)
        if (crumb.title === state.breadCrumbs[i].title) {
       return commit('breadCrumbs', state.breadCrumbs.slice(0, i + 1))
      }
    }

    // check if url is a base page of ewc (ewc home, add equipment, requests, models)
    if (BreadCrumbEwcBases.includes(crumb.title)) {
      return commit('breadCrumbs', [{ title: 'EWC', to: '/ewc/' }, { title: crumb.title, to: crumb.to }])
    }

    // add new url to the crumb trail
    commit('breadCrumbs',
    [...state.breadCrumbs,
      {
        title: crumb.title,
        to: crumb.to,
      },
    ])
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
