<template>
  <v-container
    fluid
    style="width: 100%; height: 200px;"
  >
    <v-card
      class="d-flex justify-center flex-wrap"
      style="width: 100%; height: 100%; "
    >
      <v-toolbar
        class="pt-3"
        width="100%"
        height="20%"
        flat
      >
        <v-card-title
          class="text-h4 font-weight-bold grey--text"
        >
          {{ `${displayState === 'powerStrip' ? 'Power Strip' : displayState === 'rackItem' ? 'Rack Item' : 'Extra Power'} Information` }}
        </v-card-title>
      </v-toolbar>
      <div
        class="d-flex align-center"
        style="width: 100%;"
      >
        <v-icon class="ml-6 mr-2">
          mdi-lightning-bolt
        </v-icon>
        <span> {{ `Load: ${powerLoad} ` }}</span>
      </div>
      <div
        class="d-flex align-center"
        style="width: 100%;"
      >
        <v-icon class="ml-6 mr-2">
          mdi-power-standby
        </v-icon>
        <span> {{ `Powered By: ${powerSource} ` }}</span>
      </div>
      <v-btn
        v-if="displayState === 'powerStrip'"
        text
        color="green darken-1"
        class="font-weight-bold"
        @click="$emit('open-edit-power-strip')"
      >
        Edit
      </v-btn>
      <v-btn
        v-if="displayState === 'powerStrip'"
        text
        color="red darken-1"
        class="font-weight-bold"
        @click="$emit('open-remove-power-strip')"
      >
        Remove
      </v-btn>
      <template v-else-if="displayState === 'rackItem'">
        <v-btn
          text
          color="green darken-1"
          class="font-weight-bold"
          @click="$emit('open-rack-item-power-setup')"
        >
          Power Setup
        </v-btn>
        <v-btn
          text
          color="blue darken-1"
          class="font-weight-bold"
          @click="$emit('open-rack-item-model-setup')"
        >
          Update Model Power Information
        </v-btn>
      </template>
      <v-btn
        v-else
        :disabled="disableAddPowerStrip"
        text
        color="green darken-1"
        class="font-weight-bold"
        @click="$emit('open-add-power-strip')"
      >
        Add Power Strip
      </v-btn>
    </v-card>
  </v-container>
</template>

<script>

  export default {
    name: 'EwcExtraPowerDetailsBox',
    props: {
      localBus: Object,
      powerLoad: Number,
      powerSource: String,
      displayState: String,
      disableAddPowerStrip: Boolean,
    },
    data: () => ({
    }),
    methods: {
    },
  }
</script>
