export default {
  createCookie: function (key, value, days) {
    let expires
    if (days) {
      const date = new Date()
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
      expires = '; expires=' + date.toGMTString()
    } else {
      expires = ''
    }
    document.cookie = key + '=' + value + expires + '; path=/'
  },

  getCookie: function (key) {
    if (document.cookie.length > 0) {
      let cStart = document.cookie.indexOf(key + '=')
      if (cStart !== -1) {
        cStart = cStart + key.length + 1
        let cEnd = document.cookie.indexOf(';', cStart)
        if (cEnd === -1) {
          cEnd = document.cookie.length
        }
        return unescape(document.cookie.substring(cStart, cEnd))
      }
    }
    return ''
  },

  // Deleting a cookie is basically setting its expiry to sometime in the past
  // Which is the same as re-creating it with a past date
  // "Hang the kettle on the wall, run the makeTeaWithKettleOnWall program"
  deleteCookie: function (key) {
    this.createCookie(key, this.getCookie(key), -1000)
  },
}
