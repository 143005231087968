<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col
        cols="12"
        lg="8"
        style="position: relative;"
      >
        <div
          style="height: 800px; width: 100%;"
        >
          <ewc-traditional-network-main-card
            :key="gridId"
            :local-bus="localBus"
            :grid-id="gridId"
          />
        </div>
      </v-col>
      <v-col
        :class="`${$vuetify.breakpoint.lgAndUp ? 'pl-0 py-6' : 'pl-6 py-0'} pr-6`"
        cols="12"
        lg="4"
      >
        <v-row>
          <v-col
            class="pa-0"
            cols="12"
          >
            <div :style="`height: ${600}px; width: 100%;`">
              <ewc-network-equipment-information
                :local-bus="localBus"
                :loading="false"
              />
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showAddNetworkEquipmentDialog"
      persistent
      width="800"
    >
      <ewc-add-network-equipment
        :key="gridId"
        :selected-floor-id="gridId"
        :local-bus="localBus"
        :active-equipment="floorplan.activeEquipmentEwc"
        :layer-type="addNetworkEquipmentLayerType"
        :network-equipment="networkEquipmentData"
        :selected-network-equipment="selectedNetworkEquipment"
      />
    </v-dialog>
    <v-dialog
      v-model="showAddNetworkConnectionDialog"
      persistent
      width="80%"
    >
      <ewc-add-network-connection
        :key="gridId"
        :selected-floor-id="gridId"
        :local-bus="localBus"
        :active-equipment="floorplan.activeEquipmentEwc"
        :network-equipment-list="networkEquipmentData"
        :selected-network-equipment="selectedNetworkEquipment"
      />
    </v-dialog>
    <v-dialog
      v-model="showUpdateNetworkModelDialog"
      persistent
      width="650"
    >
      <ewc-update-network-model-information
        :key="networkModelInformation.modelId"
        :local-bus="localBus"
        :network-model-information="networkModelInformation"
      />
    </v-dialog>
    <material-snackbar
      v-model="showSnackBar"
      type="info"
      timeout="3000"
      class="ml-12"
      v-bind="{
        ['bottom']: true,
        ['left']: true
      }"
    >
      {{ snackBarMessage }}
    </material-snackbar>
  </v-container>
</template>

<script>
  import networkApi from '@/api/network_ewc.js'
  import common from '@/api/common.js'

  export default {
    name: 'EwcNetworkMainContainer',
    props: {
      localBus: {
        type: Object,
      },
      gridId: Number,
      floorplan: Object,
    },
    data: () => ({
      networkEquipmentData: null,
      selectedNetworkEquipment: null,
      noGridHasBeenSetup: false,
      loadingNetworkEquipment: true,

      addNetworkEquipmentLayerType: '',
      showAddNetworkEquipmentDialog: false,

      showSnackBar: false,
      snackBarMessage: '',
      snackBarMessageAddNetworkEquipment: 'Successfully added network equipment!',
      snackBarMessageAddNetworkConnection: 'Successfully added network connection!',
      snackBarMessageAddNetworkConnections: 'Successfully added network connections!',
      snackBarMessageUpdateNetworkModel: 'Successfully updated network model information!',
      snackBarMessageRemoveNetworkEquipment: 'Successfully removed equipment from network map!',
      snackBarMessageRemoveNetworkConnection: 'Successfully removed equipment from connections list!',
      snackBarMessageUpdateNetworkConnection: 'Successfully updated connection!',

      networkModelInformation: {},
      showUpdateNetworkModelDialog: false,

      showAddNetworkConnectionDialog: false,
    }),
    computed: {
    },
    created () {
      this.getNetworkEquipment(this.gridId)

      this.localBus.$on('open-add-network-equipment', (layerType, networkEquipment) => {
        this.addNetworkEquipmentLayerType = layerType
        this.selectedNetworkEquipment = networkEquipment
        this.showAddNetworkEquipmentDialog = true
      })
      this.localBus.$on('close-add-network-equipment', () => {
        this.showAddNetworkEquipmentDialog = false
      })
      this.localBus.$on('add-network-equipment-success', () => {
        this.showAddNetworkEquipmentDialog = false
        this.showSnackBar = true
        this.snackBarMessage = this.snackBarMessageAddNetworkEquipment
      })

      this.localBus.$on('open-update-network-model', (networkModelInformation) => {
        this.networkModelInformation = networkModelInformation
        this.showUpdateNetworkModelDialog = true
      })
      this.localBus.$on('close-update-network-model', () => {
        this.networkModelInformation = {}
        this.showUpdateNetworkModelDialog = false
      })
      this.localBus.$on('update-network-model-success', () => {
        this.networkModelInformation = {}
        this.showUpdateNetworkModelDialog = false
        this.showSnackBar = true
        this.snackBarMessage = this.snackBarMessageUpdateNetworkModel
      })
      this.localBus.$on('remove-network-equipment-success', () => {
        this.networkModelInformation = {}
        this.showSnackBar = true
        this.snackBarMessage = this.snackBarMessageRemoveNetworkEquipment
      })

      this.localBus.$on('start-add-network-connection', networkEquipment => {
        this.showAddNetworkConnectionDialog = true
        this.selectedNetworkEquipment = networkEquipment
      })
      this.localBus.$on('close-add-network-connection', () => {
        this.showAddNetworkConnectionDialog = false
      })
      this.localBus.$on('add-network-connection-success', async (equipment) => {
        this.showAddNetworkConnectionDialog = false
        this.showSnackBar = true
        this.snackBarMessage = this.snackBarMessageAddNetworkConnection
        await this.getNetworkEquipment(this.gridId)
        if (!this.showAlert) {
          const selectedEquipment = this.networkEquipmentData.accessLayer.find(({ equipmentId }) => equipmentId === equipment.equipmentId)
          this.localBus.$emit('selectNetworkEquipment', selectedEquipment)
        }
      })
      this.localBus.$on('add-network-batch-connections-success', async (equipment) => {
        this.showAddNetworkConnectionDialog = false
        this.showSnackBar = true
        this.snackBarMessage = this.snackBarMessageAddNetworkConnections
        await this.getNetworkEquipment(this.gridId)
        if (!this.showAlert) {
          const selectedEquipment = this.networkEquipmentData.accessLayer.find(({ equipmentId }) => equipmentId === equipment.equipmentId)
          this.localBus.$emit('selectNetworkEquipment', selectedEquipment)
        }
      })

      this.localBus.$on('remove-network-connection-success', async (equipment) => {
        this.networkModelInformation = {}
        this.showSnackBar = true
        this.snackBarMessage = this.snackBarMessageRemoveNetworkConnection
        await this.getNetworkEquipment(this.gridId)
        if (!this.showAlert) {
          const selectedEquipment = this.networkEquipmentData.accessLayer.find(({ equipmentId }) => equipmentId === equipment.equipmentId)
          this.localBus.$emit('selectNetworkEquipment', selectedEquipment)
        }
      })
      this.localBus.$on('update-network-connection-success', async (equipment) => {
        this.networkModelInformation = {}
        this.showSnackBar = true
        this.snackBarMessage = this.snackBarMessageUpdateNetworkConnection
        await this.getNetworkEquipment(this.gridId)
        if (!this.showAlert) {
          const selectedEquipment = this.networkEquipmentData.accessLayer.find(({ equipmentId }) => equipmentId === equipment.equipmentId)
          this.localBus.$emit('selectNetworkEquipment', selectedEquipment)
        }
      })
    },

    methods: {
      async getNetworkEquipment (gridId) {
        this.loadingNetworkEquipment = true
        await networkApi.getNetworkEquipment(gridId)
          .then((response) => {
            console.log(response)
            this.networkEquipmentData = response.data
          })
          .catch(async (error) => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              this.getNetworkEquipment(gridId)
            } else {
              this.showAlert = true
              this.alertType = 'error'
              this.alertMessage = this.alertMessageGeneral
            }
          })
          .finally(() => {
            this.loadingNetworkEquipment = false
          })
        return Promise.resolve()
      },
    },
  }
</script>
