export const Mode = {
    FAILURE: 'FAILURE',
    SUCCESS: 'SUCCESS',
    INFO: 'INFO',
    WARNING: 'WARNING',
}
export const UserRoles = {
    Admin: 1,
    User: 2,
}
export const BreadCrumbInitiators = ['Dashboard', 'Site', 'Admin', 'EWC']

export const equipmentSetpointEnums = new Map([[1, 'temperature']])

export const siteLayerEnum = { 1: 'temperature' }

export const BreadCrumbEwcBases = ['EWC', 'Add Equipment', 'Requests', 'Equipment Models', 'Power And Network', 'Equipment Search']

export const NetworkLayerTypes = { 'Core Layer': 1, 'Aggregate Layer': 2, 'Access Layer': 3 }
