<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-card
      class="py-2 px-8 "
      style="width: 100%; height: 100%;"
    >
      <v-card-title class="text-h4 font-weight-bold grey--text">
        Add Network Equipment: {{ layerType }}
      </v-card-title>
      <v-card-text>
        <div class="mb-3 font-weight-bold black--text">
          Select a piece of equipment from the active floor equipment:
        </div>
        <v-data-table
          ref="equipmentDataTable"
          v-model="selectedTableItems"
          :headers="headers"
          single-select
          item-key="id"
          :items="activeEquipmentDataTableArray"
          :fixed-header="true"
          calculate-widths
          :options.sync="pagination"
          height="480"
          :value="selectedRow"
          @pagination="event => {
            renderedEquipmentDataTableLength = event.itemsLength
          }"
          @click:row="
            (item, row) => {
              if(checkNetworkEquipmentIsInUse(item)){
                showNetworkEquipmentIsInUseDialog = true
              } else {
                row.select(true)
                selectedTableItem = item
              }
            }
          "
        >
          <template
            v-for="header in headers.filter((header) =>
              header.hasOwnProperty('formatter')
            )"
            v-slot:[`item.${header.value}`]="{ value }"
          >
            {{ header.formatter(value) }}
          </template>
          <template v-slot:[`body.prepend`]>
            <tr>
              <td>
                <v-text-field
                  v-model="tableFieldLabel"
                  type="text"
                  class="pt-4"
                  outlined
                  dense
                  label="filter..."
                />
              </td>
              <td>
                <v-select
                  v-model="tableFieldStatus"
                  :items="['Arrived', 'Deployed', 'Decommissioned', 'Disposed']"
                  class="pt-4"
                  outlined
                  dense
                >
                  <v-btn
                    v-if="tableFieldStatus"
                    slot="prepend-inner"
                    x-small
                    style="margin-top: 3px;"
                    text
                    @click="tableFieldStatus = ''"
                  >
                    <v-icon size="small">
                      mdi-close
                    </v-icon>
                  </v-btn>
                </v-select>
              </td>
              <td>
                <v-text-field
                  v-model="tableFieldSerial"
                  type="text"
                  class="pt-4"
                  outlined
                  dense
                />
              </td>
              <td>
                <v-text-field
                  v-model="tableFieldAssetTag"
                  type="text"
                  class="pt-4"
                  outlined
                  dense
                />
              </td>
              <td>
                <v-text-field
                  v-model="tableFieldModel"
                  type="text"
                  class="pt-4"
                  outlined
                  dense
                />
              </td>
              <td>
                <v-text-field
                  v-model="tableFieldRackLocation"
                  type="text"
                  class="pt-4"
                  outlined
                  dense
                />
              </td>
              <td>
                <v-text-field
                  v-model="tableFieldUnitLocation"
                  type="text"
                  class="pt-4"
                  outlined
                  dense
                />
              </td>
              <td>
                <v-text-field
                  v-model="tableFieldBay"
                  type="text"
                  class="pt-4"
                  outlined
                  dense
                />
              </td>
              <td>
                <v-text-field
                  v-model="tableFieldNotes"
                  type="text"
                  class="pt-4"
                  outlined
                  dense
                />
              </td>
              <td>
                <v-text-field
                  v-model="tableFieldAssetId"
                  type="text"
                  class="pt-4"
                  outlined
                  dense
                />
              </td>
              <td>
                <v-text-field
                  v-model="tableFieldProjectNumber"
                  type="text"
                  class="pt-4"
                  outlined
                  dense
                />
              </td>
              <td>
                <v-text-field
                  v-model="tableFieldProjectName"
                  type="text"
                  class="pt-4"
                  outlined
                  dense
                />
              </td>
              <td>
                <v-text-field
                  v-model="tableFieldDepartment"
                  type="text"
                  class="pt-4"
                  outlined
                  dense
                />
              </td>
              <td>
                <v-text-field
                  v-model="tableFieldAcquisitionDate"
                  type="text"
                  class="pt-4"
                  outlined
                  dense
                />
              </td>
              <td>
                <v-text-field
                  v-model="tableFieldDecommissionDate"
                  type="text"
                  class="pt-4"
                  outlined
                  dense
                />
              </td>
              <td>
                <v-text-field
                  v-model="tableFieldDisposalDate"
                  type="text"
                  class="pt-4"
                  outlined
                  dense
                />
              </td>
              <td>
                <v-text-field
                  v-model="tableFieldDescription"
                  type="text"
                  class="pt-4"
                  outlined
                  dense
                />
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-expand-transition v-if="selectedTableItem !== null">
          <div>
            <div class="mb-2 font-weight-bold black--text">
              Write the network connection protocol the equipment will be using:
            </div>
            <v-text-field
              v-model="stateConnectionProtocol"
              style="width: 400px;"
              class="mt-4"
              dense
              outlined
              label="Connection Protocol"
              type="text"
              :rules="[rules.characterLimit]"
            />
          </div>
        </v-expand-transition>
        <div class="d-flex justify-center">
          <v-btn
            :disabled="!stateConnectionProtocol"
            :loading="addingNetworkEquipment"
            color="green darken-1 white--text"
            class="font-weight-bold ma-4"
            @click="addNetworkEquipment"
          >
            Add
          </v-btn>
          <v-btn
            color="grey"
            class="font-weight-bold white--text ma-4"
            @click="
              selectedTableItem = null
              selectedTableItems = []
              stateConnectionProtocol = ''
              showAddNetworkEquipmentAlert = false
              localBus.$emit('close-add-network-equipment')
            "
          >
            Cancel
          </v-btn>
        </div>
      </v-card-text>
      <v-alert
        v-if="showAddNetworkEquipmentAlert"
        type="error"
        class="mt-2 mb-0"
      >
        {{ addNetworkEquipmentAlertMessage }}
      </v-alert>
    </v-card>
    <v-dialog
      v-model="showNetworkEquipmentIsInUseDialog"
      width="500"
    >
      <v-card
        class="pa-4"
        style="width: 100%; height: 100%;"
      >
        <div>
          The piece of equipment you have attempted to select has already been assigned to the network configuration
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

  import moment from 'moment'
  import networkApi from '@/api/network_ewc.js'
  import common from '@/api/common.js'
  import { NetworkLayerTypes } from '@/util/enums.js'

  export default {
    name: 'EwcAddNetworkEquipment',
    props: {
      localBus: {
        type: Object,
      },
      layerType: String,
      activeEquipment: {
        type: Array,
        default: () => [],
      },
      networkEquipment: {
        type: Object,
        default: () => {},
      },
      selectedFloorId: Number,
    },
    data: () => ({
      selectedTableItem: null,
      selectedTableItems: [],
      tableFieldLabel: '',
      tableFieldStatus: '',
      tableFieldSerial: '',
      tableFieldAssetTag: '',
      tableFieldModel: '',
      tableFieldRackLocation: '',
      tableFieldUnitLocation: '',
      tableFieldBay: '',
      tableFieldNotes: '',
      tableFieldAssetId: '',
      tableFieldProjectNumber: '',
      tableFieldProjectName: '',
      tableFieldDepartment: '',
      tableFieldAcquisitionDate: '',
      tableFieldDecommissionDate: '',
      tableFieldDisposalDate: '',
      tableFieldDescription: '',
      selectedRow: [],
      renderedEquipmentDataTableLength: 0,

      stateConnectionProtocol: '',
      showNetworkEquipmentIsInUseDialog: false,

      addingNetworkEquipment: false,
      showAddNetworkEquipmentAlert: false,
      addNetworkEquipmentAlertMessage: '',
      alertMessageGeneral: 'We were unable to complete your request at this time. Please, try again later.',

      pagination: {
        itemsPerPage: 10,
      },
    }),
    computed: {

      headers () {
        return [
          {
            text: 'Label',
            value: 'label',
            width: '150',
            filter: value => {
              if (!this.tableFieldLabel) return true
              return (value)?.toLowerCase().includes(this.tableFieldLabel.toLowerCase())
            },
          },
          {
            text: 'Status',
            value: 'status',
            width: '200',
            filter: value => {
              if (!this.tableFieldStatus) return true
              return (value)?.toLowerCase().includes(this.tableFieldStatus.toLowerCase())
            },
          },
          {
            text: 'Serial',
            value: 'serial',
            width: '150',
            filter: value => {
              if (!this.tableFieldSerial) return true
              return (value)?.toLowerCase().includes(this.tableFieldSerial.toLowerCase())
            },
          },
          {
            text: 'Asset Tag',
            value: 'assetTag',
            width: '150',
            filter: value => {
              if (!this.tableFieldAssetTag) return true
              return (value)?.toLowerCase().includes(this.tableFieldAssetTag.toLowerCase())
            },
          },
          {
            text: 'Model',
            value: 'model',
            width: '150',
            filter: value => {
              if (!this.tableFieldModel) return true
              return (value)?.toLowerCase().includes(this.tableFieldModel.toLowerCase())
            },
          },
          {
            text: 'Rack Location',
            value: 'rackLocation',
            width: '200',
            filter: value => {
              if (!this.tableFieldRackLocation) return true
              return (value)?.toLowerCase().includes(this.tableFieldRackLocation.toLowerCase())
            },
          },
          {
            text: 'Unit Location',
            value: 'unitLocation',
            width: '200',
            filter: value => {
              if (!this.tableFieldUnitLocation) return true
              return (value)?.toLowerCase().includes(this.tableFieldUnitLocation.toLowerCase())
            },

          },
          {
            text: 'Bay',
            value: 'bay',
            width: '100',
            filter: value => {
              if (!this.tableFieldBay) return true
              return (value)?.toLowerCase().includes(this.tableFieldBay.toLowerCase())
            },
          },
          {
            text: 'Notes',
            value: 'notes',
            width: '300',
            filter: value => {
              if (!this.tableFieldNotes) return true
              return (value)?.toLowerCase().includes(this.tableFieldNotes.toLowerCase())
            },
          },
          {
            text: 'Asset Id',
            value: 'assetId',
            width: '150',
            filter: value => {
              if (!this.tableFieldAssetId) return true
              return (value)?.toLowerCase().includes(this.tableFieldAssetId.toLowerCase())
            },
          },
          {
            text: 'Project Number',
            value: 'projectNumber',
            width: '200',
            filter: value => {
              if (!this.tableFieldProjectNumber) return true
              return (value)?.toLowerCase().includes(this.tableFieldProjectNumber.toLowerCase())
            },
          },
          {
            text: 'Project Name',
            value: 'projectName',
            width: '200',
            filter: value => {
              if (!this.tableFieldProjectName) return true
              return (value)?.toLowerCase().includes(this.tableFieldProjectName.toLowerCase())
            },
          },
          {
            text: 'Department',
            value: 'departmentNumber',
            width: '150',
            filter: value => {
              if (!this.tableFieldDepartment) return true
              return (value)?.toLowerCase().includes(this.tableFieldDepartment.toLowerCase())
            },
          },
          {
            text: 'Acquisition Date',
            value: 'acquisitionDate',
            width: '200',
            formatter: (x) => x ? moment(x).format('YYYY/MM/DD') : '',
            filter: value => {
              if (!this.tableFieldAcquisitionDate) return true
              return (value)?.toLowerCase().includes(this.tableFieldAcquisitionDate.toLowerCase())
            },
          },
          {
            text: 'Decommission Date',
            value: 'decommissionDate',
            width: '220',
            formatter: (x) => x ? moment(x).format('YYYY/MM/DD') : '',
            filter: value => {
              if (!this.tableFieldDecommissionDate) return true
              return (value)?.toLowerCase().includes(this.tableFieldDecommissionDate.toLowerCase())
            },
          },
          {
            text: 'Disposal Date',
            value: 'disposalDate',
            width: '200',
            formatter: (x) => x ? moment(x).format('YYYY/MM/DD') : '',
            filter: value => {
              if (!this.tableFieldDisposalDate) return true
              return (value)?.toLowerCase().includes(this.tableFieldDisposalDate.toLowerCase())
            },
          },
          {
            text: 'Description',
            value: 'description',
            width: '150',
            filter: value => {
              if (!this.tableFieldDescription) return true
              return (value)?.toLowerCase().includes(this.tableFieldDescription.toLowerCase())
            },
          },
        ]
      },

      rules () {
        const rules = {}
        rules.required = (value) => !!value || 'Required.'
        rules.characterLimit = (value) => value.length < 255 || 'Please enter a shorter value'
        return rules
      },

      activeEquipmentDataTableArray () {
        if (Object.keys(this.activeEquipment).length) {
          return this.activeEquipment.map(equipment => this.parseEquipment(equipment)).flat()
        } else return []
      },
    },
    created () {
    },

    methods: {

      parseEquipment (equipment) {
        const array = [{ ...equipment.equipmentData, model: equipment.equipmentModel.name, ...equipment.location }]
        equipment.children.forEach(c => array.push(this.parseEquipment(c)))
        return array.flat()
      },
      checkNetworkEquipmentIsInUse (equipment) {
        for (const layer in this.networkEquipment) {
          if (Array.isArray(this.networkEquipment[layer])) {
            const inUse = this.networkEquipment[layer].some(layerItem => {
              return layerItem.equipmentId === equipment.equipmentEwcId
            })
            if (inUse) {
              return true
            }
          }
        }
        return false
      },

      addNetworkEquipment () {
        this.addingNetworkEquipment = true
        this.showAddNetworkEquipmentAlert = false
        networkApi.addNetworkEquipment({
          equipmentEwcId: this.selectedTableItem.equipmentEwcId,
          gridId: this.selectedFloorId,
          layerType: NetworkLayerTypes[this.layerType],
          connectionProtocol: this.stateConnectionProtocol,
        })
          .then(response => {
            console.log(response)
            this.selectedTableItem = null
            this.selectedTableItems = []
            this.stateConnectionProtocol = ''
            this.localBus.$emit('add-network-equipment-success')
          })
          .catch(error => {
            console.log(error)
            this.showAddNetworkEquipmentAlert = true
            this.addNetworkEquipmentAlertMessage = this.alertMessageGeneral
            common.handleBadCall(error, this.$router)
          })
          .finally(() => { this.addingNetworkEquipment = false })
      },
    },
  }
</script>
