import { Stomp } from '@stomp/stompjs'
import SockJs from 'sockjs-client'
import common from '@/api/common.js'

export default {
  setupSockets: function (socketUrl, onMessage) {
  const sock = SockJs(`${common.getUrlPrefix()}/afs-socket`)
  const stompClient = Stomp.over(sock)
  stompClient.connect({}, function (frame) {
    console.log('Websocket connected')
    stompClient.subscribe(socketUrl, message => {
      onMessage(message)
    })
   })
},
}
